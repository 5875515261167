import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../../store/State";
import Subpage from "../../components/website/Subpage";
import Row from "../../components/website/Row";
import Col from "../../components/website/Col";
import {Redirect} from "react-router-dom";
import Msg from "../../i18n/Msg";

class PageAdminHome extends Component {
    render() {
        const profileState = State.getProfileState(this.props);
        if (profileState.isAdminLogged()) {
            return (
                <>
                    <Subpage title={"Panel Administracyjny"} small={true} preloader={profileState.preloaderDemand()}>
                        <Row>
                            <Col width={12}>
                                Witaj adminie !!!
                            </Col>
                        </Row>
                    </Subpage>
                </>
            );
        } else {
            return <Redirect push to={Msg.of().menu.admin.link}/>
        }
    }
}


const PageAdminHomeConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageAdminHome)

PageAdminHome.propTypes = {};

export default PageAdminHomeConnectedToRedux;