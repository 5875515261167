import React from 'react';

const Footer = () => (
    <section className="cid-rz6qIZVUAq" id="footer6-q">


        <div className="container">
            <div className="media-container-row align-center mbr-white">
                <div className="col-12">
                    <p className="mbr-text mb-0 mbr-fonts-style display-4">
                        Wszystkie Prawa Zastrzeżone ©2019-2021 i-Pi Sp z o.o.
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default Footer;