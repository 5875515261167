import Field from "./components/Field";
import Msg from "../i18n/Msg";
import FieldType from "./components/FieldType";
import ResponsiveRatio from "./components/ResponsiveRatio";
import School from "../model/School";
import React from 'react';
import FieldParams from "./components/FieldParams";
import ApiEngine from "../logic/ApiEngine";
import FormHeading from "./components/controls/FormHeading";


class SchoolForm {
    schoolName = new Field(School.fieldNames.name, Msg.schoolNameLabel, FieldType.text);
    schoolType = new Field(School.fieldNames.type, Msg.schoolTypeLabel, FieldType.radio);
    nip = new Field(School.fieldNames.nip, Msg.schoolNipLabel, FieldType.text);
    street = new Field(School.fieldNames.street, Msg.schoolStreetLabel, FieldType.text);
    houseNumber = new Field(School.fieldNames.houseNumber, Msg.schoolHouseNumberLabel, FieldType.text);
    city = new Field(School.fieldNames.city, Msg.schoolCityLabel, FieldType.text);
    postalCode = new Field(School.fieldNames.postalCode, Msg.schoolPostalCodeLabel, FieldType.text);
    secEmail = new Field(School.fieldNames.secEmail, Msg.schoolSecEmailLabel, FieldType.text);
    secPhone = new Field(School.fieldNames.secPhone, Msg.schoolSecPhoneLabel, FieldType.text);
    schoolSize = new Field(School.fieldNames.schoolSize, Msg.schoolSchoolSizeLabel, FieldType.radio);
    voivodeship = new Field(School.fieldNames.voivodeship, Msg.schoolVoivodeshipLabel, FieldType.select);

    /**
     * @param {School} school
     * @param {Object} changeFunctionsObject
     * @param {ValidationResult} validationResult
     * @return {*}
     */
    buildForm = (school, changeFunctionsObject, validationResult) => {
        const ratio = ResponsiveRatio.get(5, 5, 2, 2);
        const ratioStreet = ResponsiveRatio.get(5, 5, 3, 3);
        const ratioHouseNumber = ResponsiveRatio.get(5, 5, 4, 3);
        const ratioPostalCode = ResponsiveRatio.get(5, 5, 6, 6);
        const marginBelowEveryFormComponent = "2rem";

        const defaultFieldParams = FieldParams.get('', changeFunctionsObject.handleSchoolEdit, ratio, marginBelowEveryFormComponent, null, validationResult);
        const voivodeshipsList = {0: '--', ...ApiEngine.VOIVODESHIPS}

        const msg = Msg.get();

        const jsxOfSchoolForm = (
            <div className="container">
                <h5 className="text-uppercase text-danger"
                    style={{marginTop: marginBelowEveryFormComponent, marginBottom: marginBelowEveryFormComponent, textTransform: 'uppercase'}}>
                    {msg.common.step} 1/2
                </h5>
                {validationResult.isNotValid() && (
                    <p className="h6" style={{marginTop: marginBelowEveryFormComponent, marginBottom: marginBelowEveryFormComponent, lineHeight: '1.5rem', color: 'red'}}>
                        Dane wymagają poprawienia. W poniższym formularzu umieszczone są odpowiednie komunikaty
                    </p>
                )
                }
                <FormHeading text={msg.schoolBasicData}/>
                {this.schoolName.getReactElement(defaultFieldParams.withFieldValue(school.name))}
                {this.schoolType.getReactElement(defaultFieldParams.withFieldValue(school.type).withDictionary(ApiEngine.SCHOOL_TYPES))}
                {this.nip.getReactElement(defaultFieldParams.withFieldValue(school.nip))}
                {this.schoolSize.getReactElement(defaultFieldParams.withFieldValue(school.schoolSize).withDictionary(ApiEngine.SCHOOL_SIZES))}
                <FormHeading text={msg.schoolAddressLabel}/>
                <div className="row">
                    <div className="col-lg-8 col-sm-12">
                        {this.street.getReactElement(defaultFieldParams.withFieldValue(school.street).withResponsiveRatio(ratioStreet))}
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        {this.houseNumber.getReactElement(defaultFieldParams.withFieldValue(school.houseNumber).withResponsiveRatio(ratioHouseNumber))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-12">
                        {this.postalCode.getReactElement(defaultFieldParams.withFieldValue(school.postalCode).withResponsiveRatio(ratioPostalCode))}
                    </div>
                    <div className="col-lg-8 col-sm-12">
                        {this.city.getReactElement(defaultFieldParams.withFieldValue(school.city).withResponsiveRatio(ratioStreet))}
                    </div>
                </div>
                {this.voivodeship.getReactElement(defaultFieldParams.withFieldValue(school.voivodeship).withDictionary(voivodeshipsList))}
                <FormHeading text={Msg.schoolSecretariat}/>
                {this.secEmail.getReactElement(defaultFieldParams.withFieldValue(school.secEmail))}
                {this.secPhone.getReactElement(defaultFieldParams.withFieldValue(school.secPhone))}
            </div>
        );
        return jsxOfSchoolForm;
    }

    /**
     * @public
     * @static
     * @method
     * @param {School} school
     * @param {function(*=): void} handleSchoolEdit
     * @param {ValidationResult} validationResult
     * @return {*} Zwraca JSX formularza
     */
    static get = (school, handleSchoolEdit, validationResult) => {
        const schoolForm = new SchoolForm();
        return schoolForm.buildForm(school, {handleSchoolEdit}, validationResult);
    }


}

export default SchoolForm;