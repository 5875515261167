import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveRatio from "../ResponsiveRatio";

const TextInput = props => {
    let {label, name, onChangeFunc, fieldValue, ratio, marginBottom, isPassword} = props;
    let style;
    if(props.big) {
        style = {
            minHeight: '3.5em',
            fontSize: '1.5rem',
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
            backgroundColor: 'white'
        }
    } else {
        style = {
            minHeight: '1.5em',
            fontSize: '1rem',
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            backgroundColor: 'white'
        }
    }

    const inputType = isPassword? 'password' : 'text';

    return (
        <>
            <div className="row align-items-center" style={{"marginBottom": marginBottom}}>
                <div className={ratio.getLabelClasses()} style={{marginTop: '0.6rem'}}>
                    <p className="h6">{label}</p>
                </div>
                <div className={ratio.getControlClasses()}>
                    <input className="form-control form-control-sm display-7" name={name} type={inputType} value={fieldValue} onChange={onChangeFunc} style={style}/>
                </div>
            </div>
        </>
    );
};

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChangeFunc: PropTypes.func.isRequired,
    fieldValue: PropTypes.string,
    big: PropTypes.bool,
    ratio: PropTypes.shape(ResponsiveRatio.shape()).isRequired,
    marginBottom: PropTypes.string,
    isPassword: PropTypes.bool
}

TextInput.defaultProps = {
    ratio: ResponsiveRatio.default(),
    isPassword: false
}

export default TextInput;