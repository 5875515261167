import React from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import {jsPDF} from "jspdf";

class BarChart extends React.Component {

    /**
     * @type {RefObject}
     */
    canvasRef;

    /**
     * @type {Chart}
     */
    myChart;

    // colors = ['green', 'red', 'blue', 'pink', 'purple', 'orange'];
    colors = ['#05668D', '#028090', '#00A896', '#02C39A', '#B5E7B5', '#F0F3BD', '#F1F4C3', '#04738F', '#019493', '#01B698', '#79DBAC'];

    constructor(props) {
        super(props);
        Chart.defaults.global.defaultFontFamily = "Arial, Roboto, sans-serif";
        // Chart.defaults.global.defaultFontSize = "17";
        Chart.defaults.global.defaultFontColor = "black";
        this.canvasRef = React.createRef();
    }

    getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    componentDidUpdate() {

        this.myChart.data.labels = this.props.data.map(d => d.label);
        this.myChart.data.datasets = this.generateDataSets();
        this.myChart.update();
    }

    generateDataSets() {
        const datasets = [
            {
                label: this.props.title,
                data: this.props.data.map(d => d.value),
                backgroundColor: this.colors
            }
        ];
        /*this.props.data.forEach((d, index) => {
            let color;
            if(this.colors[index]){
                color = this.colors[index];
            } else {
                color = this.colors[this.getRandomIntInclusive(0, this.colors.length - 1)]
            }
            datasets[index] = {
                label: d.label,
                data: [d.value],
                backgroundColor: color
            };
        })*/

        return datasets;
    }

    componentDidMount() {
        const completeFunc = () => {
            console.log('animation complete');
        }
        this.myChart = new Chart(this.canvasRef.current, {
            type: 'bar',
            options: {
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            fontSize: 15,
                            fontFamily: "'Roboto', sans-serif",
                            fontColor: '#1a1d3b',
                            fontStyle: 'bold'
                        }
                    }],
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: this.props.maxY + Math.floor(this.props.maxY/10),
                                callback: (value, index, values) => {
                                    if (value <= this.props.maxY) {
                                        return value;
                                    } else {
                                        return null;
                                    }
                                }
                            },
                        }
                    ],

                },
                animation: {
                    onComplete: completeFunc
                },
                legend: {
                    display: false
                },
            },
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: this.generateDataSets()
                /*datasets: [{
                    label: this.props.title,
                    data: this.props.data.map(d => d.value),
                    backgroundColor: this.props.color
                }]*/
            }
        });
    }

    savePdf = () => {
        const doc = new jsPDF();

        doc.text("Hello world!", 10, 10);
        doc.save("a4.pdf");

    }

    render() {
        let height = this.props.height;
        if (this.props.maxY <= 1) {
            height = '100px'
        }
        return (
            <div style={{width: this.props.width, height: height}}>
                <canvas ref={this.canvasRef}/>
            </div>
        );
    }
}

BarChart.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.array,
    maxY: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string
}

BarChart.defaultProps = {
    maxY: 100,
    width: '500px',
    height: '500px'
}

export default BarChart;
