import Current from "../profiles/Current";

class ApiUrl {


    // static API_URL = process.env.NODE_ENV === 'production' ? 'https://api.ewaluacja.online' : 'http://localhost:8080';
    static API_URL = Current.PROFILE.apiURL;
    // static CONTACT_FORM_URL = 'http://localhost:8080/CATI/efw.do';
    static CONTACT_FORM_URL = 'https://manager.ewaluacja.online/efw.do';


    static API = {
        OPEN: {
            SESSION: {
                LOGIN: ApiUrl.API_URL + '/api/open/session/login',
                LOGIN_SANDBOX: ApiUrl.API_URL + '/api/open/session/sandboxLogin',
                ADMIN_LOGIN: ApiUrl.API_URL + '/api/open/session/aLogin',
            },
            REGISTER: ApiUrl.API_URL + '/api/open/register',
            REGISTER_ACTIVATION: ApiUrl.API_URL + '/api/open/register/activation',

            BY_HASH: {
              REGISTER: ApiUrl.API_URL + '/api/open/by_hash/register',
              SCHOOL_NAME_GET: ApiUrl.API_URL + '/api/open/by_hash/school_name_get'
            },
            DICTIONARY: {
                SCHOOL_SIZES: ApiUrl.API_URL + '/api/open/dictionary/schoolsizes',
                SCHOOL_TYPES: ApiUrl.API_URL + '/api/open/dictionary/schooltypes',
                USER_ROLES: ApiUrl.API_URL + '/api/open/dictionary/userroles',
                VOIVODESHIPS: ApiUrl.API_URL + '/api/open/dictionary/voivodeships',
            },
            CHECKS: {
                NIP_EXISTENCE: ApiUrl.API_URL + '/api/open/checks/nip_existence',
                LOGIN_EXISTENCE: ApiUrl.API_URL + '/api/open/checks/login_existence',
                PERSONAL_EMAIL_EXISTENCE: ApiUrl.API_URL + '/api/open/checks/personal_email_existence',
            },
            CONTACT: {
                FORM: ApiUrl.API_URL + '/api/open/contact/form'
            },
            MAILING: {
                SUPPORT: {
                    CONTENT:  ApiUrl.API_URL + '/api/open/mailing/support/content',
                    CANCEL:  ApiUrl.API_URL + '/api/open/mailing/support/cancel',
                }
            }
        },
        SECURED: {
            USER: {
                PROFILE: {
                    GET: ApiUrl.API_URL + '/api/secured/user/profile/get',
                    LOGOUT: ApiUrl.API_URL + '/api/secured/user/profile/logout'
                },
                LIST: ApiUrl.API_URL + '/api/secured/user/list'
            },
            SURVEY: {
                LIST: {
                    ALL: ApiUrl.API_URL + '/api/secured/survey/list/all',
                    ACTIVE: ApiUrl.API_URL + '/api/secured/survey/list/active'
                },

                PARAMS: {
                    SET: ApiUrl.API_URL + '/api/secured/survey/params/set',
                    SET_DEFAULT: ApiUrl.API_URL + '/api/secured/survey/params/setDefault',
                    LIST: ApiUrl.API_URL + '/api/secured/survey/params/list'
                },

                QUOTE: {
                    GET: ApiUrl.API_URL + '/api/secured/survey/quote/get',
                    SET: ApiUrl.API_URL + '/api/secured/survey/quote/set'
                },

                STATS: {
                    GET: ApiUrl.API_URL + '/api/secured/survey/stats/getStats',
                    QUESTS: ApiUrl.API_URL + '/api/secured/survey/stats/getQuests',
                    ITEMS: ApiUrl.API_URL + '/api/secured/survey/stats/getItems',
                    COLS: ApiUrl.API_URL + '/api/secured/survey/stats/getCols',
                }
            },
            MAILING: {
                INVITATION: {
                    REALIZACJA_PODSTAWY_PROGRAMOWEJ_W_CZASIE_PANDEMII:  ApiUrl.API_URL + '/api/secured/mailing/invitation/rppwcp'
                }
            }
        }

    }




}

export default ApiUrl;