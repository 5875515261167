import React from 'react';

const Department = props => {
    return (
        <>

            <div className="media-container-row" style={{margin: 30, marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}>
                <div className="mbr-text col-12 mbr-fonts-style display-7" style={{textAlign: 'center'}}>
                    <h2 className="mb-0" style={{lineHeight: '2rem', fontSize: '1.3rem', color: '#fff', backgroundColor: '#49ad5c'}}>
                        <strong>{props.name.toUpperCase()}</strong>
                    </h2>
                </div>
            </div>
            {props.reverse ? (
                <div className="media-container-row" style={{marginBottom: 100}}>
                    <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-4">
                        {/*<img src={props.image} alt="" title="" style={{width: '100%', height: '400px'}}/>*/}
                    </div>
                    <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-8">
                        <div className="accordion" id={`dep${props.id}`}>
                            {props.children}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="media-container-row" style={{marginBottom: 100}}>
                    <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-10">
                        <div className="accordion" id={`dep${props.id}`}>
                            {props.children}
                        </div>
                    </div>
                    {/*<div className="mbr-text col-12 mbr-fonts-style display-7 col-md-2">
                        <img src={props.image} alt="" title="" style={{width: '100%', height: '400px'}}/>
                    </div>*/}
                </div>
            )
            }
        </>
    );
};

export default Department;