import React from 'react';
import Utils from "../../logic/Utils";

const ConfirmMessage = props => {
    let style={}
    if(props.style) {
       style = props.style;
    }
    const wholeText = props.text;
    const toDisplay = Utils.replaceTagsWithElements(wholeText, '_BR_', <br/>);
    return (
        <div data-form-alert="" className="alert alert-success col-12" style={style}>{toDisplay}</div>
    );
};

export default ConfirmMessage;