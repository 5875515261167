import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import TitleBanner from "../../components/website/TitleBanner";
import Subpage from "../../components/website/Subpage";
import Row from "../../components/website/Row";
import Col from "../../components/website/Col";
import {Redirect} from "react-router-dom";
import Msg from "../../i18n/Msg";
import Button from "../../components/visual/Button";
import ApiEngine from "../../logic/ApiEngine";
import Text from "../../components/website/Text";

class PageAdminMailing extends Component {

    state = {
        prodUnblocked: false
    }

    startMailing = (mode) => {
        ApiEngine.startMailingRPPWCP(mode)
    }

    prodSwitch = () => {
        this.setState({prodUnblocked: !this.state.prodUnblocked})
    }

    render() {
        const profileState = State.getProfileState(this.props);
        if (profileState.isAdminLogged()) {
            return (
                <>
                    <Subpage title={"Mailing"} small={true} preloader={profileState.preloaderDemand()}>
                        <Row>
                            <Col width={12}>
                                <Text>
                                    Realizacja podstawy programowej w czasie pandemii
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={4}>
                                <Button size={4} color="primary" onClickAction={() => this.startMailing('test')}>Start TEST</Button>
                            </Col>
                            <Col width={4}>
                                <Button size={4} color="primary" onClickAction={() => this.startMailing('fake')}>Start FAKE</Button>
                            </Col>
                            <Col width={4}>
                                <Button size={4} color="secondary" onClickAction={this.prodSwitch}>{this.state.prodUnblocked ? 'Zablokuj ' : 'Odblokuj '} PROD</Button>
                                {this.state.prodUnblocked && <Button size={4} color="dark" onClickAction={() => this.startMailing('prod')}>Start PROD</Button>}
                            </Col>

                        </Row>
                    </Subpage>
                </>
            );
        } else {
            return <Redirect push to={Msg.of().menu.admin.link}/>
        }
    }
}


const PageAdminMailingConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageAdminMailing)

PageAdminMailing.propTypes = {};

export default PageAdminMailingConnectedToRedux;