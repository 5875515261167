import $ from 'jquery';

class ModalHandler {

    /**
     * @field
     * @type {string}
     */
    modalID;


    constructor(modalID) {
        this.modalID = modalID;
    }

    static of(modalID) {
        return new ModalHandler(modalID);
    }

    show() {
        $('#' + this.modalID).modal('show');
    }

    hide() {
        $('#' + this.modalID).modal('hide');
    }

    /**
     *
     * @param {function:any} predicateFunc
     */
    hideOnPredicate(predicateFunc) {
        const closingPermission = predicateFunc();
        if(closingPermission) {
            this.hide();
        }
    }

    /**
     *
     * @param {function(object)} func
     */
    onShow(func) {
        $('#' + this.modalID).on('shown.bs.modal', e => {
            func(e);
        });
    }

    /**
     *
     * @param {function(object)} func
     */
    onHide(func) {
        $('#' + this.modalID).on('hide.bs.modal', e => {
            func(e);
        });
    }



}

export default ModalHandler;