import BaseReducer from "../BaseReducer";
import SurveyState from "../states/SurveyState";
import UpdateStats from "../actions/Survey/UpdateStats";
import UpdateQuestsItemsCols from "../actions/Survey/UpdateQuestsItemsCols";

class SurveyReducer extends BaseReducer {
    constructor() {
        super('SurveyReducer' ,SurveyState.initial());
        super.handledActions = [UpdateStats.TYPE, UpdateQuestsItemsCols.TYPE];
    }

    /**
     * @public
     * @static
     * @method
     * @description metoda zwracająca funkcję reducera
     * @return {function(Object=, {type: string, payload: Object}): *}
     */
    static get = () => {
        return new SurveyReducer().reduce;
    }
}

export default SurveyReducer;