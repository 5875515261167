import Logger from "../../logic/Logger";

class ChartData {

    /**
     * @public
     * @field
     * @type {string}
     */
    title;

    /**
     * @public
     * @field
     * @type {{label:string, value:number}[]}
     */
    data;

    /**
     * @public
     * @field
     * @type {string}
     */
    shortTitle;


    /**
     *
     * @param {string} title
     * @param {{label:string, value:number}[]} data
     */
    constructor(title, data) {
        this.title = title;
        this.data = data;
    }

    /**
     *
     * @param {string} title
     * @return {ChartData}
     */
    static get = (title) => {
        return new ChartData(title, []);
    }

    /**
     *
     * @param {string} label
     * @param {number} value
     */
    add(label, value) {
        this.data.push({label, value})
    }

    maxValue() {
        let max = 1;
        let message = '';
        if(this.data) {
            this.data.forEach(dataElement => {
                message += dataElement.value + ', '
                if(dataElement.value > max) {
                    max = dataElement.value;
                }
            })
        }
        message += ' ---> ' + max;
        // Logger.debug(message);
        return max;
    }
}

export default ChartData;