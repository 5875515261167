import React, {Component} from 'react';
import TitleBanner from "../components/website/TitleBanner";
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import ErrorMessage from "../components/visual/ErrorMessage";
import jCaptcha from 'js-captcha';
import Captcha from "../components/website/Captcha";
import ApiEngine from "../logic/ApiEngine";
import {connect} from 'react-redux';
import State from "../store/State";
import ProfileState from "../store/states/ProfileState";
import LoginAction from "../store/actions/profile/LoginAction";
import Msg from "../i18n/Msg";
import TextInput from "../form/components/controls/TextInput";
import GreenButton from "../components/visual/GreenButton";
import ShowPreloaderAction from "../store/actions/profile/ShowPreloaderAction";
import Preloader from "../components/visual/Preloader";
import Logger from "../logic/Logger";
import Subpage from "../components/website/Subpage";
import Row from "../components/website/Row";
import Col from "../components/website/Col";
import Current from "../profiles/Current";
import underConstructionIMG from '../img/under_construction.png';
import PropTypes from 'prop-types';

class PageLoginUser extends Component {

    static propTypes = {
        admin: PropTypes.bool
    }

    static defaultProps = {
        admin: false
    }

    constructor(props, context) {
        super(props, context);
        this.profileState = State.getProfileState(props);
    }

    /**
     * @type {ProfileState}
     */
    profileState;

    defaultLogin() {
        if (Current.PROFILE.isProd() || Current.PROFILE.isSandbox()) {
            return ''
        } else {
            if (this.props.admin) {
                return 'luk'
            } else {
                return '56017'
            }
        }
    }

    defaultPassword() {
        if (Current.PROFILE.isProd() || Current.PROFILE.isSandbox()) {
            return ''
        } else {
            if (this.props.admin) {
                return 'dupsk0.12'
            } else {
                // return 'x@a4GgfI=J'
                return 'Ewaluacja.!@#$'
            }
        }
    }

    state = {
        login: this.defaultLogin(),
        password: this.defaultPassword(),
        buttonLoginPressed: false,
        // captchaPassed: false,
        fieldsValidated: false,
        errorMessages: [],
        token: '',
        showPreloader: false
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            errorMessages: [],
            buttonLoginPressed: true,
            fieldsValidated: false,
            // captchaPassed: false
        });
        this.formValidation();
    }

    handleFormEdit = e => {
        this.setState({
            [e.target.name]: e.target.value, // login/password
            errorMessages: [],
            buttonLoginPressed: false,
            fieldsValidated: false,
            // captchaPassed: false
        });
    }

    formValidation = () => {
        let isOK = true;
        /*this.captcha.validate();
        this.captcha.reset();*/
        if (!this.state.login) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, Msg.FillErrorLogin]
            }));
            isOK = false;
        }
        if (!this.state.password) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, Msg.FillErrorPassword]
            }));
            isOK = false;
        }
        if (isOK) {
            this.setState(prevState => ({
                fieldsValidated: true
            }));
        }
        return isOK;
    }


    componentDidMount() {
        /*console.log("Uruchamiam zaraz Captche...");
        this.captcha = new jCaptcha({
            el: '.jCaptcha',
            // set callback function
            callback: (response, $captchaInputElement) => {

                if (response == 'success') {

                    /!*$captchaInputElement[0].classList.remove('error');
                    $captchaInputElement[0].classList.add('success');*!/
                    /!*$captchaInputElement[0].placeholder = '';*!/
                    this.setState({
                        captchaPassed: true
                    });

                }

                if (response == 'error') {
                    this.setState(prevState => ({
                        captchaPassed: false,
                        errorMessages: [...prevState.errorMessages, ApiEngine.messages.CaptchaError]
                    }));
                }

            },

            // canvasFillStyle: '#767676',
            requiredValue: '',
            // canvasFontFamily: 'Arial',
            /!*canvas: {
                class: 'jCaptchaCanvas',
                style: {
                    // required properties for captcha stylings:
                    width: 100,
                    height: 15,
                    textBaseline: 'top',
                    font: '15px Arial',
                    textAlign: 'left',
                    fillStyle: '#ddd'
                }
            },*!/

        });
        console.log(this.captcha)*/
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.profileState = State.getProfileState(this.props);
        const {buttonLoginPressed, fieldsValidated} = this.state;
        if (buttonLoginPressed && fieldsValidated && !this.profileState.isLogged()) {
            this.setState({
                buttonLoginPressed: false
            })
            this.authenticate();
        }

    }

    authenticate = () => {
        /*ShowPreloaderAction.run(this.props.dispatch, true);*/
        this.setState({showPreloader: true});
        /**
         * @param {ValidationResult} response
         */
        const funcCallback = (response) => {
            if (response) {
                if (response.isValid) {// MA BYC BEZ NAWIASOW - to jest sprawdzenie, czy funkcja istnieje
                    if (!response.isValid()) {
                        setTimeout(() => {
                            this.setState(prevState => ({
                                buttonLoginPressed: false,
                                errorMessages: [...prevState.errorMessages, response.errorsTextDistractedByBR()],
                                showPreloader: false
                            }));
                        }, 1000);
                    }
                } else {
                    let errorText = 'Błąd';
                    if (response.status && response.status === 401) {
                        errorText = 'Podane błędne login/hasło lub konto jest zablokowane'
                    }
                    this.setState(prevState => ({
                        buttonLoginPressed: false,
                        errorMessages: [...prevState.errorMessages, errorText],
                        showPreloader: false
                    }));
                }
            } else {
                this.setState(prevState => ({
                    buttonLoginPressed: false,
                    errorMessages: [...prevState.errorMessages, 'error'],
                    showPreloader: false
                }));
            }


        }
        if (this.props.admin) {
            LoginAction.runForAdmin(this.props.dispatch, this.state.login, this.state.password, funcCallback);
        } else {
            LoginAction.run(this.props.dispatch, this.state.login, this.state.password, funcCallback);
        }
    }

    render() {
        if (!Current.PROFILE.panelEnabled) {
            return (
                <Subpage title={this.props.admin ? Msg.of().menu.admin.label : Msg.of().menu.panel.label} small={true}>
                    <Row>
                        <Col width={12} align="center">
                            <img src={underConstructionIMG} alt="..."/>
                        </Col>
                    </Row>
                </Subpage>
            );
        } else {
            return (
                <>
                    <Subpage title={this.props.admin ? Msg.of().menu.admin.label : Msg.of().menu.panel.label} small={true}>
                        <Preloader showPreloader={this.state.showPreloader}>
                            <Row>
                                <Col width={4} style={{minHeight: '31rem'}}>
                                    <form method="POST" onSubmit={this.handleFormSubmit}>
                                        {/*<div className="row align-items-center" style={{paddingBottom: '30px'}}>
                                                <div className="col-12">
                                                    <Captcha/>
                                                </div>
                                            </div>*/}
                                        <div className="container" style={{padding: 0}}>
                                            <div className="row align-items-center" style={{margin: 0}}>
                                                <div className="col-12 align-center" style={{padding: 0, margin: 0}}>
                                                    <p className="h5" style={{textTransform: 'uppercase'}}>
                                                        {this.props.admin ? '' : 'Zarejestrowani klienci'}
                                                    </p>
                                                    <hr style={{margin: '2rem 0'}}/>
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{paddingBottom: '30px', margin: 0}}>
                                                {/*<div className="col-3" style={{paddingRight: 0}}>
                                                        <p className="h6" style={{margin: 0}}>{Msg.FieldNameLogin}</p>
                                                    </div>*/}
                                                <div className="col-12" style={{padding: 0}}>
                                                    <input className="form-control display-7" name="login" type="text" value={this.state.login}
                                                           onChange={this.handleFormEdit}
                                                           placeholder="Login"
                                                           style={{
                                                               minHeight: '2rem'
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{paddingBottom: '30px', margin: 0}}>
                                                {/*<div className="col-3" style={{paddingRight: 0}}>
                                                        <p className="h6" style={{margin: 0}}>{Msg.FieldNamePassword}</p>
                                                    </div>*/}
                                                <div className="col-12" style={{padding: 0}}>
                                                    <input className="form-control display-7" name="password" type="password" value={this.state.password}
                                                           onChange={this.handleFormEdit}
                                                           placeholder="Hasło"
                                                           style={{
                                                               minHeight: '2rem'
                                                           }}/>
                                                </div>
                                            </div>
                                            {this.state.errorMessages.length > 0 && <ErrorMessage messagesList={this.state.errorMessages} cols={12}/>}
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 align-center" style={{padding: 0, margin: 0}}>
                                                    <button type="submit" className="btn btn-primary display-7 btn-sm"
                                                            style={{width: 'auto', fontSize: '1rem'}}>{Msg.ButtonLogin}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </Preloader>
                    </Subpage>


                    {/*<ScrollToTopOnMount/>
                <TitleBanner text="PANEL KLIENTA"/>
                <Preloader showPreloader={this.state.showPreloader}>
                    <section className="mbr-section article content1 cid-rz87GNwyCo" id="content1-2c">
                        <div className="container">
                            <div className="media-container-row">
                                <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-8 col-sm-12" style={{backgroundColor: '#ffffff', padding: 0}}>

                                        </div>
                                        <div className="col-12 d-lg-none" style={{height: '3rem'}}>

                                        </div>
                                        <div className="col-lg-6 col-md-8 col-sm-12 offset-lg-2" style={{backgroundColor: '#ffffff', padding: 0}}>
                                            <div className="row align-items-center" style={{margin: 0}}>
                                                <div className="col-12 align-center" style={{padding: 0}}>
                                                    <p className="h5" style={{textTransform: 'uppercase'}}>
                                                        Nowi klienci
                                                    </p>
                                                    <hr style={{margin: '2rem 0'}}/>
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{padding: 0}}>
                                                <div className="col-12 align-center">
                                                    <p className="h6" style={{textAlign: 'justify', lineHeight: '1.5rem'}}>
                                                        Aby utworzyć nowe konto, należy wykonać następujące kroki:
                                                    </p>
                                                    <ul className="h6" style={{textAlign: 'justify', lineHeight: '1.5rem'}}>
                                                        <li>wypełnić formularz rejestracyjny</li>
                                                        <li>kliknąć link weryfikacyjny, który zostanie wysłany na adres email</li>
                                                    </ul>
                                                    <p className="h6" style={{textAlign: 'justify', lineHeight: '1.5rem'}}>
                                                        W celu przejścia do formularza rejestracji, proszę kliknąć poniższy przycisk...
                                                    </p>
                                                    <GreenButton label={Msg.of().menu.register.label} internalLink={Msg.of().menu.register.link}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                </Preloader>*/}
                </>
            );
        }
    }

}

const SiteLoginUserReduxConnected = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageLoginUser);

export default SiteLoginUserReduxConnected;