import React from 'react';
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";

const GreenButton = props => {
    let link = '';
    let align = props.align;
    if (!align.includes("align-")) {
        align = 'align-' + align;
    }
    const linkStyle = props.linkStyle;
    const sectionStyle = props.sectionStyle;
    if (props.externalLink) {
        if (props.blank) {
            link = (
                <a className="btn btn-primary display-4" href={props.externalLink} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                    {props.label}
                </a>
            );
        } else {
            link = (
                <a className="btn btn-primary display-4" href={props.externalLink} style={linkStyle}>
                    {props.label}
                </a>
            );
        }
    } else if (props.internalLink) {
        link = (
            <NavLink className="btn btn-primary display-4" to={props.internalLink}  style={linkStyle}>
                {props.label}
            </NavLink>
        );
    } else if (props.onClickFunc) {
        link = (
            <a className="btn btn-primary display-4" href="" onClick={props.onClickFunc} style={linkStyle}>
                {props.label}
            </a>
        );
    }
    return (
        <>
            <section className="mbr-section content8 cid-rz6ja30dlz" style={sectionStyle}>
                <div className="container">
                    <div className="media-container-row title">
                        <div className="col-12">
                            <div className={'mbr-section-btn ' + align}>
                                {link}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

GreenButton.propTypes = {
    label: PropTypes.string.isRequired,
    externalLink: PropTypes.string,
    internalLink: PropTypes.string,
    onClickFunc: PropTypes.func,
    align: PropTypes.string,
    linkStyle: PropTypes.object,
    sectionStyle: PropTypes.object,
    blank: PropTypes.bool
}

GreenButton.defaultProps = {
    sectionStyle: {padding: '3rem 0'},
    align: "align-center"
}

export default GreenButton;