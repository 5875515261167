import * as React from "react";
import Chart from 'chart.js';
import PropTypes from "prop-types";


class LineChart extends React.Component {

    /**
     * @type {RefObject}
     */
    canvasRef;

    /**
     * @type {Chart}
     */
    myChart;


    constructor(props) {
        super(props);
        Chart.defaults.global.defaultFontFamily = "Roboto, sans-serif";
        this.canvasRef = React.createRef();
    }

    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.time);
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.canvasRef.current, {
            type: 'line',
            options: {
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            type: 'category',
                            labels: ['A', 'B', 'C', 'X']
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                min: 0
                            }
                        }
                    ]
                }
            },
            data: {
                labels: this.props.data.map(d => d.time),
                datasets: [{
                    label: this.props.title,
                    data: this.props.data.map(d => d.value),
                    fill: 'none',
                    backgroundColor: this.props.color,
                    pointRadius: 2,
                    borderColor: this.props.color,
                    borderWidth: 1,
                    lineTension: 0
                }]
            }
        });
    }

    render() {
        return (
            <div style={{width: '500px', height: '500px'}}>
                <canvas ref={this.canvasRef} />
            </div>
        )
    }
}

LineChart.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.array
}

export default LineChart;