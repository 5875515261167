class SurveyStats {

    /**
     * @public
     * @field
     * @type {string}
     */
    srwHash;

    /**
     * @public
     * @field
     * @type {IntStringMapEntry[]}
     */
    quests;

    /**
     * @public
     * @field
     * @type {IntStringMapEntry[]}
     */
    items;

    /**
     * @public
     * @field
     * @type {IntStringMapEntry[]}
     */
    cols;

    /**
     * @public
     * @field
     * @type {StatisticData[]}
     */
    stats;

    /**
     * @public
     * @field
     * @type {Date}
     * @description czas, kiedy ostatnio aktualizowano quests, items i cols
     */
    lastUpdateLabels;

    /**
     * @public
     * @field
     * @type {Date}
     * @description czas, kiedy ostatnio aktualizowano stats
     */
    lastUpdateStats;


    constructor(srwHash, quests, items, cols, stats) {
        this.srwHash = srwHash;
        this.quests = quests;
        this.items = items;
        this.cols = cols;
        this.stats = stats;
    }

    /**
     *
     * @param {IntStringMapEntry[]} collection
     * @param {string} replacement
     * @param {number} id
     */
    htmlLabelByID(collection, id, replacement) {
        if (collection) {
            const entry = collection.find(value => value.key === id);
            if (entry && entry.value) {
                return entry.value.replaceAll("NEW_LINE", replacement);
            }
        }
        return null;
    }

    questHTML(questID) {
        return this.htmlLabelByID(this.quests, questID, '<br/>');
    }

    itemHTML(itemID) {
        return this.htmlLabelByID(this.items, itemID, '<br/>');
    }

    colHTML(colID) {
        return this.htmlLabelByID(this.cols, colID, '<br/>');
    }

    questText(questID) {
        return this.htmlLabelByID(this.quests, questID, '');
    }

    colText(colID) {
        return this.htmlLabelByID(this.cols, colID, '');
    }

    /**
     * @public
     * @static
     * @method
     * @param {string} srwHash
     * @param {IntStringMapEntry[]} quests
     * @param {IntStringMapEntry[]} items
     * @param {IntStringMapEntry[]} cols
     * @param {StatisticData[]} stats
     * @return {SurveyStats}
     */
    static get = (srwHash, quests, items, cols, stats) => {
        return new SurveyStats(srwHash, quests, items, cols, stats);
    }

    /**
     * @public
     * @static
     * @method
     * @return {SurveyStats}
     */
    static initial = () => {
        return SurveyStats.get('', [], [], [], []);
    }
}

export default SurveyStats;