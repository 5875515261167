import React from 'react';
import SurveyParams from "../model/SurveyParams";
import Field from "./components/Field";
import Msg from "../i18n/Msg";
import FieldType from "./components/FieldType";
import FieldParams from "./components/FieldParams";
import ResponsiveRatio from "./components/ResponsiveRatio";
import FormHeading from "./components/controls/FormHeading";

class SurveyParamsForm {

    /**
     * @type {number}
     */
    maxSurveyInstances;

    /**
     * @type {Field}
     */
    maxPupilsField;

    /**
     * @type {Field}
     */
    maxTeachersField;

    /**
     * @type {Field}
     */
    maxParentsField;

    /**
     * @type {FieldParams}
     */
    defaultFieldParams;

    /**
     * @type {SurveyParams}
     */
    surveyParams;


    constructor(surveyParams, maxSurveyInstances, onChangeFunc, validationResult) {
        this.surveyParams = surveyParams;
        this.maxSurveyInstances = maxSurveyInstances;
        this.maxPupilsField = new Field(SurveyParams.fieldNames.maxPupils, Msg.of().surveyParamsForm.maxPupils, FieldType.range);
        this.maxTeachersField = new Field(SurveyParams.fieldNames.maxTeachers, Msg.of().surveyParamsForm.maxTeachers, FieldType.range);
        this.maxParentsField = new Field(SurveyParams.fieldNames.maxParents, Msg.of().surveyParamsForm.maxParents, FieldType.range);
        this.defaultFieldParams = new FieldParams('', onChangeFunc, ResponsiveRatio.getDefault(), '0.1rem', null, validationResult);
    }

    buildForm() {
        const {validationResult, marginBelowComponent} = this.defaultFieldParams;
        const formElement = (
            <div className="container">
                {validationResult.isNotValid() && (
                    <p className="h6" style={{marginTop: marginBelowComponent, marginBottom: marginBelowComponent, lineHeight: '1.5rem', color: 'red'}}>
                        Dane wymagają poprawienia. W poniższym formularzu umieszczone są odpowiednie komunikaty
                    </p>
                )
                }
                <FormHeading text={Msg.of().surveyParamsForm.maxSurveyInstances} center={true}/>
                {this.maxPupilsField.getReactElement(this.defaultFieldParams.withFieldValue(this.surveyParams.maxPupils).withRange(0, this.maxSurveyInstances))}
                {this.maxParentsField.getReactElement(this.defaultFieldParams.withFieldValue(this.surveyParams.maxParents).withRange(0, this.maxSurveyInstances))}
                {this.maxTeachersField.getReactElement(this.defaultFieldParams.withFieldValue(this.surveyParams.maxTeachers).withRange(0, this.maxSurveyInstances))}
            </div>

        );
        return formElement;
    }

    /**
     *
     * @param {SurveyParams} surveyParams
     * @param {number} maxSurveyInstances
     * @param {function} onChangeFunc
     * @param {ValidationResult} validationResult
     * @return {JSX.Element}
     */
    static get = (surveyParams, maxSurveyInstances, onChangeFunc, validationResult) => {
        const surveyParamsForm = new SurveyParamsForm(surveyParams, maxSurveyInstances, onChangeFunc, validationResult);
        return surveyParamsForm.buildForm();
    }


}

export default SurveyParamsForm;