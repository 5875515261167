import Action from "../../Action";
import SurveyStats from "../../../model/SurveyStats";
import SurveyState from "../../states/SurveyState";
import ApiEngine from "../../../logic/ApiEngine";
import Logger from "../../../logic/Logger";

class UpdateQuestsItemsCols extends Action {
    static TYPE = "UpdateQuestsItemsCols";

    /**
     * @param {object} payload
     */
    constructor(payload) {
        super(UpdateQuestsItemsCols.TYPE, payload, prevState => {
            const surveys = prevState.surveys;
            const srwHash = this.payload.srwHash;
            const surveyStatsOld = surveys[srwHash];
            let surveyStatsNew;
            if (!surveyStatsOld) {
                surveyStatsNew = SurveyStats.initial();
                surveyStatsNew.srwHash = srwHash;
            } else {
                surveyStatsNew = surveyStatsOld;
            }
            surveyStatsNew.quests = this.payload.quests;
            surveyStatsNew.items = this.payload.items;
            surveyStatsNew.cols = this.payload.cols;
            surveyStatsNew.lastUpdateLabels = new Date();
            surveys[srwHash] = surveyStatsNew;
            const returningState = SurveyState.get(surveys);
            return returningState;
        });
    }


    static get = (srwHash, quests, items, cols) => {
        return new UpdateQuestsItemsCols({srwHash, quests, items, cols})
    }

    /**
     *
     * @param {function(Object)} dispatch metoda dispatch Reduxa
     * @param {string} srwHash hash badania
     * @param {Date} lastUpdateTime
     * @param {function} funcSuccess
     */
    static run = (dispatch, srwHash, lastUpdateTime, funcSuccess) => {
        const now = new Date();
        if (now.getTime() - lastUpdateTime.getTime() > 1000 * 60 * 10) {
            ApiEngine.getQuests(srwHash, quests => {
                ApiEngine.getItems(srwHash, items => {
                    ApiEngine.getCols(srwHash, cols => {
                        dispatch(UpdateQuestsItemsCols.get(srwHash, quests, items, cols).plain());
                        if(funcSuccess) {
                            funcSuccess();
                        }
                    }, response => {
                        Logger.error(response);
                    })
                }, response => {
                    Logger.error(response);
                })
            }, response => {
                Logger.error(response);
            })
        } else if(funcSuccess) {
            funcSuccess();
        }
    }
}

export default UpdateQuestsItemsCols;