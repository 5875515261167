import ResponsiveRatio from "./ResponsiveRatio";
import ValidationResult from "../../logic/ValidationResult";

class FieldParams {

    static defaultMarginBelowComponent = '2rem';

    /**
     * @public
     * @field
     * @type {string|boolean|number}
     * @description Wartość wpisana w pole lub flaga checked
     */
    fieldValue;


    /**
     * @public
     * @method
     * @type {function}
     * @description Funkcja wykonywana po zmianie zawartości pola
     */
    onChangeFunc;

    /**
     * @public
     * @field
     * @type {ResponsiveRatio}
     * @description Obiekt zawierający współczynniki dotyczące wyświetlania pola dla różnych rozmiarów ekranu. Semantyka zależna od kontekstu
     */
    responsiveRatio;

    /**
     * @public
     * @field
     * @type {string}
     * @description Margines poniżej pola formularza
     */
    marginBelowComponent;

    /**
     * @public
     * @field
     * @type {Object.<number, string>}
     * @description Słownik wartości dostępnych do wyboru, np jeśli polem jest Select (ComboBox)
     */
    dictionary;

    /**
     * @public
     * @field
     * @type {ValidationResult}
     */
    validationResult;

    /**
     * @public
     * @field
     * @type {number}
     */
    from;

    /**
     * @public
     * @field
     * @type {number}
     */
    to;

    /**
     * @param {string|boolean|number} fieldValue
     * @param {function} onChangeFunc
     * @param {ResponsiveRatio} responsiveRatio
     * @param {string} marginBelowComponent
     * @param {Object.<number, string>} dictionary
     * @param {ValidationResult} validationResult
     */
    constructor(fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent, dictionary, validationResult) {
        this.fieldValue = fieldValue;
        this.onChangeFunc = onChangeFunc;
        this.responsiveRatio = responsiveRatio;
        this.marginBelowComponent = marginBelowComponent;
        this.dictionary = dictionary;
        this.validationResult = validationResult;
    }

    /**
     * @param {string|boolean|number} fieldValue
     * @param {function} onChangeFunc
     * @param {ResponsiveRatio} responsiveRatio
     * @param {string} marginBelowComponent
     * @param {Object.<number, string>} dictionary
     * @param {ValidationResult} validationResult
     * @return {FieldParams}
     */
    static get = (fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent, dictionary, validationResult) => {
        return new FieldParams(fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent, dictionary, validationResult);
    }

    /**
     * @public
     * @method
     * @param {string | boolean | number} fieldValue
     * @return {FieldParams}
     */
    withFieldValue(fieldValue) {
        return FieldParams.get(fieldValue, this.onChangeFunc, this.responsiveRatio, this.marginBelowComponent, this.dictionary, this.validationResult);
    }

    /**
     * @public
     * @method
     * @param {boolean} checked
     * @return {any}
     */
    withChecked(checked) {
        return FieldParams.get(checked, this.onChangeFunc, this.responsiveRatio, this.marginBelowComponent, this.dictionary, this.validationResult);
    }

    /**
     * @public
     * @method
     * @param {function} onChangeFunc
     * @return {FieldParams}
     */
    withOnChangeFunc(onChangeFunc) {
        return FieldParams.get(this.fieldValue, onChangeFunc, this.responsiveRatio, this.marginBelowComponent, this.dictionary, this.validationResult);
    }

    /**
     * @public
     * @method
     * @param {ResponsiveRatio} responsiveRatio
     * @return {FieldParams}
     */
    withResponsiveRatio(responsiveRatio) {
        return FieldParams.get(this.fieldValue, this.onChangeFunc, responsiveRatio, this.marginBelowComponent, this.dictionary, this.validationResult);
    }

    /**
     * @public
     * @method
     * @param {string} marginBelowComponent
     * @return {FieldParams}
     */
    withMarginBelowComponent(marginBelowComponent) {
        return FieldParams.get(this.fieldValue, this.onChangeFunc, this.responsiveRatio, marginBelowComponent, this.dictionary, this.validationResult);
    }

    /**
     * @public
     * @method
     * @param {Object.<number, string>} dictionary
     * @return {FieldParams}
     */
    withDictionary(dictionary) {
        return FieldParams.get(this.fieldValue, this.onChangeFunc, this.responsiveRatio, this.marginBelowComponent, dictionary, this.validationResult);
    }

    /**
     * @param {number} from
     * @param {number} to
     * @return {FieldParams}
     */
    withRange(from, to) {
        const newFieldParams = FieldParams.get(this.fieldValue, this.onChangeFunc, this.responsiveRatio, this.marginBelowComponent, this.dictionary, this.validationResult);
        newFieldParams.from = from;
        newFieldParams.to = to;
        return newFieldParams;
    }

}

export default FieldParams;