import React from 'react';
import {NavLink} from "react-router-dom";
import LinkInText from "../visual/LinkInText";
import Msg from "../../i18n/Msg";

const Carousel = () => {
    return (
        <>
            <section className="carousel slide cid-rz6BapiStp" data-interval="false" id="slider1-x">


                <div className="full-screen">
                    <div className="mbr-slider slide carousel" data-pause="true" data-keyboard="false" data-ride="carousel" data-interval="6000">
                        <ol className="carousel-indicators">
                            <li data-app-prevent-settings="" data-target="#slider1-x" data-slide-to="0"></li>
                            <li data-app-prevent-settings="" data-target="#slider1-x" data-slide-to="1"></li>
                            <li data-app-prevent-settings="" data-target="#slider1-x" className="active" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item slider-fullscreen-image" data-bg-video-slide="false" style={{backgroundImage: 'url(assets/images/slide1-1920x1080.jpg)'}}>
                                <div className="container container-slide">
                                    <div className="image_wrapper">
                                        <div className="mbr-overlay" style={{opacity: 0.6}}></div>
                                        <img src="assets/images/slide1-1920x1080.jpg"/>
                                        <div className="carousel-caption justify-content-center">
                                            <div className="col-10 align-center">
                                                <h2 className="mbr-fonts-style display-1"><strong>PROFESJONALNE BADANIA EWALUACJI</strong></h2>
                                                <p className="lead mbr-text mbr-fonts-style display-7">
                                                    Nasza oferta obejmuje możliwość przeprowadzenia badań przed,
                                                    <br/>w trakcie i po zakończeniu realizacji programów będących przedmiotem ewaluacji.
                                                </p>
                                                <div className="mbr-section-btn" buttons="0">
                                                    <NavLink className="btn btn-primary display-7" to={Msg.of().menu.aboutUs.link}>
                                                        DOWIEDZ SIĘ WIĘCEJ
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item slider-fullscreen-image" data-bg-video-slide="false" style={{backgroundImage: 'url(assets/images/slide2-1920x1080.jpg)'}}>
                                <div className="container container-slide">
                                    <div className="image_wrapper">
                                        <div className="mbr-overlay" style={{opacity: 0.6}}></div>
                                        <img src="assets/images/slide2-1920x1080.jpg"/>
                                        <div className="carousel-caption justify-content-center">
                                            <div className="col-10 align-center">
                                                <h2 className="mbr-fonts-style display-1"><strong>WYGODNE ANKIETY EWALUACYJNE</strong></h2>
                                                <p className="lead mbr-text mbr-fonts-style display-7">
                                                    Portal zawiera już przygotowane profesjonalne narzędzie badawcze tworzone przez specjalistów w zakresie edukacji, socjologii oraz
                                                    psychologii. Na podstawie wyników badań otrzymają Państwo raport badawczy, który posłuży Państwu do podniesienia efektywności podejmowanych przez
                                                    Państwa działań programowych.&nbsp;
                                                    <br/>
                                                </p>
                                                <div className="mbr-section-btn" buttons="0">
                                                    <NavLink className="btn btn-primary display-7" to="/oferta">
                                                        DOWIEDZ SIĘ WIĘCEJ
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item slider-fullscreen-image active" data-bg-video-slide="false" style={{backgroundImage: 'url(assets/images/slide3_edukacja_w_cieniu_wirusa_covid_sars_pandemia_szkola.JPG)'}}>
                                <div className="container container-slide">
                                    <div className="image_wrapper">
                                        <div className="mbr-overlay" style={{opacity: 0.6}}></div>
                                        <img src="assets/images/slide3_edukacja_w_cieniu_wirusa_covid_sars_pandemia_szkola.JPG" alt="..."/>
                                        <div className="carousel-caption justify-content-center">
                                            <div className="col-10 align-center">
                                                <h2 className="mbr-fonts-style display-1"><strong>REALIZACJA PODSTAWY PROGRAMOWEJ W CZASIE PANDEMII</strong></h2>
                                                <p className="lead mbr-text mbr-fonts-style display-7">
                                                    Zapraszamy  Szkoły Podstawowe do udziału w nowym projekcie badawczym!
                                                    Jest on próbą spojrzenia na realizację podstawy programowej podczas nauczania zdalnego z punku widzenia uczniów
                                                    klas IV-VIII oraz samooceną rozwoju własnych umiejętności.
                                                    &nbsp;
                                                    <br/>
                                                </p>
                                                <div className="mbr-section-btn" buttons="0">
                                                    <NavLink className="btn btn-primary display-7" to={Msg.of().menu.news.link}>
                                                        DOWIEDZ SIĘ WIĘCEJ
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="carousel-item slider-fullscreen-image active" data-bg-video-slide="false" style={{backgroundImage: 'url(assets/images/slide3_edukacja_w_cieniu_wirusa_covid_sars_pandemia_szkola.JPG)'}}>
                                <div className="container container-slide">
                                    <div className="image_wrapper">
                                        <div className="mbr-overlay" style={{opacity: 0.6}}></div>
                                        <img src="assets/images/slide3_edukacja_w_cieniu_wirusa_covid_sars_pandemia_szkola.JPG" alt="..."/>
                                        <div className="carousel-caption justify-content-center">
                                            <div className="col-10 align-center">
                                                <h2 className="mbr-fonts-style display-1"><strong>EDUKACJA W CIENIU WIRUSA</strong></h2>
                                                <p className="lead mbr-text mbr-fonts-style display-7">
                                                    Ewaluacja.Online wspólnie z Dziennikiem Gazetą Prawną realizują projekt badawczy
                                                    w celu poznania opinii na temat stanu edukacji szkolnej przed nowym rokiem szkolnym. Badanie skierowane jest do dyrektorów szkół,
                                                    prezydentów miast, burmistrzów, wójtów i starostów. Chcemy poznać opinie oraz sugestie, które w okresie pandemii są bardzo ważne.&nbsp;
                                                    <br/>
                                                </p>
                                                <div className="mbr-section-btn" buttons="0">
                                                    <NavLink className="btn btn-primary display-7" to={Msg.of().menu.news.link}>
                                                        DOWIEDZ SIĘ WIĘCEJ
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}

                        </div>
                        <a data-app-prevent-settings="" className="carousel-control carousel-control-prev" role="button" data-slide="prev" href="#slider1-x">
                            <span aria-hidden="true" className="mbri-left mbr-iconfont"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a data-app-prevent-settings="" className="carousel-control carousel-control-next" role="button" data-slide="next" href="#slider1-x">
                            <span aria-hidden="true" className="mbri-right mbr-iconfont"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}


export default Carousel;