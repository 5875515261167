import ApiCacheElement from "./ApiCacheElement";
import Logger from "./Logger";

class ApiCache {
    /**
     *
     * @type {Map<string, ApiCacheElement>}
     */
    static CACHE = new Map();
    static CACHE_ON = true;

    static sweeperON = false;

    /**
     * @type {Set<string>}
     */
    static REQUESTS_WAITING = new Set();

    static runSweeper(){
        ApiCache.sweeperON = true;
        Logger.debug('SWEEPER IS ON');
        const sweeperFunc = () => {
            /**
             * @type {Map<string, ApiCacheElement>}
             */
            if(ApiCache.CACHE.size > 0) {
                const newMap = new Map();
                Logger.debug('--- SWEEPER ---');
                for (let [key, element] of ApiCache.CACHE.entries()) {
                    if (element.isNotExpired()) {
                        newMap.set(key, element);
                    } else {
                        Logger.debug('Remove: ' + key);
                    }
                }
                ApiCache.CACHE = newMap;
                ApiCache.printCache();
                Logger.debug('---------------');
            } else {
                Logger.debug('--- SWEEPER UNEMPLOYED ---');
            }
        }
        setInterval(sweeperFunc, 60 * 1000);
    }

    static printCache() {
        Logger.debug('--');
        Logger.debug('CACHE contains: ');
        for(let key of ApiCache.CACHE.keys()) {
            Logger.debug(key);
        }
    }

    /**
     *
     * @param {string} url
     * @param {object} formData
     * @param {object} response
     */
    static put(url, formData, response) {
        if(ApiCache.CACHE_ON) {
            if (response && url) {
                ApiCache.CACHE.set(ApiCache.key(url, formData), ApiCacheElement.get(response));
            }
            if (!ApiCache.sweeperON) {
                ApiCache.runSweeper();
            }
        }
    }

    /**
     *
     * @param {string} url
     * @param {object} formData
     * @return {ApiCacheElement}
     */
    static get(url, formData) {
        const apiCacheElement = ApiCache.CACHE.get(ApiCache.key(url, formData));
        if(apiCacheElement && apiCacheElement.isNotExpired()) {
            return apiCacheElement;
        } else {
            return null;
        }
    }

    /**
     *
     * @param {string} url
     * @param {object} formData
     * @return {string}
     */
    static key(url, formData) {
        if(!formData) {
            formData = {}
        }
        if(!url) {
            url = '';
        }

        return url + JSON.stringify(formData);
    }

    static clear() {
        ApiCache.CACHE.clear();
    }

    static waitWith(url, formData) {
        if(ApiCache.CACHE_ON) {
            const key = ApiCache.key(url, formData);
            ApiCache.REQUESTS_WAITING.add(key);
            setTimeout(() => {
               ApiCache.releaseWaiting(url, formData);
            }, 5000);
        }
    }

    static releaseWaiting(url, formData) {
        const key = ApiCache.key(url, formData);
        if(ApiCache.REQUESTS_WAITING.has(key)) {
            ApiCache.REQUESTS_WAITING.delete(key);
        }
    }

    static needWaiting(url, formData) {
       return ApiCache.REQUESTS_WAITING.has(ApiCache.key(url, formData));
    }
}

export default ApiCache;