import React from 'react';
import TitleBanner from "../components/website/TitleBanner";
import icon from '../img/bazawiedzy/icon.jpg';
import img1 from '../img/bazawiedzy/img1.jpg';
import img2 from '../img/bazawiedzy/img2.jpg';
import img3 from '../img/bazawiedzy/img3.jpg';
import img4 from '../img/bazawiedzy/img4.jpg';
import img5 from '../img/bazawiedzy/img5.jpg';
import doktor1 from '../img/bazawiedzy/doktor1.jpg';
import doktor2 from '../img/bazawiedzy/doktor2.jpg';
import doktor3 from '../img/bazawiedzy/doktor3.jpg';
import doktor4 from '../img/bazawiedzy/doktor4.jpg';
import doktor5 from '../img/bazawiedzy/doktor5.jpg';
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import GreenButton from "../components/visual/GreenButton";
import Msg from "../i18n/Msg";
import {connect} from 'react-redux';
import State from "../store/State";
import SetActiveMenuItemAction from "../store/actions/profile/SetActiveMenuItemAction";

const PageInfoGraphic = (props) => {
    const profileState = State.getProfileState(props);
    SetActiveMenuItemAction.run(props.dispatch, profileState.activeMenuItem, Msg.of().menu.infoGraphic.label);
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="O EWALUACJI"/>


            <div>
                <section className="mbr-section article content1 cid-rz81bDKPdj newcontent1" id="content1-1k" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="media-container-row">
                            <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                                <div style={{width: '100%', background: `url(${img1})`}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '102px', marginBottom: '33px', paddingLeft: '106px'}}>Co to jest ewaluacja?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '250px'}}>Ewaluacja wewnętrzna to jeden z filarów obecnego systemu nadzoru pedagogicznego w polskich szkołach i placówkach oświatowych. Przeprowadza się ją w odniesieniu do zagadnień uznanych za ważne i istotne, a jej celem nadrzędnym jest dostarczenie informacji, które zostaną wykorzystane do doskonalenia jakości pracy szkoły lub placówki.
                                    </p><p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '0px', paddingLeft: '106px', paddingRight: '250px', paddingBottom: '19px'}}>Dzięki odpowiednio przygotowanemu procesowi badawczemu uzyskujemy wyniki, których wykorzystanie wpisuje się w realizację kierunków polityki oświatowej państwa na dany rok szkolny zgodnie z art. 60 ustawy – Prawo oświatowe.</p>
                                </div>
                                <div style={{width: '100%', background: `url(${img2})`, marginTop: '-1px'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '4px', paddingLeft: '106px'}}>Jak przeprowadzamy ewaluację?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '100px'}}>Istnieje kilka modeli przeprowadzania ewaluacji w szkołach. Wiele z nich koncentruje się jedynie na jednorazowym pomiarze, tzw. badaniu na wejściu. Takie ograniczenie nie pozwala na określenie skuteczności podjętych działań, wskazanie czynników, które wpłynęły na rezultat, a tym samym kierunków modyfikacji. Innym zagrożeniem jakości ewaluacji wewnętrznej jest niski poziom wykorzystania wniosków i rekomendacji w praktyce szkolnej. Często spotyka się również ewaluacje prowadzone jedynie po to, aby spełnić formalny obowiązek wynikający z nadzoru pedagogicznego, bez zaangażowania interesariuszy oraz realizatorów ewaluacji.</p>
                                    <p style={{fontSize: '22px', textTransform: 'uppercase', lineHeight: '26px', marginBottom: '5px', paddingLeft: '344px', paddingRight: '100px', color: '#49ad5c', fontWeight: 'bold', textAlign: 'center', paddingTop: '60px'}}>Przykład</p>
                                    <p style={{fontSize: '18px', lineHeight: '30px', marginBottom: '5px', paddingLeft: '344px', paddingRight: '100px', textAlign: 'center'}}>Szkoła chciała sprawdzić, czy pewna metoda nauczania języka angielskiego ma wpływ na osiągane przez uczniów wyniki w nauce. Na początku zostaje przeprowadzony test zdolności językowych wśród badanych uczniów (faza pre-test).</p>
                                    <p style={{fontSize: '18px', lineHeight: '30px', marginBottom: '5px', paddingLeft: '344px', paddingRight: '100px', textAlign: 'center', paddingTop: '10px', paddingBottom: '88px'}}>Po pewnym czasie szkoła przeprowadziła ponownie test zdolności językowych w grupie (faza post-test). Następnie badacz sprawdził, czy  wzrost zdolności językowych uczniów był większy przed wprowadzeniem metody nauczania języka angielskiego.</p>
                                </div>
                                <div style={{width: '100%', background: `url(${img3})`, marginTop: '-5px'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '13px', paddingLeft: '106px'}}>Kiedy przeprowadzamy ewaluację?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>Istotnym elementem jest okres przeprowadzenia badań (ewaluacji) w szkołach i placówkach oświatowych. Rok szkolny rządzi się w pewien sposób swoimi prawami. Po wybraniu przez szkołę/placówkę oświatową kierunku realizowanego programu, badanie powinno być zrealizowane przed wprowadzeniem zamierzonych działań. W ten sposób uzyskamy obiektywne wyniki postaw uczniów, rodziców/opiekunów oraz nauczycieli.</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '0px', paddingLeft: '106px', paddingRight: '275px', paddingBottom: '59px'}}>Po analizie wyników badań możemy celniej dopasować program, tak by zwiększyć efektywność podejmowanych działań.</p>
                                </div>
                                <div style={{width: '100%', background: `url(${img4})`, marginTop: '-5px'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '31px', paddingLeft: '479px'}}>Co robimy, aby spełnić wymogi RODO?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '320px', paddingRight: '96px', textAlign: 'center'}}>Wykorzystując naszą platformę do badań w szkołach i placówkach oświatowych musimy dochować obowiązków związanych z powierzeniem przetwarzania danych osobowych. Nasza platforma pomaga szkołom i placówkom oświatowym w wysyłce e-maili, bądź też agregowaniu odpowiedzi respondentów ankiet. Nie przetwarzamy przekazywanych nam danych.</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '0px', paddingLeft: '320px', paddingRight: '96px', paddingBottom: '35px', textAlign: 'center'}}>Jesteśmy platformą badawczą oferującą usługi dla edukacji i przetwarzającą dane w oparciu o powierzenie, ale nie jesteśmy odbiorcą tych danych (w rozumieniu przepisów dane nie są nam udostępniane, lecz powierzane). dlatego też szkoły i placówki oświatowe nie muszą wskazywać nas w obowiązku informacyjnym.</p>
                                </div>
                                <div style={{width: '100%', background: `url(${img5})`, marginTop: '-5px', marginBottom: '29px'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '30px', paddingLeft: '106px'}}>Jak zwiększamy próbę badania?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>Jak zwiększyć próbę badania? Jak przeprowadzić badania, aby jak najwięcej osób wypełniło nam ankietę?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>Istotnym elementem w procesie badawczym jest wielkość próby, czyli ile osób nam odpowiedziało na naszą ankietę. Im więcej osób nam odpowie na ankietę, tym nasze wyniki będą bardziej wiarygodne. Mamy trzy grupy docelowe: uczniowie, rodzice/opiekunowie i nauczyciele. W przypadku uczniów ankieta może być przeprowadzona np. podczas zajęć komputerowych, gdzie każdy uczeń po zalogowaniu się odpowie klikając na wybrane odpowiedzi. Możemy dzięki naszej platformie przeprowadzić badania prawie wśród całej grupy docelowej, w zależności od wieku czy wybranych klas szkolnych. Tak samo możemy potraktować grupę nauczycieli. Przyjazność naszej platformy sprawi, że czas na wypełnienie ankiety jest ograniczony do niezbędnego minimum.</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '20px', paddingLeft: '106px', paddingRight: '275px'}}>Najtrudniejszą grupą do zbadania są rodzice/opiekunowie, im wyższe roczniki klas, tym trudniej jest namówić tę grupę na pewną formę współpracy ze szkołą, jak również wpłynąć na jej zaangażowanie w wypełnieniu ankiet. Nasze doświadczenie podpowiada nam kilka dobrych rozwiązań, które mogą zwiększyć liczbę uzyskanych odpowiedzi od ww. osób:</p>
                                    <div style={{float: 'left', width: '50px', marginLeft: '106px'}}><img src={icon} alt="" style={{}} /></div><div style={{float: 'left', width: '650px'}}><p style={{fontSize: '18px', lineHeight: '26px'}}>Podczas spotkań z rodzicami i opiekunami dzieci warto wspomnieć, że w najbliższym czasie otrzymają ankietę z prośbą o jej wypełnienie. W przypadku braku danych kontaktowych, tj. adres e-mail, warto rozdać ankietę w wersji papierowej. Wersja papierowa wydłuża jednak czas zbierania danych, ponieważ każdą ankietę z osobna należy wprowadzić do platformy.</p></div><div style={{clear: 'both'}} />
                                    <div style={{float: 'left', width: '50px', marginLeft: '106px'}}><img src={icon} alt="" style={{}} /></div><div style={{float: 'left', width: '650px'}}><p style={{fontSize: '18px', lineHeight: '26px'}}>Przesłanie linku do wypełniania ankiety warto poprzedzić krótkim mailingiem do rodziców/opiekunów, tak aby byli przygotowani na informację o planowanym badaniu;</p></div><div style={{clear: 'both'}} />
                                    <div style={{float: 'left', width: '50px', marginLeft: '106px'}}><img src={icon} alt="" style={{}} /></div><div style={{float: 'left', width: '650px'}}><p style={{fontSize: '18px', lineHeight: '26px'}}>Istotnym elementem komunikacji pomiędzy szkołą/placówką oświatową jest dziennik ucznia. Warto poprzez to narzędzie wysłać link z naszą ankietą, gdzie rodzicom/opiekunom po jego kliknięciu otworzy się ankieta do wypełnienia. Nasza platforma jest dostosowana do każdej formy odbioru ankiety, również poprzez telefony komórkowe (wersja mobilna);</p></div><div style={{clear: 'both'}} />
                                    <div style={{float: 'left', width: '50px', marginLeft: '106px'}}><img src={icon} alt="" style={{}} /></div><div style={{float: 'left', width: '650px'}}><p style={{fontSize: '18px', lineHeight: '26px'}}>Warto też wykorzystać nieformalne fora komunikacji rodziców typu WhatsApp czy Facebook , gdyż stanowią one ważną rolę w komunikacji z rodzicami/ opiekunami dzieci.</p></div><div style={{clear: 'both', paddingBottom: '133px'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mbr-section article content1 cid-rz81bDKPdj newcontent2" id="content1-1k" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="media-container-row">
                            <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                                <div style={{width: '100%'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#4AAD5B', marginTop: '40px', textAlign: 'center'}}>Co to jest ewaluacja?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Ewaluacja wewnętrzna to jeden z filarów obecnego systemu nadzoru pedagogicznego w polskich szkołach i placówkach oświatowych. Przeprowadza się ją w odniesieniu do zagadnień uznanych za ważne i istotne, a jej celem nadrzędnym jest dostarczenie informacji, które zostaną wykorzystane do doskonalenia jakości pracy szkoły lub placówki.
                                    </p><p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Dzięki odpowiednio przygotowanemu procesowi badawczemu uzyskujemy wyniki, których wykorzystanie wpisuje się w realizację kierunków polityki oświatowej państwa na dany rok szkolny zgodnie z art. 60 ustawy – Prawo oświatowe.</p>
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={doktor1} alt="" style={{}} /></div>
                                </div>
                                <div style={{width: '100%'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#4AAD5B', marginTop: '40px', textAlign: 'center', lineHeight: '34px'}}>Jak przeprowadzamy ewaluację?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Istnieje kilka modeli przeprowadzania ewaluacji w szkołach. Wiele z nich koncentruje się jedynie na jednorazowym pomiarze, tzw. badaniu na wejściu. Takie ograniczenie nie pozwala na określenie skuteczności podjętych działań, wskazanie czynników, które wpłynęły na rezultat, a tym samym kierunków modyfikacji. Innym zagrożeniem jakości ewaluacji wewnętrznej jest niski poziom wykorzystania wniosków i rekomendacji w praktyce szkolnej. Często spotyka się również ewaluacje prowadzone jedynie po to, aby spełnić formalny obowiązek wynikający z nadzoru pedagogicznego, bez zaangażowania interesariuszy oraz realizatorów ewaluacji.</p>
                                    <p style={{fontSize: '22px', textTransform: 'uppercase', lineHeight: '26px', color: '#49ad5c', fontWeight: 'bold', textAlign: 'center'}}>Przykład</p>
                                    <p style={{fontSize: '18px', lineHeight: '30px', marginBottom: '25px', textAlign: 'center'}}>Szkoła chciała sprawdzić, czy pewna metoda nauczania języka angielskiego ma wpływ na osiągane przez uczniów wyniki w nauce. Na początku zostaje przeprowadzony test zdolności językowych wśród badanych uczniów (faza pre-test).</p>
                                    <p style={{fontSize: '18px', lineHeight: '30px', marginBottom: '25px', textAlign: 'center'}}>Po pewnym czasie szkoła przeprowadziła ponownie test zdolności językowych w grupie (faza post-test). Następnie badacz sprawdził, czy  wzrost zdolności językowych uczniów był większy przed wprowadzeniem metody nauczania języka angielskiego.</p>
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={doktor2} alt="" style={{}} /></div>
                                </div>
                                <div style={{width: '100%'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#4AAD5B', marginTop: '40px', textAlign: 'center', lineHeight: '34px'}}>Kiedy przeprowadzamy ewaluację?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Istotnym elementem jest okres przeprowadzenia badań (ewaluacji) w szkołach i placówkach oświatowych. Rok szkolny rządzi się w pewien sposób swoimi prawami. Po wybraniu przez szkołę/placówkę oświatową kierunku realizowanego programu, badanie powinno być zrealizowane przed wprowadzeniem zamierzonych działań. W ten sposób uzyskamy obiektywne wyniki postaw uczniów, rodziców/opiekunów oraz nauczycieli.</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Po analizie wyników badań możemy celniej dopasować program, tak by zwiększyć efektywność podejmowanych działań.</p>
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={doktor3} alt="" style={{}} /></div>
                                </div>
                                <div style={{width: '100%'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#4AAD5B', marginTop: '40px', textAlign: 'center', lineHeight: '34px'}}>Co robimy, aby spełnić wymogi RODO?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Wykorzystując naszą platformę do badań w szkołach i placówkach oświatowych musimy dochować obowiązków związanych z powierzeniem przetwarzania danych osobowych. Nasza platforma pomaga szkołom i placówkom oświatowym w wysyłce e-maili, bądź też agregowaniu odpowiedzi respondentów ankiet. Nie przetwarzamy przekazywanych nam danych.</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Jesteśmy platformą badawczą oferującą usługi dla edukacji i przetwarzającą dane w oparciu o powierzenie, ale nie jesteśmy odbiorcą tych danych (w rozumieniu przepisów dane nie są nam udostępniane, lecz powierzane). dlatego też szkoły i placówki oświatowe nie muszą wskazywać nas w obowiązku informacyjnym.</p>
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={doktor4} alt="" style={{}} /></div>
                                </div>
                                <div style={{width: '100%'}}>
                                    <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#4AAD5B', marginTop: '40px', textAlign: 'center', lineHeight: '34px'}}>Jak zwiększamy próbę badania?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Jak zwiększyć próbę badania? Jak przeprowadzić badania, aby jak najwięcej osób wypełniło nam ankietę?</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Istotnym elementem w procesie badawczym jest wielkość próby, czyli ile osób nam odpowiedziało na naszą ankietę. Im więcej osób nam odpowie na ankietę, tym nasze wyniki będą bardziej wiarygodne. Mamy trzy grupy docelowe: uczniowie, rodzice/opiekunowie i nauczyciele. W przypadku uczniów ankieta może być przeprowadzona np. podczas zajęć komputerowych, gdzie każdy uczeń po zalogowaniu się odpowie klikając na wybrane odpowiedzi. Możemy dzięki naszej platformie przeprowadzić badania prawie wśród całej grupy docelowej, w zależności od wieku czy wybranych klas szkolnych. Tak samo możemy potraktować grupę nauczycieli. Przyjazność naszej platformy sprawi, że czas na wypełnienie ankiety jest ograniczony do niezbędnego minimum.</p>
                                    <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '25px', textAlign: 'center'}}>Najtrudniejszą grupą do zbadania są rodzice/opiekunowie, im wyższe roczniki klas, tym trudniej jest namówić tę grupę na pewną formę współpracy ze szkołą, jak również wpłynąć na jej zaangażowanie w wypełnieniu ankiet. Nasze doświadczenie podpowiada nam kilka dobrych rozwiązań, które mogą zwiększyć liczbę uzyskanych odpowiedzi od ww. osób:</p>
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={icon} alt="" style={{}} /></div><div><p style={{fontSize: '18px', lineHeight: '26px', textAlign: 'center', marginBottom: '20px'}}>Podczas spotkań z rodzicami i opiekunami dzieci warto wspomnieć, że w najbliższym czasie otrzymają ankietę z prośbą o jej wypełnienie. W przypadku braku danych kontaktowych, tj. adres e-mail, warto rozdać ankietę w wersji papierowej. Wersja papierowa wydłuża jednak czas zbierania danych, ponieważ każdą ankietę z osobna należy wprowadzić do platformy.</p></div><div style={{clear: 'both'}} />
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={icon} alt="" style={{}} /></div><div><p style={{fontSize: '18px', lineHeight: '26px', textAlign: 'center', marginBottom: '20px'}}>Przesłanie linku do wypełniania ankiety warto poprzedzić krótkim mailingiem do rodziców/opiekunów, tak aby byli przygotowani na informację o planowanym badaniu;</p></div><div style={{clear: 'both'}} />
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={icon} alt="" style={{}} /></div><div><p style={{fontSize: '18px', lineHeight: '26px', textAlign: 'center', marginBottom: '20px'}}>Istotnym elementem komunikacji pomiędzy szkołą/placówką oświatową jest dziennik ucznia. Warto poprzez to narzędzie wysłać link z naszą ankietą, gdzie rodzicom/opiekunom po jego kliknięciu otworzy się ankieta do wypełnienia. Nasza platforma jest dostosowana do każdej formy odbioru ankiety, również poprzez telefony komórkowe (wersja mobilna);</p></div><div style={{clear: 'both'}} />
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}><img src={icon} alt="" style={{}} /></div><div><p style={{fontSize: '18px', lineHeight: '26px', textAlign: 'center', marginBottom: '20px'}}>Warto też wykorzystać nieformalne fora komunikacji rodziców typu WhatsApp czy Facebook , gdyż stanowią one ważną rolę w komunikacji z rodzicami/ opiekunami dzieci.</p></div><div style={{textAlign: 'center', marginBottom: '20px'}}><img src={doktor5} alt="" style={{}} /></div><div style={{clear: 'both', paddingBottom: '40px'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>






{/*
            <section className="mbr-section article content1 cid-rz81bDKPdj" id="content1-1k" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <div style={{width: '100%', background: `url(${img1})`}}>
                                <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '101px', paddingBottom: '18px',
                                    paddingLeft: '106px'}}>
                                    Co to jest ewaluacja?
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '250px'}}>
                                    Ewaluacja wewnętrzna to jeden z filarów obecnego systemu nadzoru pedagogicznego w polskich szkołach i placówkach oświatowych.
                                    Przeprowadza się ją w odniesieniu do zagadnień uznanych za ważne i istotne, a jej celem nadrzędnym jest dostarczenie informacji,
                                    które zostaną wykorzystane do doskonalenia jakości pracy szkoły lub placówki.
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '0px', paddingLeft: '106px', paddingRight: '250px', paddingBottom: '19px'}}>
                                    Dzięki odpowiednio przygotowanemu procesowi badawczemu uzyskujemy wyniki, których wykorzystanie wpisuje się w realizację
                                    kierunków polityki oświatowej państwa na dany rok szkolny zgodnie z art. 60 ustawy – Prawo oświatowe.
                                </p>
                            </div>
                            <div style={{width: '100%', background: `url(${img2})`, marginTop: '-1px'}}>
                                <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '4px', paddingLeft: '106px'}}>
                                    Jak przeprowadzamy ewaluację?
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '100px'}}>
                                    Istnieje kilka modeli przeprowadzania ewaluacji w szkołach. Wiele z nich koncentruje się jedynie na jednorazowym pomiarze,
                                    tzw. badaniu na wejściu. Takie ograniczenie nie pozwala na określenie skuteczności podjętych działań, wskazanie czynników,
                                    które wpłynęły na rezultat, a tym samym kierunków modyfikacji. Innym zagrożeniem jakości ewaluacji wewnętrznej jest niski
                                    poziom wykorzystania wniosków i rekomendacji w praktyce szkolnej. Często spotyka się również ewaluacje prowadzone jedynie
                                    po to, aby spełnić formalny obowiązek wynikający z nadzoru pedagogicznego, bez zaangażowania interesariuszy oraz realizatorów ewaluacji.
                                </p>
                                <p style={{fontSize: '22px', textTransform: 'uppercase', lineHeight: '26px', marginBottom: '5px', paddingLeft: '344px', paddingRight: '100px', color: '#49ad5c',
                                    fontWeight: 'bold', textAlign: 'center', paddingTop: '60px'}}>
                                    Przykład
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '30px', marginBottom: '5px', paddingLeft: '344px', paddingRight: '100px', textAlign: 'center'}}>
                                    Szkoła chciała sprawdzić, czy pewna metoda nauczania języka angielskiego ma
                                    wpływ na osiągane przez uczniów wyniki w nauce. Na&nbsp;początku zostaje przeprowadzony test zdolności językowych wśród badanych uczniów (faza pre-test).
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '30px', marginBottom: '5px', paddingLeft: '344px', paddingRight: '100px', textAlign: 'center', paddingTop: '10px', paddingBottom: '88px'}}>
                                    Po pewnym czasie szkoła przeprowadziła ponownie test zdolności językowych w grupie (faza post-test). Następnie badacz sprawdził, czy&nbsp;
                                    wzrost zdolności językowych uczniów był większy przed wprowadzeniem metody nauczania języka angielskiego.
                                </p>
                            </div>

                            <div style={{width: '100%', background: `url(${img3})`, marginTop: '-5px'}}>
                                <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '13px', paddingLeft: '106px',}}>
                                    Kiedy przeprowadzamy ewaluację?
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>
                                    Istotnym elementem jest okres przeprowadzenia badań (ewaluacji) w szkołach i&nbsp;placówkach oświatowych. Rok szkolny rządzi się w pewien sposób swoimi prawami.
                                    Po wybraniu przez szkołę/placówkę oświatową kierunku realizowanego programu, badanie powinno być zrealizowane przed wprowadzeniem
                                    zamierzonych działań. W ten sposób uzyskamy obiektywne wyniki postaw uczniów, rodziców/opiekunów oraz nauczycieli.
                                </p>
                                <p style={{fontSize: '18px',lineHeight: '26px',marginBottom: '0px',paddingLeft: '106px',paddingRight: '275px',paddingBottom: '59px'}}>
                                    Po analizie wyników badań możemy celniej dopasować program, tak by zwiększyć
                                    efektywność podejmowanych działań.
                                </p>
                            </div>

                            <div style={{width: '100%', background: `url(${img4})`, marginTop: '-5px'}}>
                                <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '31px', paddingLeft: '479px',}}>
                                    Co robimy, aby spełnić wymogi RODO?
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '320px', paddingRight: '96px', textAlign: 'center'}}>
                                    Wykorzystując naszą platformę do badań w szkołach i placówkach oświatowych musimy dochować obowiązków związanych z powierzeniem przetwarzania danych osobowych.
                                    Nasza platforma pomaga szkołom i&nbsp;placówkom oświatowym w wysyłce e-maili, bądź też agregowaniu odpowiedzi
                                    respondentów ankiet. Nie przetwarzamy przekazywanych nam danych.
                                </p>
                                <p style={{fontSize: '18px',lineHeight: '26px',marginBottom: '0px',paddingLeft: '320px', paddingRight: '96px', paddingBottom: '35px', textAlign: 'center'}}>
                                    Jesteśmy platformą badawczą oferującą usługi dla edukacji i&nbsp;przetwarzającą dane w oparciu
                                    o powierzenie, ale nie jesteśmy odbiorcą tych danych (w rozumieniu przepisów dane nie są nam udostępniane, lecz powierzane).
                                    dlatego też szkoły i placówki oświatowe nie muszą wskazywać nas w obowiązku informacyjnym.
                                </p>
                            </div>

                            <div style={{width: '100%', background: `url(${img5})`, marginTop: '-5px', marginBottom: '29px',}}>
                                <p style={{fontSize: '24px', textTransform: 'uppercase', fontWeight: 'bold', color: '#fff', paddingTop: '30px', paddingLeft: '106px',}}>
                                    Jak zwiększamy próbę badania?
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>
                                    Jak zwiększyć próbę badania? Jak przeprowadzić badania, aby jak najwięcej osób wypełniło nam ankietę?
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>
                                    Istotnym elementem w procesie badawczym jest wielkość próby, czyli ile osób nam odpowiedziało na
                                    naszą ankietę. Im więcej osób nam odpowie na ankietę, tym nasze wyniki będą bardziej wiarygodne. Mamy trzy grupy docelowe: uczniowie, rodzice/opiekunowie i nauczyciele.
                                    W przypadku uczniów ankieta może być przeprowadzona np. podczas zajęć komputerowych, gdzie każdy uczeń po zalogowaniu się odpowie klikając na wybrane odpowiedzi.
                                    Możemy dzięki naszej platformie przeprowadzić badania prawie wśród całej grupy docelowej, w zależności od wieku czy wybranych klas szkolnych.
                                    Tak samo możemy potraktować grupę nauczycieli. Przyjazność naszej platformy sprawi, że czas na wypełnienie ankiety jest ograniczony do niezbędnego minimum.
                                </p>
                                <p style={{fontSize: '18px', lineHeight: '26px', marginBottom: '5px', paddingLeft: '106px', paddingRight: '275px'}}>
                                    Najtrudniejszą grupą do zbadania są rodzice/opiekunowie, im wyższe roczniki klas, tym trudniej jest namówić tę grupę na pewną formę współpracy ze szkołą,
                                    jak również wpłynąć na jej zaangażowanie w wypełnieniu ankiet. Nasze doświadczenie podpowiada nam kilka dobrych rozwiązań, które mogą zwiększyć
                                    liczbę uzyskanych odpowiedzi od ww. osób:
                                </p>
                                <div style={{float: 'left', width: '50px', marginLeft: '106px'}}>
                                    <img src={icon} alt="" style={{}}/>
                                </div>
                                <div style={{float: 'left', width: '650px'}}>
                                    <p style={{fontSize: '18px',lineHeight: '26px'}}>
                                        Podczas spotkań z rodzicami i opiekunami dzieci warto wspomnieć, że w najbliższym czasie otrzymają ankietę z prośbą o jej wypełnienie.
                                        W przypadku braku danych kontaktowych, tj. adres e-mail, warto rozdać ankietę w wersji papierowej. Wersja papierowa wydłuża jednak czas zbierania danych,
                                        ponieważ każdą ankietę z osobna należy wprowadzić do platformy.
                                    </p>
                                </div>
                                <div style={{clear: 'both'}}></div>
                                <div style={{float: 'left', width: '50px', marginLeft: '106px'}}>
                                    <img src={icon} alt="" style={{}}/>
                                </div>
                                <div style={{float: 'left', width: '650px'}}>
                                    <p style={{fontSize: '18px',lineHeight: '26px'}}>
                                        Przesłanie linku do wypełniania ankiety warto poprzedzić krótkim mailingiem do rodziców/opiekunów, tak aby byli
                                        przygotowani na informację o planowanym badaniu;
                                    </p>
                                </div>
                                <div style={{clear: 'both'}}></div>
                                <div style={{float: 'left', width: '50px', marginLeft: '106px'}}>
                                    <img src={icon} alt="" style={{}}/>
                                </div>
                                <div style={{float: 'left', width: '650px'}}>
                                    <p style={{fontSize: '18px',lineHeight: '26px'}}>
                                        Istotnym elementem komunikacji pomiędzy szkołą/placówką oświatową jest dziennik ucznia. Warto poprzez to narzędzie wysłać link z naszą ankietą,
                                        gdzie rodzicom/opiekunom po jego kliknięciu otworzy się ankieta do wypełnienia. Nasza platforma jest dostosowana do każdej formy odbioru ankiety,
                                        również poprzez telefony komórkowe (wersja mobilna);
                                    </p>
                                </div>
                                <div style={{clear: 'both'}}></div>
                                <div style={{float: 'left', width: '50px', marginLeft: '106px'}}>
                                    <img src={icon} alt="" style={{}}/>
                                </div>
                                <div style={{float: 'left', width: '650px'}}>
                                    <p style={{fontSize: '18px',lineHeight: '26px'}}>
                                        Warto też wykorzystać nieformalne fora komunikacji rodziców typu WhatsApp czy Facebook , gdyż stanowią one ważną rolę w
                                        komunikacji z rodzicami/ opiekunami dzieci.
                                    </p>
                                </div>
                                <div style={{clear: 'both', paddingBottom: '147px'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            <GreenButton label="Przydatne linki" internalLink={Msg.of().menu.usefulLinks.link}/>
            <GreenBottomBanner/>
        </>
    );
};

const PageInfoGraphicConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageInfoGraphic);

export default PageInfoGraphicConnectedToRedux;