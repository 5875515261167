import Action from "../../Action";
import SurveyStats from "../../../model/SurveyStats";
import SurveyState from "../../states/SurveyState";
import ApiEngine from "../../../logic/ApiEngine";
import Logger from "../../../logic/Logger";
import Current from "../../../profiles/Current";

class UpdateStats extends Action {
    static TYPE = "UpdateStats";

    /**
     * @param {object} payload
     */
    constructor(payload) {
        super(UpdateStats.TYPE, payload,
            /**
             * @param {SurveyState} prevState
             * @return {Object}
             */
            prevState => {
                const surveys = prevState.surveys;
                const srwHash = this.payload.srwHash;
                const surveyStatsOld = surveys[srwHash];
                let surveyStatsNew;
                if (!surveyStatsOld) {
                    surveyStatsNew = SurveyStats.initial();
                    surveyStatsNew.srwHash = srwHash;
                } else {
                    surveyStatsNew = surveyStatsOld;
                }
                surveyStatsNew.stats = this.payload.stats;
                surveyStatsNew.lastUpdateStats = new Date();
                surveys[srwHash] = surveyStatsNew;
                return SurveyState.get(surveys);
            });
    }

    /**
     *
     * @param {string} srwHash
     * @param {StatisticData[]} stats
     * @return {UpdateStats}
     */
    static get = (srwHash, stats) => {
        return new UpdateStats({srwHash, stats})
    }

    static getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     *
     * @param {function(Object)} dispatch metoda dispatch Reduxa
     * @param {string} srwHash hash badania
     * @param {Date} lastUpdateTime
     * @param {function} funcSuccess
     */
    static run = (dispatch, srwHash, lastUpdateTime, funcSuccess) => {
        const now = new Date();
        if (now.getTime() - lastUpdateTime.getTime() > 10000) {
            ApiEngine.getStatisticData(srwHash, statisticDataTable => {
                Logger.debug("POBIERANIE DANYCH.... !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + now.toString() + ' - ' + lastUpdateTime.toString())
                /** @type {StatisticData[]} */
                const table = statisticDataTable;
                if(Current.PROFILE.isDev()) {
                    table.forEach(stat => stat.count = UpdateStats.getRandomIntInclusive(1, 50))
                }
                dispatch(UpdateStats.get(srwHash, table).plain());
                if (funcSuccess) {
                    funcSuccess();
                }
            }, response => {
                Logger.error(response);
            })
        } else if (funcSuccess) {
            funcSuccess();
        }
    }
}

export default UpdateStats;