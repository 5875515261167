import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ResponsiveRatio from "../ResponsiveRatio";

class Range extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
        marginBottom: PropTypes.string,
        ratio: PropTypes.shape(ResponsiveRatio.shape()).isRequired,
        fieldValue: PropTypes.number,
        onChangeFunc: PropTypes.func,
        big: PropTypes.bool
    }

    render() {
        const {id, label, name, from, to, marginBottom, ratio, fieldValue, onChangeFunc, big} = this.props;

        let fieldStyle;
        if(marginBottom) {
            fieldStyle = {"marginBottom": marginBottom};
        } else {
            fieldStyle = {}
        }

        let style;
        if(big) {
            style = {
                minHeight: '3.5em',
                fontSize: '1.5rem',
                fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
                backgroundColor: 'white'
            }
        } else {
            style = {
                minHeight: '1.5em',
                fontSize: '1rem',
                fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                backgroundColor: 'white'
            }
        }

        style = {}

        return (
            <>
                <div className="row align-items-center" style={fieldStyle}>
                    {/*<div className={ratio.getLabelClasses()} style={{marginTop: '0.6rem'}}>
                        <p className="h6"><strong>{label}</strong></p>
                    </div>
                    <div className={ratio.getControlClasses()}>*/}
                    <div className="col-12">
                        <table className="table table-sm table-borderless">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="align-center"><strong>{label}: {fieldValue}</strong></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="align-right">{from}</td>
                                    <td className="w-100">
                                        <input className="custom-range" name={name} type="range" value={fieldValue} onChange={onChangeFunc} style={style} min={from} max={to} id={id}/>
                                    </td>
                                    <td className="align-left">{to}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </>
        );
    }
}



export default Range;