import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import TitleBanner from "../../components/website/TitleBanner";
import ApiEngine from "../../logic/ApiEngine";
import User from "../../model/User";
import Field from "../../form/components/Field";
import ValidationResult from "../../logic/ValidationResult";
import UserForm from "../../form/UserForm";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../../i18n/Msg";
import ScrollToTopOnMount from "../../components/helpers/ScrollToTopOnMount";
import InfoTable from "../../components/visual/InfoTable";

class PageSystemUsers extends Component {

    state = {
        users: [],
        activeUserID: 0,
        user: User.getDefault(),
        validationResult: ValidationResult.get()
    }

    usersListValid = false;

    loadUsersList = () => {
        ApiEngine.usersList(users => {
            let activeUserID = 0;
            if (users && users.length > 0) {
                activeUserID = users[0].id;
            }
            this.usersListValid = true;
            this.setState({users, activeUserID});
        }, null);
    }

    changeActiveUser = id => {
        const user = this.state.users.find(usr => usr.id === id);
        this.setState({activeUserID: id, user});
    }

    /**
     * @param {object} e zdarzenie
     * @param {string} objectName nazwa obiektu w state
     */
    handleStateObject = (e, objectName) => {
        e.persist();
        this.setState(prevState => {
            const fromEvent = Field.readFromEvent(e);
            const newState = prevState;
            newState[objectName][fromEvent.fieldName] = fromEvent.fieldValue;
            return newState;
        })
    }

    /**
     * @description funkcja generująca funkcje handle dla formularza
     * @param objectName
     * @return {function}
     */
    handleGenerator = objectName => {
        const handleFunc = e => {
            this.handleStateObject(e, objectName);
        }
        return handleFunc;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem,Msg.of().menu.systemUsers.label);
    }


    render() {
        if (!this.usersListValid) {
            this.loadUsersList();
        }
        const profileState = State.getProfileState(this.props);

        const users = this.state.users;
        const userHeaders = [];
        let userDetails = null;
        if (users) {
            users.forEach((user, index) => {
                if (this.state.activeUserID === user.id) {
                    userHeaders[index] = (
                        <a className="list-group-item list-group-item-action active" style={{backgroundColor: '#49ad5c', borderColor: '#49ad5c'}} key={index} onClick={() => this.changeActiveUser(user.id)}>
                            {user.firstname} {user.lastname}
                        </a>
                    );
                    userDetails = (
                        <>
                            <InfoTable header={Msg.of().common.user} content={user.getInfoObject()}/>
                        </>
                    );
                } else {
                    userHeaders[index] = (
                        <a className="list-group-item list-group-item-action" onClick={() => this.changeActiveUser(user.id)} key={index}>
                            {user.firstname} {user.lastname}
                        </a>
                    );
                }
            })
        }

        if (profileState.isLogged()) {
            return (
                <>
                    <ScrollToTopOnMount/>
                    <TitleBanner text="Pracownicy" small={true}/>
                    <section className="mbr-section article content1 cid-rz87GNwyCo" id="eloelo">
                        <div className="container">
                            <div className="media-container-row">
                                <div className="col-lg-4">
                                    <div className="list-group">
                                        {userHeaders}
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    {userDetails}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        } else {
            return <PageLoginUser/>
        }
    }
}


const PageSystemUsersConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageSystemUsers)

PageSystemUsers.propTypes = {};

export default PageSystemUsersConnectedToRedux;