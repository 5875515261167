import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

ReactDOM.render(<App />, document.getElementById('root'));

if(process.env.NODE_ENV !== 'production') {
    console.log('Tryb: ' + process.env.NODE_ENV);
}

