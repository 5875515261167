import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../store/State";
import Subpage from "../components/website/Subpage";
import ApiEngine from "../logic/ApiEngine";
import Logger from "../logic/Logger";
import Row from "../components/website/Row";
import Col from "../components/website/Col";
import TextParagraph from "../components/website/TextParagraph";

class PageCancelMailing extends Component {

    state = {
        message: 'Wysyłanie zgłoszenia...'
    }

    emailHash;
    profileState;

    cancelMailing = () => {
        ApiEngine.cancelMailing(this.emailHash, response => {
            let message = this.state.message;
            if(response.errors.length > 0) {
                message = "Przepraszamy. Nie udało się przetworzyć żądania automatycznie. Prosimy o przesłanie wiadomości email na adres kontakt@ewaluacja.online. " +
                    "W tytule wiadomości proszę napisać \"REZYGNACJA\", a w treści zamieścić kod " + this.emailHash;
            } else if(response.warnings.length > 0) {
                message = "Mailing został anulowany wcześniej. Wiadomości nie będą wysyłane.";
            } else if(response.oks.length > 0) {
                message = "Zgłoszenie zostało przyjęte. Od tej pory nie będziemy wysyłać do Państwa wiadomości email"
            }
            this.setState({message})
        })
    }

    componentDidMount() {
        this.cancelMailing();
    }

    render() {
        this.profileState = State.getProfileState(this.props);
        this.emailHash = this.props.match && this.props.match.params ? this.props.match.params.hash : undefined;
        return (
            <Subpage title={"Rezygnacja z mailingu"} small={false}>
                <Row>
                    <Col width={12} align="center" style={{minHeight: '15rem'}} paddingTopBottom="10rem">
                        <TextParagraph align="center" style={{color: '#333'}}>
                            {this.state.message}
                        </TextParagraph>
                    </Col>
                </Row>
            </Subpage>
        );
    }
}


const PageCancelMailingConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageCancelMailing)

PageCancelMailing.propTypes = {};

export default PageCancelMailingConnectedToRedux;