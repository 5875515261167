import Action from "./Action";
import LoginAction from "./actions/profile/LoginAction";
import LogoutAction from "./actions/profile/LogoutAction";
import InitialDataLoadedAction from "./actions/profile/InitialDataLoadedAction";
import ShowPreloaderAction from "./actions/profile/ShowPreloaderAction";
import SetActiveMenuItemAction from "./actions/profile/SetActiveMenuItemAction";
import UpdateQuestsItemsCols from "./actions/Survey/UpdateQuestsItemsCols";
import UpdateStats from "./actions/Survey/UpdateStats";

/**
 * @description Klasa służąca do budowania obiektów akcji na podstawie typu i parametrów payload
 */
class ActionBuilder {


    /**
     * @public
     * @static
     * @method
     * @param {!string} type
     * @param {?object} payload
     * @returns {Action}
     */
    static buildAction = (type, payload) => {
        switch (type) {
            case InitialDataLoadedAction.TYPE: return new InitialDataLoadedAction(payload);
            case LoginAction.TYPE: return new LoginAction(payload);
            case LogoutAction.TYPE: return new LogoutAction(payload);
            case ShowPreloaderAction.TYPE: return new ShowPreloaderAction(payload);
            case SetActiveMenuItemAction.TYPE: return new SetActiveMenuItemAction(payload);
            case UpdateQuestsItemsCols.TYPE: return new UpdateQuestsItemsCols(payload);
            case UpdateStats.TYPE: return new UpdateStats(payload);
            default: return new Action(type, payload, () => {});
        }
    }
}

export default ActionBuilder;