class ApiCacheElement {
    /**
     * @public
     * @field
     * @type {object}
     */
    response;


    /**
     * @public
     * @field
     * @type {number}
     */
    createdTime;


    /**
     *
     * @param {object} response
     */
    constructor(response) {
        this.response = response;
        this.createdTime = Date.now();
    }

    /**
     * @public
     * @method
     * @return {boolean}
     */
    isExpired() {
        const secondsExpiration = 60;
        return Date.now() >= this.createdTime + secondsExpiration * 1000;
    }

    /**
     * @public
     * @method
     * @return {boolean}
     */
    isNotExpired() {
        return !this.isExpired();
    }

    /**
     *
     * @param {object} response
     * @param {function} funcSuccess
     * @param {function} funcError
     * @return {ApiCacheElement}
     */
    static get = (response) => {
        return new ApiCacheElement(response);
    }
}

export default ApiCacheElement;