import React from "react";
import Msg from "../i18n/Msg";
import GreenButton from "../components/visual/GreenButton";

class Utils {
    static escapeDiacritics = text => {
        return text.replace(/ą/g, 'a').replace(/Ą/g, 'A')
            .replace(/ć/g, 'c').replace(/Ć/g, 'C')
            .replace(/ę/g, 'e').replace(/Ę/g, 'E')
            .replace(/ł/g, 'l').replace(/Ł/g, 'L')
            .replace(/ń/g, 'n').replace(/Ń/g, 'N')
            .replace(/ó/g, 'o').replace(/Ó/g, 'O')
            .replace(/ś/g, 's').replace(/Ś/g, 'S')
            .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
            .replace(/ź/g, 'z').replace(/Ź/g, 'Z');
    }

    static escapeDiacriticsAndSpaces = text => {
        return Utils.escapeDiacritics(text).replace(/ /g, '');
    }

    static validateNIP = nip => {
        if (typeof nip !== 'string')
            return false;

        nip = nip.replace(/[\ \-]/gi, '');

        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }

        if (sum % 11 === controlNumber) {
            return nip;
        } else {
            return null;
        }
    }

    /**
     *
     * @param email
     * @return {boolean}
     */
    static validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static validatePhoneNumber = (phoneNo) => {
        let re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
        return re.test(phoneNo) && String(phoneNo).length >= 9;
    }

    static validatePostalCode = postalCode => {
        return postalCode.match(/^\d\d-\d\d\d$/);
    }

    static validatePassword = password => {
        const lowerCasePattern = /[a-z]/g;
        const upperCasePattern = /[A-Z]/g;
        const numberPattern = /[0-9]/g;
        if (password && password.length >= 8) {
            if (password.match(lowerCasePattern) && password.match(upperCasePattern) && password.match(numberPattern)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    static scrollToRef = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }

    static scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    /**
     *
     * @param {string} text
     * @param {string} tag
     * @param {object} element
     */
    static replaceTagsWithElements(text, tag, element) {
        if (text.includes(tag)) {
            const arr = text.split(tag);
            const size = arr.length;
            const result = [];
            let currIndex = 0;
            for (let i = 0; i < size; i++) {
                result[currIndex++] = <span key={currIndex}>{arr[i]}</span>;
                if (i < size - 1) {
                    result[currIndex++] = <span key={currIndex}>{element}</span>;
                }
            }
            return result;
        } else {
            return (
                <span>{text}</span>
            );
        }
    }

    //static tokens = ['_BR_', '_REGISTER_'];
    static tokens = {
        '_BR_': <br/>,
        '_REGISTER_': <GreenButton label={Msg.of().menu.register.label} internalLink={Msg.of().menu.register.link}/>,
        '_LOGIN_': <GreenButton label={Msg.of().login.form.button.login} internalLink={Msg.of().menu.panel.link}/>
    }

    static tokenizator(text) {
        const arr = text.split(' ');
        const result = [];
        let currIndex = 0;
        let plainTxt = '';
        arr.forEach((value, index) => {
            const element = Utils.tokens[value];
            if (element) {
                if (plainTxt.length > 0) {
                    result[currIndex++] = <span key={currIndex}>{plainTxt}</span>
                    plainTxt = '';
                }
                result[currIndex++] = <div style={{display: "inline"}} key={currIndex}>{element}</div>;
            } else {
                plainTxt += value + ' ';
            }
        });
        if (plainTxt.length > 0) {
            result[currIndex++] = <span key={currIndex}>{plainTxt}</span>

        }
        return result;
    }

    static replaceBR(text) {
        return this.replaceTagsWithElements(text, '_BR_', <br/>);
    }

    static randomID() {
        return Math.random().toString(36).substr(2, 9);
    }

}

export default Utils;