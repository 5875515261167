import React from 'react';

const TestimonialsItem = props => {
    return (
        <>
            <section className="mbr-section article content1 cid-rz84vSyYsi" id="content1-1v">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <div style={{marginBottom: '5px', fontSize: '16px'}}>
                                <strong>{props.date}</strong>
                            </div>
                            <div>
                                <strong>{props.person}</strong>
                                <br/><small><strong>{props.role}</strong></small>
                            </div>
                            <div style={{textAlign: 'justify', marginTop: '1em'}}>
                                <em>
                                    {props.children}
                                </em>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TestimonialsItem;