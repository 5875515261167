import ValidationMessage from "./ValidationMessage";
import React from 'react';
import Msg from "../i18n/Msg";
import ConfirmMessage from "../components/visual/ConfirmMessage";
import Utils from "./Utils";

class ValidationResult {
    /**
     *
     * @type {ValidationMessage[]}
     */
    errors = [];

    /**
     *
     * @type {ValidationMessage[]}
     */
    oks = [];

    /**
     *
     * @type {ValidationMessage[]}
     */
    warnings = [];

    /**
     * @type {string}
     */
    token;


    status = 100;

    /**
     * @type {Object.<string, *>}
     */
    data;

    static get = () => {
        return new ValidationResult();
    }

    static build(oks, warnings, errors, data, token, status) {
        const result = new ValidationResult();
        result.oks = oks;
        result.warnings = warnings;
        result.errors = errors;
        result.data = data;
        result.token = token;
        result.status = status;
        return result;
    }

    static buildFromResponse(response) {
        let responseObj;
        if (response.responseText && response.responseText.length > 0) {
            responseObj = JSON.parse(response.responseText);
        } else {
            responseObj = response;
        }

        const responseErrors = responseObj.errors;
        const responseOKs = responseObj.oks;
        const responseWarnings = responseObj.warning;

        const status = response.status;
        const token = responseObj.token;
        const data = responseObj.data;

        if(!responseErrors) {
            console.log('RESPONSE NOT RECOGNIZED: ');
            console.log(responseObj);
        }

        const errors = responseErrors ? responseErrors.map(err => {
            let key = 'r';
            if (err.includes(':')) {
                const arr = err.split(':');
                key = arr[0];
                err = arr[1];
            }
            return ValidationMessage.get(key, err)
        }) : [ValidationMessage.get('r', 'Response not recognized')];

        const oks = responseOKs ? responseOKs.map(ok => ValidationMessage.get('r', ok)) : [];
        const warnings = responseWarnings ? responseWarnings.map(wrn => {
            let key = 'r';
            if (wrn.includes(':')) {
                const arr = wrn.split(':');
                key = arr[0];
                wrn = arr[1];
            }
            return ValidationMessage.get(key, wrn);
        }) : [ValidationMessage.get('r', 'Response not recognized')];
        return ValidationResult.build(oks, warnings, errors, data, token, status);
    }

    static buildFromErrorReason(reason) {
        const wrapper = ValidationResult.build(null, null, [`Error ${reason.status}`], null, null, reason.status)
        return wrapper;
    }

    /**
     * @return {boolean}
     */
    isOK = () => {
        return this.errors.length === 0;
    }

    /**
     * @return {boolean}
     */
    isValid() {
        return this.isOK();
    }

    /**
     * @return {boolean}
     */
    isNotValid() {
        return !this.isOK();
    }

    /**
     * @public
     * @method
     * @param key klucz określający pole formularza, którego dotyczy komunikat
     * @param text tekst komunikatu
     * @return {ValidationResult}
     */
    addError(key, text) {
        this.errors = [...this.errors, ValidationMessage.get(key, text)];
        return this;
    }

    /**
     * @public
     * @method
     * @param key klucz określający pole formularza, którego dotyczy komunikat
     * @param text tekst komunikatu
     * @return {ValidationResult}
     */
    addWarning(key, text) {
        this.warnings = [...this.warnings, ValidationMessage.get(key, text)];
        return this;
    }

    /**
     * @public
     * @method
     * @param key klucz określający pole formularza, którego dotyczy komunikat
     * @param text tekst komunikatu
     * @return {ValidationResult}
     */
    addOK(key, text) {
        this.oks = [...this.oks, ValidationMessage.get(key, text)];
        return this;
    }

    /**
     * @public
     * @method
     * @param key klucz określający pole formularza, którego dotyczy komunikat
     * @return {ValidationMessage[]}
     */
    getErrorsForKey(key) {
        return this.errors.filter(error => error.key === key);
    }

    /**
     * @public
     * @method
     * @param key klucz określający pole formularza, którego dotyczy komunikat
     * @return {ValidationMessage[]}
     */
    getWarningsForKey(key) {
        return this.warnings.filter(warning => warning.key === key);
    }

    /**
     * @public
     * @method
     * @param key klucz określający pole formularza, którego dotyczy komunikat
     * @return {ValidationMessage[]}
     */
    getOKsForKey(key) {
        return this.oks.filter(ok => ok.key === key);
    }

    /**
     * @public
     * @method
     * @param {string} [key]
     * @return {*[]}
     */
    getErrorsHTMLForKey(key) {
        const errors = key ? this.getErrorsForKey(key) : this.errors;
        return errors.map((error, index) => {
            return (
                <div className="h6" style={{lineHeight: '1.5rem', color: 'red'}} key={index}>
                    {Utils.tokenizator(error.text)}
                </div>
            );
        });
    }

    /**
     * @public
     * @method
     * @return {*[]}
     */
    getErrorsHTMLForAllKeys() {
        return this.getErrorsHTMLForKey();
    }

    getOksHTMLForKey(key, style) {
        const oks = this.getOKsForKey(key);
        return oks.map((ok, index) => {
            return (
                <ConfirmMessage text={ok.text} key={index} style={style}/>
            );
        });
    }

    /**
     * @public
     * @method
     * @return {ValidationResult}
     */
    clear() {
        this.errors = [];
        this.warnings = [];
        this.oks = [];
        return this;
    }

    /**
     * @public
     * @method
     * @param {ValidationResult} otherResult
     */
    join(otherResult) {
        if (otherResult.oks) {
            this.oks = [...this.oks, ...otherResult.oks];
        }
        if (otherResult.warnings) {
            this.warnings = [...this.warnings, ...otherResult.warnings];
        }
        if (otherResult.errors) {
            this.errors = [...this.errors, ...otherResult.errors];
        }
        return this;
    }

    /**
     * @param {Object.<string,string>} msgObject
     */
    translateMessagesFromApiUsingMsgObject(msgObject) {
        this.errors = ValidationResult.translateValidationMessages(this.errors, msgObject);
        this.oks = ValidationResult.translateValidationMessages(this.oks, msgObject);
        this.warnings = ValidationResult.translateValidationMessages(this.warnings, msgObject);
    }

    /**
     * @param {Object.<string,string>} msgObject
     * @returns {ValidationResult}
     */
    withMessagesFromApiTranslated(msgObject) {
        this.translateMessagesFromApiUsingMsgObject(msgObject);
        return this;
    }


    /**
     * @private
     * @static
     * @param {ValidationMessage[]} messages
     * @param {Object.<string,string>} msgObject
     */
    static translateValidationMessages = (messages, msgObject) => {
        return messages.map(validationMessage => {
            const newValidationMessage = new ValidationMessage();
            newValidationMessage.key = validationMessage.key;
            newValidationMessage.text = msgObject[validationMessage.text];
            return newValidationMessage;
        });
    }

    textListFromCodesArray(codes) {
        return codes.map(
            (value, index, arr) => {
                if (value.text) {
                    return Msg.of().exception[value.text];
                } else {
                    return Msg.of().exception[value]
                }
            }
        ).join("<br/>");
    }

    /**
     * @public
     * @function
     * @description Zwraca listę komunikatów pozytywnego potwierdzenia rozdzieloną znacznikami BR
     * @returns {string}
     */
    oksTextDistractedByBR() {
        return this.textListFromCodesArray(this.oks);
    }

    /**
     * @public
     * @function
     * @description Zwraca listę komunikatów ostrzeżenia rozdzieloną znacznikami BR
     * @returns {string}
     */
    warningsTextDistractedByBR() {
        return this.textListFromCodesArray(this.warnings);
    }

    /**
     * @public
     * @function
     * @description Zwraca listę komunikatów błędów rozdzieloną znacznikami BR
     * @returns {string}
     */
    errorsTextDistractedByBR() {
        return this.textListFromCodesArray(this.errors);
    }
}

export default ValidationResult;