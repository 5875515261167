import ValidationResult from "../logic/ValidationResult";
import Msg from "../i18n/Msg";
import Utils from "../logic/Utils";
import User from "./User";
import Field from "../form/components/Field";
import ApiEngine from "../logic/ApiEngine";
import phoneIcon from "../img/phone_icon.png";
import emailIcon from "../img/mail_icon.png";
import React from 'react';

class School {

    /**
     * @public
     * @static
     * @description Nazwy pól formularza szkoły
     * @type {{secEmail: string, schoolSize: string, nip: string, city: string, street: string, postalCode: string, name: string, houseNumber: string, secPhone: string, voivodeship: string, type: string}}
     */
    static fieldNames = {
        name: 'name',
        type: 'type',
        nip: 'nip',
        street: 'street',
        houseNumber: 'houseNumber',
        city: 'city',
        postalCode: 'postalCode',
        secEmail: 'secEmail',
        secPhone: 'secPhone',
        schoolSize: 'schoolSize',
        voivodeship: 'voivodeship'
    }

    /**
     * @public
     * @field
     * @description ID z bazy danych
     * @type {number}
     */
    id;

    /**
     * @public
     * @field
     * @description Nazwa szkoły
     * @type {string}
     */
    name = '';

    /**
     * @public
     * @field
     * @description Typ szkoły: podstawowa LUB ponadpodstawowa LUB inna
     * @type {string}
     */
    type = '';

    /**
     * @public
     * @field
     * @description NIP szkoły
     * @type {string}
     */
    nip = '';

    /**
     * @public
     * @field
     * @description Ulica w adresie szkoły
     * @type {string}
     */
    street = '';

    /**
     * @public
     * @field
     * @description Numer posesji/budynku w adresie szkoły
     * @type {string}
     */
    houseNumber = '';

    /**
     * @public
     * @field
     * @description Miejscowość w adresie szkoły
     * @type {string}
     */
    city = '';

    /**
     * @public
     * @field
     * @description Kod pocztowy w adresie szkoły
     * @type {string}
     */
    postalCode = '';

    /**
     * @public
     * @field
     * @description Adres email sekretariatu szkoły
     * @type {string}
     */
    secEmail = '';

    /**
     * @public
     * @field
     * @description Numer telefonu do sekretariatu szkoły
     * @type {string}
     */
    secPhone = '';

    /**
     * @public
     * @field
     * @description Rozmiar szkoły, jeden z predefiniowanych
     * @type {string}
     */
    schoolSize = '';

    /**
     * @public
     * @field
     * @description Województwo w adresie szkoły
     * @type {string}
     */
    voivodeship = '';

    /**
     * @public
     * @field
     * @description Użytkownik tworzony przy rejestracji (pierwszy w danej szkole)
     * @type {User}
     */
    registrationUser = null;

    // noinspection DuplicatedCode
    constructor(name, type, nip, street, houseNumber, city, postalCode, secEmail, secPhone, schoolSize, voivodeship, id) {
        this.name = name;
        this.type = type;
        this.nip = nip;
        this.street = street;
        this.houseNumber = houseNumber;
        this.city = city;
        this.postalCode = postalCode;
        this.secEmail = secEmail;
        this.secPhone = secPhone;
        this.schoolSize = schoolSize;
        this.voivodeship = voivodeship;
        this.id = id;
    }

    static get = (name, type, nip, street, houseNumber, city, postalCode, secEmail, secPhone, schoolSize, voivodeship, id) => {
        return new School(name, type, nip, street, houseNumber, city, postalCode, secEmail, secPhone, schoolSize, voivodeship, id);
    }

    static getDefault = () => {
        return School.get('SP1', '1', '9111986285', 'Zielona', '10', 'Wrocław', '55-555', 'szkolaSP3000@szkola.wroclaw.pl',
            '123123123', '1', '1', undefined);
        // return School.get('', '', '', '', '', '', '', '', '', '', '');
    }

    /**
     * @public
     * @method
     * @description ustawia pole obiektu przekazane w evencie i zwraca nowy obiekt szkoły
     * @param e event
     * @return {School} nowy obiekt szkoły
     */
    returnNewFromEvent(e) {
        const fromEvent = Field.readFromEvent(e);
        this[fromEvent.fieldName] = fromEvent.fieldValue;
        const newSchool = new School(this.name, this.type, this.nip, this.street, this.houseNumber, this.city, this.postalCode,
            this.secEmail, this.secPhone, this.schoolSize, this.voivodeship, this.id);
        return newSchool;
    }

    /**
     * @public
     * @method
     * @description Waliduje poprawność formularza
     * @return {ValidationResult}
     */
    validate() {
        const result = ValidationResult.get();
        const names = School.fieldNames;
        if (!this.name || this.name.length < 3) {
            result.addError(names.name, Msg.schoolFormValidationError.name);
        }
        if (!this.type) {
            result.addError(names.type, Msg.schoolFormValidationError.type);
        }
        if (!this.nip || !Utils.validateNIP(this.nip)) {
            result.addError(names.nip, Msg.schoolFormValidationError.nip);
        }
        if (!this.street || this.street.length < 2) {
            result.addError(names.street, Msg.schoolFormValidationError.street);
        }
        if (!this.houseNumber || this.houseNumber.length < 1) {
            result.addError(names.houseNumber, Msg.schoolFormValidationError.houseNumber);
        }
        if (!this.city || this.city.length < 3) {
            result.addError(names.city, Msg.schoolFormValidationError.city);
        }
        if (!this.postalCode || !Utils.validatePostalCode(this.postalCode)) {
            result.addError(names.postalCode, Msg.schoolFormValidationError.postalCode);
        }
        if (!this.schoolSize) {
            result.addError(names.schoolSize, Msg.schoolFormValidationError.schoolSize);
        }
        if (!this.voivodeship || this.voivodeship === '--') {
            result.addError(names.voivodeship, Msg.schoolFormValidationError.voivodeship);
        }
        if (!this.secPhone || this.secPhone.length < 9) {
            result.addError(names.secPhone, Msg.schoolFormValidationError.secPhone);
        }
        if (!this.secEmail || !Utils.validateEmail(this.secEmail)) {
            result.addError(names.secEmail, Msg.schoolFormValidationError.secEmail);
        }
        return result;
    }

    /**
     *
     * @return {{string: string|*}}
     */
    getInfoObject() {
        const school = this;
        return {
            [Msg.schoolNameLabelShort]: school.name,
            [Msg.schoolTypeLabelShort]: ApiEngine.nameOfSchoolType(school.type),
            [Msg.schoolSchoolSizeLabelShort]: ApiEngine.nameOfSchoolSize(school.schoolSize),
            [Msg.schoolAddressLabelShort]: (
                <>
                    {school.street} {school.houseNumber}<br/>
                    {school.postalCode} {school.city}<br/>
                </>
            ),
            [Msg.schoolVoivodeshipLabelShort]: ApiEngine.nameOfVoivodeship(school.voivodeship),
            [Msg.schoolSecretariatShort]: (
                <>
                    <table className="table table-sm table-borderless" style={{marginBottom: 0}}>
                        <tbody>
                        <tr>
                            <th className="align-left" style={{textAlign: 'left'}}><img src={phoneIcon} alt="Tel:"/></th>
                            <td style={{width: '100%'}}>{school.secPhone}</td>
                        </tr>
                        <tr>
                            <th className="align-left" style={{textAlign: 'left'}}><img src={emailIcon} alt="Email:"/></th>
                            <td style={{width: '100%'}}>{school.secEmail}</td>
                        </tr>
                        </tbody>
                    </table>
                </>
            ),
            [Msg.schoolNipLabelShort]: school.nip,
        }
    }

    /*/!**
     * @public
     * @method
     * @param schoolUser Użytkownik tworzony przy rejestracji szkoły (rejestracyjny)
     * @description przygotowanie danych do wysłania formularza
     * @return {{secEmail: string, registrationUser: {activationTime: boolean, firstname: (string|string), password, phone, rodoAccepted, headOfEvaluationTeam: string, login, userRole, email: *, lastname: (string|string)}, city: string, postalCode: string, schoolType: string, houseNumber: string, postalCity: string, schoolSize: string, nip: string, street: string, name: string, secPhone: string, voivodeship: string}}
     *!/
    toSend(schoolUser) {
        const result = {
            "name": this.name,
            "schoolType": this.type,
            "nip": this.nip,
            "street": this.street,
            "houseNumber": this.houseNumber,
            "postalCode": this.postalCode,
            "postalCity": '',
            "city": this.city,
            "secEmail": this.secEmail,
            "secPhone": this.secPhone,
            "schoolSize": this.schoolSize,
            "voivodeship": this.voivodeship,
            "registrationUser": {
                "firstname": schoolUser.firstname,
                "lastname": schoolUser.lastname,
                "email": schoolUser.email,
                "phone": schoolUser.phone,
                "rodoAccepted": schoolUser.rodoAccepted,
                "login": schoolUser.login,
                "password": schoolUser.password,
                "headOfEvaluationTeam": "true",
                "activationTime": false,
                "userRole": schoolUser.userRole
            }
        }
        return result;
    }*/
}

export default School;