class ProfileState {
    /**
     * @public
     * @type {!boolean}
     * @description flaga określająca, czy API_ENGINE jest gotowe do użycia, tzn czy zostało zakończone pobieranie danych wstępnych, np. słowników
     */
    initialDataLoaded;

    /**
     * @public
     * @type {?string}
     * @description token autoryzacyjny JWS
     */
    token;

    /**
     * @public
     * @type {!boolean}
     * @description flaga określająca, czy użytkownik jest zalogowany czy nie
     */
    authenticated;

    /**
     * @public
     * @type {boolean}
     * @description flaga określająca, czy zamiast właściwej zawartości strony powinien być wyświetlony preloader na środku ekranu
     */
    showPreloader = false;

    /**
     * @public
     * @type {SchoolUser}
     * @description aktualnie zalogowany użytkownik
     */
    schoolUser;

    /**
     * @public
     * @type {EduInstitution}
     * @description szkoła zalogowanego użytkownika
     */
    eduInstitution;

    /**
     * @public
     * @type {string}
     * @description przechowuje informację o tym, jaka pozycja menu jest w danym momencie aktywna
     */
    activeMenuItem;

    /**
     * @public
     * @type {boolean}
     * @description flaga określająca, czy zalogowany user jest adminem
     */
    admin = false;

    isLogged() {
        return this.token && this.authenticated;
    }

    isAdmin() {
        return this.admin
    }

    preloaderDemand() {
        return this.showPreloader || !this.initialDataLoaded;
    }

    isAdminLogged() {
        return this.isAdmin() && this.isLogged();
    }

    isNoNAdminLogged() {
        return !this.isAdmin() && this.isLogged();
    }


    constructor(initialDataLoaded, token, authenticated, schoolUser, eduInstitution, activeMenuItem, admin = false) {
        this.initialDataLoaded = initialDataLoaded;
        this.token = token;
        this.authenticated = authenticated;
        this.schoolUser = schoolUser;
        this.eduInstitution = eduInstitution;
        this.activeMenuItem = activeMenuItem;
        this.admin = admin
    }

    /**
     * @public
     * @static
     * @method
     * @description Zwraca stan początkowy, czyli
     *  {
     *      initialDataLoaded : false,
     *      token : null,
     *      authenticated : false,
     *      schoolUser: null,
     *      eduInstitution: null,
     *      activeMenuItem: ''
     *  }
     * @return {ProfileState}
     */
    static initial = () => {
        return new ProfileState(false, null, false, null, null, '', false);
    }

    /**
     * @public
     * @static
     * @method
     * @description Zwraca stan zgodny z zadanymi parametrami
     * @param initialDataLoaded flaga określająca czy API_ENGINE jest gotowy do użycia, tzn czy zostało zakończone pobieranie danych wstępnych taki jak np. słowniki
     * @param token token autoryzacyjny
     * @param authenticated flaga określająca, czy użytkownik jest zalogowany do systemu
     * @param {SchoolUser} schoolUser zalogowany użytkownik
     * @param {EduInstitution} eduInstitution szkoła zalogowanego użytkownika
     * @param {string} activeMenuItem aktywna pozycja menu
     * @param {boolean} admin
     * @return {ProfileState}
     */
    static get = (initialDataLoaded, token, authenticated, schoolUser, eduInstitution, activeMenuItem, admin) => {
        return new ProfileState(initialDataLoaded, token, authenticated, schoolUser, eduInstitution, activeMenuItem, admin)
    }
}

export default ProfileState;