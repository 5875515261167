import ApiEngine from "./ApiEngine";
import Current from "../profiles/Current";

class Logger {

    static debugEnabled = Current.PROFILE.logDebug;

    static isObject = sth => {
        return sth && typeof sth === 'object'
    }

    static info(sth) {
        if(Logger.isObject(sth)) {
            Logger.infoObject(sth)
        } else {
            if (ApiEngine.isDevMode()) {
                console.log('[INFO]' + sth)
            }
        }
    }

    static infoObject(sth) {
        if (ApiEngine.isDevMode()) {
            console.log('[INFO]:')
            console.log(sth)
        }
    }

    static debug(sth) {
        if(Logger.isObject(sth)) {
            Logger.debugObject(sth);
        } else {
            if (ApiEngine.isDevMode() && Logger.debugEnabled) {
                console.log('[DEBUG]' + sth);
            }
        }
    }

    static debugObject(sth) {
        if (ApiEngine.isDevMode() && Logger.debugEnabled) {
            console.log('[DEBUG]:');
            console.log(sth);
        }
    }

    static error(sth) {
        if(Logger.isObject(sth)) {
            Logger.errorObject(sth);
        } else {
            console.log('[ERROR]' + sth);
        }
    }

    static errorObject(sth) {
        console.log('[ERROR]:');
        console.log(sth);
    }


}

export default Logger;