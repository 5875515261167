class StatisticData {

    /**
     * @public
     * @field
     * @type {number}
     */
    questID;

    /**
     * @public
     * @field
     * @type {number}
     */
    questRecord;

    /**
     * @public
     * @field
     * @type {number}
     */
    questOrderNr;

    /**
     * @public
     * @field
     * @type {number}
     */
    colID;

    /**
     * @public
     * @field
     * @type {number}
     */
    colOrderNr;

    /**
     * @public
     * @field
     * @type {number}
     */
    itemID;

    /**
     * @public
     * @field
     * @type {number}
     */
    itemRecord;

    /**
     * @public
     * @field
     * @type {number}
     */
    itemOrderNr;

    /**
     * @public
     * @field
     * @type {number}
     */
    count;


    constructor(questID, questRecord, questOrderNr, colID, colOrderNr, itemID, itemRecord, itemOrderNr, count) {
        this.questID = questID;
        this.questRecord = questRecord;
        this.questOrderNr = questOrderNr;
        this.colID = colID;
        this.colOrderNr = colOrderNr;
        this.itemID = itemID;
        this.itemRecord = itemRecord;
        this.itemOrderNr = itemOrderNr;
        this.count = count;
    }


    /**
     * @public
     * @static
     * @method
     * @param {number} questID
     * @param {number} questRecord
     * @param {number} questOrderNr
     * @param {number} colID
     * @param {number} colOrderNr
     * @param {number} itemID
     * @param {number} itemRecord
     * @param {number} itemOrderNr
     * @param {number} count
     * @return {StatisticData}
     */
    static get = (questID, questRecord, questOrderNr, colID, colOrderNr, itemID, itemRecord, itemOrderNr, count) => {
        return new StatisticData(questID, questRecord, questOrderNr, colID, colOrderNr, itemID, itemRecord, itemOrderNr, count)
    }


    /**
     * @public
     * @static
     * @method
     * @param {object} obj
     * @return {StatisticData}
     */
    static fromObject = obj => {
        const {questID, questRecord, questOrderNr, colID, colOrderNr, itemID, itemRecord, itemOrderNr, count} = obj;
        return new StatisticData(questID, questRecord, questOrderNr, colID, colOrderNr, itemID, itemRecord, itemOrderNr, count)
    }
}

export default StatisticData