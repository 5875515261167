class Survey {


    static fieldNames = {
        surID: 'surID',
        srwID: 'srwID',
        surName: 'surName',
        srwNumber: 'srwNumber',
        rdbID: 'rdbID',
        testLink: 'testLink'
    }


    /**
     * @public
     * @field
     * @description identyfikator badania
     * @type {number}
     */
    surID;

    /**
     * @public
     * @field
     * @description identyfikator fali
     * @type {number}
     */
    srwID;

    /**
     * @public
     * @field
     * @description nazwa badania
     * @type {string}
     */
    surName;

    /**
     * @public
     * @field
     * @description numer fali
     * @type {number}
     */
    srwNumber;

    /**
     * @public
     * @field
     * @description identyfikator bazy respodnentów
     * @type {number}
     */
    rdbID;

    /**
     * @public
     * @field
     * @description link do testowania kwestionariusza
     * @type {string}
     */
    testLink;

    /**
     * @public
     * @field
     * @description
     * @type {SurveyParams}
     */
    params;


    constructor(surID, srwID, surName, srwNumber, rdbID, testLink, params) {
        this.surID = surID;
        this.srwID = srwID;
        this.surName = surName;
        this.srwNumber = srwNumber;
        this.rdbID = rdbID;
        this.testLink = testLink;
        this.params = params
    }

    static get(surID, srwID, surName, srwNumber, rdbID, testLink, params) {
        return new Survey(surID, srwID, surName, srwNumber, rdbID, testLink, params);
    }

    /**
     *
     * @param {Survey} anotherSurvey
     */
    equals(anotherSurvey) {
        if (this.surID === anotherSurvey.surID && this.surName === anotherSurvey.surName && this.srwID === anotherSurvey.srwID
            && this.testLink === anotherSurvey.testLink && this.srwNumber === anotherSurvey.srwNumber && this.rdbID === anotherSurvey.rdbID) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @public
     * @method
     * @return {boolean}
     */
    isRun() {
        return !!(this.params && this.params.id);
    }
}

export default Survey;