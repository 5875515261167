import {Link, withRouter} from "react-router-dom";
import React, {Component} from 'react';
import Msg from "../../i18n/Msg";
import State from "../../store/State";
import {connect} from 'react-redux';
import LogoutAction from "../../store/actions/profile/LogoutAction";
import MenuItem from "./MenuItem";
import ShowPreloaderAction from "../../store/actions/profile/ShowPreloaderAction";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import Flag from "../visual/Flag";
import Current from "../../profiles/Current";


class Menu extends Component {

    state = {}

    /**
     *
     * @param {ProfileState} profileState
     */
    logoutFunction = (profileState) => {
        if (profileState.isAdmin()) {
            this.props.history.push(Msg.of().menu.admin.link);
        } else {
            this.props.history.push(Msg.of().menu.panel.link);
        }
        ShowPreloaderAction.run(this.props.dispatch, true);
        LogoutAction.run(this.props.dispatch, () => {
        }, () => {
        });
    }



    onChangeMenuFuncGenerator = activeMenuItem => {
        return () => {
            SetActiveMenuItemAction.run(this.props.dispatch, State.getProfileState(this.props).activeMenuItem, activeMenuItem);
        }
    }

    render() {
        /**
         * @type {ProfileState}
         */
        const profileState = State.getProfileState(this.props);

        console.log('ActiveMenuItem: ' + profileState.activeMenuItem);

        const {
            aboutUs, news, offer, infoGraphic, usefulLinks,
            testimonials, priceList, contact, panel, logout,
            schoolProfile, systemUsers, schoolClasses, surveys, surveyWaves, statistics,
            admin, adminHome, adminMailing
        } = Msg.of().menu;

        let menuContent;
        if (profileState.isNoNAdminLogged()) {
            menuContent = (
                <>
                    <MenuItem label={schoolProfile.label} to={schoolProfile.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(schoolProfile.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    {/*<MenuItem label={systemUsers.label} to={systemUsers.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(systemUsers.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem label={schoolClasses.label} to={schoolClasses.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(schoolClasses.label)}
                              activeMenuItem={profileState.activeMenuItem}/>*/}
                    <MenuItem label={surveys.label} to={surveys.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(surveys.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    {/*<MenuItem label={surveyWaves.label} to={surveyWaves.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(surveyWaves.label)}
                              activeMenuItem={profileState.activeMenuItem}/>*/}
                   {/* <MenuItem label={statistics.label} to={statistics.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(statistics.label)}
                              activeMenuItem={profileState.activeMenuItem}/>*/}
                    <MenuItem label={logout.label} onClickFunc={() => this.logoutFunction(profileState)} button={true}
                              onChangeMenuFunc={this.onChangeMenuFuncGenerator(panel.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                </>
            );
        } else if(profileState.isAdminLogged()) {
            menuContent = (
                <>
                    <MenuItem label={adminHome.label} to={adminHome.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(adminHome.label)}
                              activeMenuItem={profileState.activeMenuItem}/>

                    <MenuItem label={adminMailing.label} to={adminMailing.link} onChangeMenuFunc={this.onChangeMenuFuncGenerator(adminMailing.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem label={logout.label} onClickFunc={() => this.logoutFunction(profileState)} button={true}
                              onChangeMenuFunc={this.onChangeMenuFuncGenerator(admin.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                </>
            );
        } else {
            menuContent = (
                <>
                    <MenuItem to={aboutUs.link} label={aboutUs.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(aboutUs.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={news.link} label={news.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(news.label)} activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={offer.link} label={offer.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(offer.label)} activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={infoGraphic.link} label={infoGraphic.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(infoGraphic.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={usefulLinks.link} label={usefulLinks.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(usefulLinks.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={testimonials.link} label={testimonials.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(testimonials.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={priceList.link} label={priceList.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(priceList.label)}
                              activeMenuItem={profileState.activeMenuItem}/>
                    <MenuItem to={contact.link} label={contact.label} onChangeMenuFunc={this.onChangeMenuFuncGenerator(contact.label)}
                              activeMenuItem={profileState.activeMenuItem}/>

                    {
                        Current.PROFILE.panelEnabled &&
                        (
                            <MenuItem to={panel.link} label={panel.label} button={true} onChangeMenuFunc={this.onChangeMenuFuncGenerator(panel.label)}
                                      activeMenuItem={profileState.activeMenuItem}/>
                        )
                    }
                </>
            );
        }


        return (
            <>
                <section className="menu cid-rz6PjvQsBe dupsko" id="menu2-19"> {/*cid-rz6PjvQsBe*/}
                    <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm"
                         style={{backgroundColor: 'white', paddingTop: '1rem'}}>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <div className="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <div className="menu-logo">
                            <div className="navbar-brand">
                            <span className="navbar-logo">
                                <Link to="/" onClick={this.onChangeMenuFuncGenerator('')}>
                                    <img src="/assets/images/ewaluacja-online.svg" alt="" title="Ewaluacja Online" style={{height: '3.8rem'}}/>
                                </Link>
                            </span>
                            </div>
                        </div>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                                {/*<li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                        <span className="flag-icon flag-icon-pl" style={{lineHeight: '1rem', marginRight: '0.3rem'}}/>
                                        <span className="text-black display-4" style={{fontSize: '0.8rem', fontWeight: 500}}>PL</span>
                                    </a>
                                    <div className="dropdown-menu" id="languages">
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"gb"} text='EN'/>
                                        </a>
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"us"}/>
                                        </a>
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"cz"}/>
                                        </a>
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"sk"}/>
                                        </a>
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"de"}/>
                                        </a>
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"fr"}/>
                                        </a>
                                        <a className="dropdown-item langFlag">
                                            <Flag of={"es"}/>
                                        </a>
                                    </div>
                                </li>*/}
                                {menuContent}
                            </ul>
                        </div>


                    </nav>
                </section>

                {/*<div className="container">
                    <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm"
                         ref={(el) => {
                             if (el) {
                                 return el.style.setProperty("background-color", "#2e2e2e", "important")
                             } else {
                                 return null;
                             }
                         }}>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarsExample09">
                            <ul className="navbar-nav mr-auto" style={{margin: "auto"}}>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="" id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                       style={{paddingTop: 0, paddingBottom: 0}}>
                                        <div style={{display: "inline-block", height: "1rem", position: "relative", paddingTop: "0.2rem"}}>
                                            <span className="flag-icon flag-icon-pl" style={{width: "0.7rem", lineHeight: "0.7rem", float: "left"}}/>
                                            <span style={{
                                                color: "white",
                                                fontSize: "0.7rem",
                                                lineHeight: "0.7rem",
                                                marginTop: "0",
                                                display: "inline-block",
                                                float: "left",
                                                paddingLeft: '0.2rem'
                                            }}>
                                        PL
                                        </span>
                                        </div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                                        <a className="dropdown-item">
                                            <div style={{display: "block", height: "1.2rem", position: "relative", paddingTop: "0.2rem"}}>
                                                <span className="flag-icon flag-icon-gb" style={{width: "1rem", lineHeight: "1rem", float: "left"}}/>
                                                <span style={{fontSize: "1rem", lineHeight: "1rem", marginTop: "0", display: "inline-block", float: "left", paddingLeft: '0.5rem'}}>
                                            english
                                            </span>
                                            </div>
                                        </a>
                                        <a className="dropdown-item">
                                            <div style={{display: "block", height: "1.2rem", position: "relative", paddingTop: "0.2rem"}}>
                                                <span className="flag-icon flag-icon-cz" style={{width: "1rem", lineHeight: "1rem", float: "left"}}/>
                                                <span style={{fontSize: "1rem", lineHeight: "1rem", marginTop: "0", display: "inline-block", float: "left", paddingLeft: '0.5rem'}}>
                                            český
                                            </span>
                                            </div>
                                        </a>
                                        <a className="dropdown-item">
                                            <div style={{display: "block", height: "1.2rem", position: "relative", paddingTop: "0.2rem"}}>
                                                <span className="flag-icon flag-icon-sk" style={{width: "1rem", lineHeight: "1rem", float: "left"}}/>
                                                <span style={{fontSize: "1rem", lineHeight: "1rem", marginTop: "0", display: "inline-block", float: "left", paddingLeft: '0.5rem'}}>
                                            slovenský
                                            </span>
                                            </div>
                                        </a>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>*/}
            </>
        );
    };
};

Menu.propTypes = {}

Menu.defaultProps = {}

const menuConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(Menu)

export default withRouter(menuConnectedToRedux);

