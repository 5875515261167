import React from 'react';
import {NavLink} from "react-router-dom";
import fbLogo from '../../img/facebook-icon.png';

const ContactBanner = () => (
    <section className="cid-rz83UYYlPe" id="footer1-1q">


        <div className="container">
            <div className="media-container-row content text-white">
                <div className="col-12 col-md-4" style={{textAlign: 'center'}}>

                    <div className="media-wrap">
                        <a href="/">
                            <img src="/assets/images/ewaluacja-online-footer.svg" alt="Mobirise" title=""/>
                        </a>
                    </div>
                    <div className="media-wrap" style={{marginTop: '2em', width: '100%', textAlign: 'center'}}>
                        <div style={{width: 210, margin: 'auto'}}>
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td style={{}}>
                                        <div style={{border: '0px solid red', display: 'block', marginTop: 0, paddingTop: 0, width: 28, height: 28, marginRight: 2}}>
                                            <a target="_blank" href="https://www.facebook.com/EwaluacjaOnline-115482263152070" style={{width: 28, height: 28, display: 'block'}}>
                                                <img src={fbLogo} style={{height: 28, width: 28, display: 'block'}}/>
                                            </a>
                                        </div>
                                    </td>
                                    <td style={{}}>
                                        <div style={{border: '0px solid red', display: 'block'}}
                                             className="fb-like" data-href="https://www.facebook.com/EwaluacjaOnline-115482263152070" data-width="" data-layout="button"
                                             data-action="like" data-size="large" data-show-faces="false" data-share="true">
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
                <div className="col-12 col-md-4 mbr-fonts-style display-7" style={{textAlign: 'center'}}>
                    <div style={{textAlign: 'left', width: 230, margin: 'auto'}}>
                        <h5 className="pb-3" style={{textAlign: 'left'}}>i-Pi Sp. z o.o.</h5>
                        <p className="mbr-text">
                            ul. Norwida 10
                            <br/>56-416 Twardogóra
                            <br/>NIP: 911-198-62-85
                            <br/>REGON: 021322055
                            <br/>KRS: 0000362089
                            <br/>
                        </p>
                    </div>
                </div>
                {/*<div className="col-12 col-md-3 mbr-fonts-style display-7" style={{textAlign: 'center'}}>
                    <div style={{textAlign: 'left', width: 230, margin: 'auto'}}>
                        <h5 className="pb-3" style={{textAlign: 'left'}}>KB Pretendent Sp. z o.o.</h5>
                        <p className="mbr-text">
                            ul. Elizy Orzeszkowej 40/9
                            <br/>50-311 Wrocław
                            <br/>NIP: 612-177-16-33
                            <br/>REGON: 020261720
                            <br/>KRS: 0000251665
                            <br/>
                        </p>
                    </div>
                </div>*/}
                <div className="col-12 col-md-4 mbr-fonts-style display-7" style={{textAlign: 'center'}}>
                    <div style={{textAlign: 'left', width: 230, margin: 'auto'}}>
                        <h5 className="pb-3" style={{textAlign: 'left'}}>Dane kontaktowe</h5>
                        <p className="mbr-text">
                            {/*tel.: <a href="tel:+48 71 75 75 333">+48 71 75 75 333</a><br/>*/}
                            tel.: <a href="tel:+48 783 314 404">+48 783 314 404</a>
                            <br/>mail: <a href="mailto:info@surneo.pl">info@surneo.pl</a>
                            <br/>web: <a href="/">ewaluacja.online</a>
                            <br/>
                        </p>
                    </div>
                </div>
            </div>

        </div>


    </section>

);

export default ContactBanner;