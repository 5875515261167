import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import TitleBanner from "../../components/website/TitleBanner";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../../i18n/Msg";
import BarChart from "../../components/charts/BarChart";
import LineChart from "../../components/charts/LineChart";
import { Preview, print } from 'react-html2pdf';
import {jsPDF} from 'jspdf';
import BarChartClass from "../../components/charts/BarChartClass";

class PageSurveyWaves extends Component {

    /**
     * @type {BarChartClass}
     */
    barChart;
    barChartImage;
    barChartRef;

    constructor(props, context) {
        super(props, context);
        this.barChartRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem,Msg.of().menu.surveyWaves.label);
        console.log(this.barChartRef);
    }


    componentDidMount() {
        /*this.barChart = new BarChartClass(this.getRandomArray(4), 'Wykresik', '#00ff00', this.barChartRef);
        this.barChartImage = this.barChart.constructChart();*/
        console.log(this.barChartRef);
    }

    /**
     *
     * @param numItems
     * @return {[{label: string, value: number}]}
     */
    getRandomArray(numItems) {
        let names = '1234ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let data = [];
        for(let i = 0; i < numItems; i++) {
            data.push({
                label: names[i],
                value: Math.round(20 + 80 * Math.random())
            });
        }
        return data;
    }

    toDataURL(canvas) {
        return canvas.toDataURL('image/png');
    }

    savePdf = () => {
        const doc = new jsPDF();

        doc.text("Hello world!", 10, 10);
        doc.addImage(this.toDataURL(this.barChartRef.current.canvasRef.current), 'image/png', 0, 100, 100, 100);
        doc.save("a4.pdf");

    }

    createBarChart = () => {
        const barChart = new BarChart({
            data: this.getRandomArray(5),
            title: 'Programmatically made chart',
            color: '#ff0207'
        });
        return barChart;
    }

    render() {
        const profileState = State.getProfileState(this.props);
        if (profileState.isLogged()) {
            return (
                <>
                    <TitleBanner text="Realizacja ankiet" small={true}/>
                   {/* <Preview id={'jsx-template'} >
                        <p>adsf</p>
                        <BarChart data={this.getRandomArray(4)} title="Wykres" color="#3E517A"/>
                        <LineChart data={this.getRandomArray(4)} title="Wykres" color="#3E517A"/>
                    </Preview>
                    <button onClick={()=>print('a', 'jsx-template')}> print</button>*/}
                    <BarChart data={this.getRandomArray(4)} title="Wykres" color="#3E517A" ref={this.barChartRef}/>
                    <LineChart data={this.getRandomArray(4)} title="Wykres" color="#3E517A"/>
                    {/*<div style={{width: '500px', height: '500px'}}>
                        <canvas ref={this.barChartRef} />
                    </div>*/}
                    <button onClick={this.savePdf}>JSPDF</button>
                </>
            );
        } else {
            return <PageLoginUser/>
        }
    }
}


const PageSurveyWavesConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageSurveyWaves)

PageSurveyWaves.propTypes = {};

export default PageSurveyWavesConnectedToRedux;