import React, {Component} from 'react';
import {connect} from "react-redux";
import State from "../store/State";
import SetActiveMenuItemAction from "../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../i18n/Msg";
import Button from "../components/visual/Button";
import Row from "../components/website/Row";
import Col from "../components/website/Col";
import greenBG from "../img/greenbg.jpeg";
import homeschoolingBanner from "../img/slide3_edukacja_w_cieniu_wirusa_covid_sars_pandemia_szkola.JPG";
import $ from 'jquery';
import LandingPage from "../components/website/LandingPage";
import BigHeader from "../components/visual/BigHeader";
import TextParagraph from "../components/website/TextParagraph";
import Text from "../components/website/Text";
import Utils from "../logic/Utils";
import Logger from "../logic/Logger";
import ApiEngine from "../logic/ApiEngine";

class PageInterest extends Component {

    state = {
        email: '',
        errorMessage: null,
        phase: 0 // 0 - przed wysłanie, 1 - w trakcie wysyłania formularza, 2 - po wysłaniu (komunikat zwrotny)
    }

    signUpFormRef = React.createRef();
    schoolHash;
    surveyName = 'Realizacja podstawy programowej w czasie pandemii'
    schoolName;
    schoolRSPO;
    alreadyRegistered;


    componentDidMount() {
        ApiEngine.getSchoolNameByHash(this.schoolHash, data => {
            this.schoolName = data.name;
            this.schoolRSPO = data.rspo;
            this.alreadyRegistered = data.registered;
            if (this.alreadyRegistered === true) {
                this.setState({
                    phase: 3
                })
            }
        }, error => {
            Logger.error(error);
        })
        this.popovers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.popovers();
    }


    handleUpperButton = () => {
        Utils.scrollToRef(this.signUpFormRef);
    }

    handleEmailEdit = e => {
        this.setState({
            email: e.target.value
        })
    }

    handleSubmit = () => {
        if (this.state.email) {
            const emailValid = Utils.validateEmail(this.state.email);
            if (emailValid) {
                this.setState({
                    errorMessage: null,
                    phase: 1
                });
                ApiEngine.joinSchoolHomeOfficeSurvey(this.schoolHash, this.schoolRSPO, this.state.email,
                    () => {
                        this.setState({phase: 2});
                        this.alreadyRegistered = true;
                    }, /**@param {ValidationResult} validationResult */validationResult => {
                        const errors = validationResult.getErrorsForKey('r');
                        const errorsMessagesToDisplay = [];
                        errors.map(error => {
                            if (error.text === "email repeated") {
                                errorsMessagesToDisplay.push('Ten adres email jest już w użyciu. Proszę wpisać inny')
                            } else if (error.text === 'no email') {
                                errorsMessagesToDisplay.push('Nie podano adresu email');
                            } else if (error.text === 'wrong email') {
                                errorsMessagesToDisplay.push('Nieprawidłowy adres email');
                            } else if (error.text === 'email problem') {
                                errorsMessagesToDisplay.push('Nie udało się wysłać wiadomości z hasłem');
                            } else if (error.text === 'internal error') {
                                errorsMessagesToDisplay.push('Przepraszamy, mamy chwilowe problemy z serwerami');
                            } else if (error.text === 'HASH not found') {
                                errorsMessagesToDisplay.push('Nie odnaleziono szkoły po kodzie hash');
                            } else if (error.text === 'RSPO not found') {
                                errorsMessagesToDisplay.push('Nie odnaleziono szkoły po numerze RSPO');
                            } else {
                                errorsMessagesToDisplay.push(error.text);
                            }

                        });
                        const errorsHTML = errorsMessagesToDisplay.map(errorText => {
                            return (
                                <Row key={errorText}>
                                    <Col width={12} align="center">
                                        <Text color="#f97545" fontWeight="bold">{errorText}</Text>
                                    </Col>
                                </Row>
                            )
                        });
                        this.setState({
                            phase: 0,
                            errorMessage: errorsHTML
                        });

                    })
            } else {
                this.setState({errorMessage: 'Proszę wpisać prawidłowy adres email'});
            }
        } else {
            this.setState({errorMessage: 'Proszę wpisać adres email'});
        }
    }

    popovers = () => {
        $('#rodoPopover').popover({
            html: true,
            container: 'body',
            trigger: 'focus',
            placement: 'auto',
            title: 'Klauzula informacyjna o przetwarzaniu danych',
            content:
                '<p>Informujemy, że dane zgromadzone podczas przeprowadzania badania, których źródłem będą ankiety wypełniane przez uczniów, ' +
                'zostaną wykorzystane do następujących celów:</p>\n' +
                '\n' +
                '<ul>\n' +
                '\n' +
                '<li>Do przygotowania dedykowanego dla Państwa szkoły raportu końcowego z badania, ' +
                'dotyczącego wyników zebranych w tej placówce. Dostęp do tego raportu uzyska na wyłączność Państwa szkoła i stanie się ' +
                'jedynym jego właścicielem. Raport ten zostanie przez nas udostępniony bezpłatnie</li>\n' +
                '\n' +
                '<li>Do przygotowania raportu zbiorczego na szczeblu krajowym oraz wojewódzkim, ' +
                'zawierającego połączone dane ze szkół na danym obszarze, z zachowaniem anonimowości poszczególnych szkół. ' +
                'Właścicielem danych zawartych w tym raporcie zostanie firma i-Pi sp. z o.o. z zastrzeżeniem prawa do dowolnego dysponowania tymi danymi, ' +
                'w tym na przykład do publikacji w prasie bądź w Internecie</li>'

        }).on('show.bs.popover', function () {
            $($(this).data("bs.popover").getTipElement()).css("max-width", "700px");
        });

        $('#emailDemandCause').popover({
            html: true,
            container: 'body',
            trigger: 'focus',
            placement: 'auto',
            boundary: 'viewport',
            title: 'Dlaczego potrzebny jest adres email?',
            content:
                '<p>Jak wykorzystamy podany adres email?</p>' +
                '<ul>' +
                '<li>Wygenerujemy automatycznie trudne do złamania hasło dostępowe do platformy Ewaluacja.Online i prześlemy je na ten adres w ciągu tygodnia od momentu' +
                ' zgłoszenia</li>' +
                '<li>Adres ten będzie jednocześnie nazwą użytkownika (loginem), potrzebnym do zalogowania się do platformy</li>' +
                '</ul>' +
                '<p>Czego na pewno z tym adresem nie zrobimy?</p>' +
                '<ul>' +
                '<li>Nie będziemy przysyłać reklam ani spamu</li>' +
                '<li>Nie udostępnimy tego adresu podmiotom trzecim, niezwiązanym z obsługą portalu Ewaluacja.Online</li>' +
                '<li>Nie wykorzystamy tego adresu w żadnym celu niezwiązanym z działaniem i obsługą platformy Ewaluacja.Online</li>' +
                '</ul>' +
                '<p>Czy nie dałoby się korzystać z platformy Ewaluacja.Online bez podawania adresu email?</p>' +
                '<ul>' +
                '<li>Oferujemy profesjonalne ankiety do przeprowadzania ewaluacji w placówkach oświatowych, możliwość przeprowadzania badań, przeglądania i pobierania' +
                ' wyników tych badań, oraz dostęp do raportu końcowego. Są to dane, które chronimy przed niepowołanym dostępem. Dlatego muszą być zabezpieczone' +
                ' hasłem. Hasło generowane jest automatycznie i przesyłane na podany adres email. Adres ten jest też loginem do systemu.</li>' +
                '</ul>'

        }).on('show.bs.popover', function () {
            $($(this).data("bs.popover").getTipElement()).css("max-width", "700px");
        });
    }


    render() {
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem, Msg.of().menu.contact.label);
        this.schoolHash = this.props.match && this.props.match.params ? this.props.match.params.hash : undefined;


        let phaseDependantContent = null;

        switch (this.state.phase) {
            case 1:
                phaseDependantContent = (
                    <Col width={12}>
                        <Row>
                            <Col width={6} paddingTopBottom="2rem">
                                <div className="progress" style={{height: '1.5rem'}}>
                                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                                         role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                         style={{width: '100%'}}>
                                        <Text color="white" fontSize="0.8rem">
                                            Trwa wysyłanie. Proszę czekać
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                );
                break;
            case 2:
                phaseDependantContent = (
                    <Col width={12}>
                        <Row>
                            <Col width={6} align="center" paddingTopBottom="2rem">
                                <Text color="white">Dziękujemy. Zgłoszenie zostało przyjęte</Text>
                            </Col>
                        </Row>
                    </Col>
                );
                break;
            case 3:
                phaseDependantContent = (
                    <Col width={12}>
                        <Row>
                            <Col width={6} align="center" paddingTopBottom="2rem">
                                <Text color="white">Dziękujemy. Dostęp został przyznany. Hasło przyślemy w wiadomości email w dniu uruchomienia badania.</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={6} align="center">
                                <Button color="danger" size={4} internalLinkTo={Msg.of().menu.panel.link}><strong>Strona logowania</strong></Button>
                            </Col>
                        </Row>
                    </Col>
                );
                break;
            case 0:
            default:
                phaseDependantContent = (
                    <>
                        {/*<Col width={12} align="center" paddingTop="1.2rem">
                            <Row>
                                <Col width={6} align="center" paddingTop="1.2rem">
                                    <Text style={{color: '#fff', fontWeight: 'normal', fontSize: '0.7rem', fontFamily: 'Poppins, sans-serif'}}>
                                        Informujemy, że Przystąpienie do badania nie wiąże się z żadnym zobowiązaniem.
                                        <br/>Przerwanie udziału w badaniu możliwe jest na każdym jego etapie.<br/>Dyrekcja będzie mogła zapoznać się z ankietą przed udostępnieniem jej uczniom
                                    </Text>
                                </Col>
                            </Row>
                        </Col>*/}
                        <Col align="center" width={6} paddingTop="2rem" paddingBottom="2rem">
                            <div className="form-group">
                                <input type="email" name="email" placeholder="Adres email" className="form-control" onChange={this.handleEmailEdit} value={this.state.email}/>
                            </div>
                        </Col>
                        <Col width={12}>
                            <Row>
                                <Col width={6} align="center">
                                    <a tabIndex="0" id="emailDemandCause" className="" data-toggle="popover"
                                       style={{color: '#c0e2ff', fontWeight: 'normal', fontSize: '1.15rem', fontFamily: 'Poppins, sans-serif'}}>
                                        Po co nam adres email?
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col width={6} align="center">
                                    <a tabIndex="0" id="rodoPopover" className="" data-toggle="popover"
                                       style={{color: '#c0e2ff', fontWeight: 'normal', fontSize: '1.15rem', fontFamily: 'Poppins, sans-serif'}}>
                                        Jak przetwarzamy dane?
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col width={6} align="center" paddingTopBottom="2rem">
                                    {this.state.errorMessage && (
                                        <Row>
                                            <Col width={12} align="center">
                                                <Text color="#f97545" fontWeight="bold">{this.state.errorMessage}</Text>
                                            </Col>
                                        </Row>
                                    )}
                                    <Button color="danger" size={4} onClickAction={this.handleSubmit}><strong>WYŚLIJ</strong></Button>
                                </Col>
                            </Row>
                        </Col>
                    </>
                );
                break;

        }


        const bgImageURL = 'url(\'' + greenBG + '\')';
        return (
            <>
                <LandingPage title={this.surveyName} subtitle={"Zaproszenie do udziału w badaniu"} banner={homeschoolingBanner}>
                    <Row>
                        <Col align="center">
                            <BigHeader padding="1rem 0 0 0">Szanowni Państwo</BigHeader>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextParagraph align="justify">
                                Kontynuując projekt <strong>"Edukacja w cieniu wirusa"</strong>, który dzięki Wam z sukcesem zrealizowaliśmy w 2020 roku, mamy zaszczyt zaprosić
                                szkołę <span style={{color: '#49ad5c'}}><strong>{this.schoolName}</strong></span> do udziału w kolejnej jego odsłonie.
                                Badanie jest próbą spojrzenia na realizację podstawy programowej podczas nauczania zdalnego z perspektywy uczniów. Zakładamy, że będzie stanowić
                                uzupełnienie dotychczas prowadzonych badań, najczęściej odnoszących się do oceny nauczania zdalnego z perspektywy
                                nauczycieli, dyrektorów, rodziców. Pozwoli podjąć dyskusję na temat spostrzeżeń, dotyczących formy edukacji, z jaką przyszło się uczniom zmierzyć,
                                biorąc pod uwagę również ich głos w tej dyskusji.
                            </TextParagraph>
                            <TextParagraph>
                                Informujemy, że:
                                <ul>
                                    <li>Przystąpienie do badania nie wiąże się z żadnym zobowiązaniem dla szkoły</li>
                                    <li>Dyrekcja będzie mogła zapoznać się z ankietą przed udostępnieniem jej uczniom</li>
                                    <li>Przerwanie udziału w badaniu możliwe jest na każdym jego etapie</li>
                                </ul>
                            </TextParagraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col align="center" paddingTop="2rem">
                            <Button size={4} onClickAction={this.handleUpperButton}><strong>AKCEPTUJĘ ZAPROSZENIE</strong></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col align="center">
                            <BigHeader>JAK ZOSTAŁO PRZYGOTOWANE BADANIE?</BigHeader>
                        </Col>
                    </Row>
                    <Row>
                        <Col width={10} align="justify">
                            <Text>
                                <ul>
                                    <li style={{paddingBottom: '1rem'}}>
                                        powstała w oparciu o podstawę programową kształcenia ogólnego dla klas IV-VIII szkoły podstawowej ogólnodostępnej
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        źródłem odniesienia badania są te umiejętności wskazane w podstawie programowej, które są kształcone na wielu przedmiotach i które
                                        korespondują
                                        z kompetencjami kluczowymi
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        wyniki prezentują opinie uczniów na temat wybranych elementów edukacji zdalnej oraz samoocenę rozwoju własnych umiejętności
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        narzędzie pozwala na wnioskowanie o realizacji podstawy programowej poprzez odniesienie do preferowanych form aktywności uczniów na
                                        zajęciach on-line, najczęściej wykorzystywanych pomocy dydaktycznych, zmian w organizacji pracy, świadomości pojawiających się trudności
                                    </li>
                                </ul>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: '#ddd'}}>
                        <Col align="center">
                            <BigHeader bg="#ddd">JAKIE UZYSKAMY ODPOWIEDZI?</BigHeader>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: '#ddd', paddingBottom: '4rem'}}>
                        <Col width={10} align="justify">
                            <Text>
                                <ul style={{margin: 0}}>
                                    <li style={{paddingBottom: '1rem', paddingTop: '1rem'}}>
                                        <strong>Jaką samoświadomość mają uczniowie?</strong> Co podczas nauki on-line było dla uczniów trudne, co łatwe, co ich zainteresowało?
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        <strong>Czy nauczyciele trafnie dobierali pomoce naukowe?</strong> Wykorzystanie których pomocy dydaktycznych przez nauczycieli było dla
                                        uczniów szczególnie ważne, zapadło im w pamięć?
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        <strong>Jak uczniowie oceniają własną skuteczność?</strong> Jakie postępy osiągnęli w zakresie rozwoju wybranych umiejętności?
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        <strong>Jak oceniają rozwinięcie poszczególnych umiejętności?</strong> Czy dostrzegają zróżnicowanie ich rozwoju?
                                    </li>
                                    <li style={{paddingBottom: '1rem'}}>
                                        Jakie pytania dotyczące realizacji podstawy programowej można postawić w odniesieniu do wyników badania?
                                        <strong> Jakie są perspektywy? Co możemy poddać pogłębionej analizie?</strong>
                                    </li>
                                </ul>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{backgroundImage: bgImageURL, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', padding: "2rem 0"}}>
                        <Col width={12}>
                            <Row>
                                <Col width={8} align="center">
                                    <Text color="white">
                                        <span style={{fontSize: '1.15rem', color: '#59bd6c'}}>Przystąpienie do badania</span>
                                        <h2 style={{textTransform: 'uppercase', marginBottom: 0, color: '#69cd7c'}}>{this.surveyName}</h2><br/><br/>
                                        <h2 style={{fontSize: '1.15rem'}} ref={this.signUpFormRef}>{this.schoolName}</h2>
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        {phaseDependantContent}
                    </Row>
                </LandingPage>

            </>
        );
    }

}


const InterestPageConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageInterest)

PageInterest.propTypes = {}

;

export default InterestPageConnectedToRedux;