import React from 'react';
import PropTypes from 'prop-types';

export const TitleBannerWithoutMenu = (props) => {
    let bannerContent;
    const backgroundURL = 'url(\'' + props.banner + '\')';
    bannerContent = (
        <>
            <section className="mbr-section content4 cid-rz7YWhWzMl" id="content4-1c" style={{padding: 0, height: '12rem', backgroundImage: backgroundURL}}>
                <div className="container">
                    <div className="media-container-row align-center" style={{paddingTop: '4.5rem'}}>
                        <h1 className="align-center pb-3 mbr-fonts-style display-1" style={{textTransform: 'uppercase'}}>
                            <strong>
                                {props.title}
                            </strong>
                        </h1>
                    </div>

                </div>
            </section>
            <section className="mbr-section content4 cid-rz7YWhWzMl" id="content4-1c2" style={{padding: 0}}>
                <div style={{height: "1rem", width: "100%"}}>&nbsp;</div>
                <div className="container">
                    <div className="media-container-row align-center">
                        <div className="col col-12 d-none d-md-block">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                <strong>
                                    {props.subtitle}
                                </strong>
                            </h2>
                        </div>
                    </div>
                    <div className="media-container-row align-center d-block d-md-none">
                        <h2 className="align-center pb-3 mbr-fonts-style display-1">
                            <strong>
                                {props.subtitle}
                            </strong>
                        </h2>
                    </div>
                </div>
            </section>
        </>
    );

    return (
        <>
            {bannerContent}
        </>
    );
};

TitleBannerWithoutMenu.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired
}

export default TitleBannerWithoutMenu;

