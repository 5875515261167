
class Msg_pl {

    common = {
        next: 'Dalej',
        back: 'Wstecz',
        correctData: 'Popraw dane',
        register: 'Zarejestruj',
        school: 'Szkoła',
        user: 'Użytkownik',
        summary: 'Podsumowanie',
        step: 'Krok',
        error: {
            ApiServerNotAccessible: 'Przepraszamy. Panel klienta chwilowo niedostępny. Prosimy spróbować później.'
        }
    }

    exception = {
        UsernameNotFoundException: 'Nie znaleziono takiego użytkownika',
        BadCredentialsException: 'Niepoprawna nazwa użytkownika lub hasło',
        LockedException: 'Konto użytkownika jest nieaktywne',
        AuthenticationServiceException: 'Usługa chwilowo niedostępna',
        LoginEmpty: 'Proszę wpisać login',
        PasswordEmpty: 'Proszę wpisać hasło'
    }

    menu = {
        aboutUs: {
            link: '/o_nas',
            label: 'O nas'
        },
        news: {
            link: '/aktualnosci',
            label: 'Aktualności'
        },
        offer: {
            link: '/oferta',
            label: 'Oferta'
        },
        infoGraphic: {
            link: '/o_ewaluacji',
            label: 'O ewaluacji'
        },
        usefulLinks: {
            link: '/przydatne_linki',
            label: 'Linki'
        },
        testimonials: {
            link: '/referencje',
            label: 'Referencje'
        },
        priceList: {
            link: '/cennik',
            label: 'Cennik'
        },
        panel: {
            link: '/panel',
            label: 'Panel Klienta'
        },
        contact: {
            link: '/kontakt',
            label: 'Kontakt'
        },
        register: {
            link: '/rejestracja',
            label:  'Rejestracja'
        },
        activation: {
            link: '/aktywacja/:code',
            label: 'Aktywacja'
        },
        regmail: {
            link: '/regmail',
            label: 'Aktywacja konta'
        },
        logout: {
            label: 'Wyloguj'
        },
        schoolProfile: {
            link: '/szkola',
            label: 'Profil szkoły'
        },
        systemUsers: {
            link: '/pracownicy',
            label: 'Pracownicy'
        },
        schoolClasses: {
            link: '/klasy',
            label: 'Klasy'
        },
        surveys: {
            link: '/badania',
            label: 'Badania'
        },
        surveyWaves: {
            link: '/realizacja',
            label: 'Realizacja ankiet'
        },
        statistics: {
            link: '/stats',
            label: 'Zestawienia'
        },
        interest: {
            link: '/podstawa_programowa_w_czasie_pandemii/:hash',
            label: 'Zainteresowanie badaniem',
            linkPartToExcludeMenu: '/podstawa_programowa_w_czasie_pandemii/'
        },
        admin: {
            link: '/myztero',
            label: 'Panel Administracyjny'
        },
        adminHome: {
            link: '/adminHome',
            label: 'Dashboard'
        },
        adminMailing: {
            link: '/adminMailing',
            label: 'Mailing'
        },
        showEmail: {
            link: '/email/:hash',
            label: 'Podgląda wiadomości email',
            linkPartToExcludeMenu: '/email/'
        },
        cancelMailing: {
            link: '/cancel/:hash',
            label: 'Anulowanie mailingu'
        }
    }

    login = {
        error: {
            emptyLogin: 'Proszę wpisać login',
            emptyPassword: 'Proszę wpisać hasło'
        },
        form: {
            login: 'Login',
            password: 'Hasło',
            button: {
                login: 'Zaloguj'
            }
        }
    }

    registration = {
        RegistrationCompleted: 'Gratulujemy :)_BR_Rejestracja przebiegła pomyślnie.' +
            '_BR_' +
            '_BR_' +
            'Na podany adres email została wysłana wiadomość, zawierająca link aktywujący konto.' +
            '_BR_' +
            'Proszę postępować zgodnie z instrukcją zawartą w tej wiadomości',
        UserInsertError: 'Nie udało się utworzyć konta użytkownika. Proszę sprawdzić poprawność wpisanych danych',
        SchoolInsertError: 'Nie udało się utworzyć profilu szkoły. Proszę sprawdzić poprawność wpisanych danych',
        SchoolAlreadyExists: 'Szkoła o takim numerze NIP już istnieje. Rejestracja tej szkoły nie jest konieczna. _LOGIN_',
        UserAlreadyExists: 'Użytkownik o takim loginie już istnieje. Proszę wpisać inną nazwę użytkownika.',
        DataIntegrityViolationException: 'Nie udało się zarejestrować szkoły. Proszę sprawdzić poprawność wpisanych danych. Jeśli dane są poprawne i błąd ten się powtórzy, prosimy o kontakt z helpdeskiem',
        RegistrationEmailError: 'Przepraszamy. Wystąpił problem z wysyłaniem wiadomości rejestracyjnej'
    }

    activation = {
        Activated: 'Konto zostało aktywowane',
        AccountAlreadyActive: 'To konto jest już aktywne!!! :)',
        RegistrationExpired: 'Link aktywacyjny wygasł _BR_ _BR_ _BR_ Proszę ponownie wypełnić formularz rejestracyjny _REGISTER_',
        RodoNotAccepted: 'Konto nie mogło zostać aktywowane, ponieważ nie została zaakceptowana klauzula RODO',
        UserNotFound: 'Konto użytkownika nie zostało odnalezione dla tego linku aktywacyjnego. Proszę upewnić się, że użyto poprawnego linku, w całości razem z pełnym kodem'
    }


    CaptchaInfo = 'Zabezpieczenie przed robotami';
    CaptchaPlaceholder = 'Proszę tutaj wpisać wynik';
    CaptchaError = 'Proszę wpisać prawidłowy wynik działania matematycznego';
    FillErrorLogin = 'Proszę wpisać login';
    FillErrorPassword = 'Proszę wpisać hasło';
    FieldNameLogin = 'Login';
    FieldNamePassword = 'Hasło';
    ButtonLogin = 'Zaloguj';
    ApiServerNotAccessible = 'Przepraszamy. Panel klienta chwilowo niedostępny. Prosimy spróbować później.';

    /*
    Formularz szkoły
     */
    schoolBasicData = 'Dane podstawowe'
    schoolTypeLabel = 'Typ szkoły'
    schoolNameLabel = 'Nazwa szkoły'
    schoolNipLabel = 'NIP szkoły'
    schoolAddressLabel = 'Adres szkoły'
    schoolStreetLabel = 'Ulica'
    schoolHouseNumberLabel = 'Numer'
    schoolCityLabel = 'Miejscowość'
    schoolPostalCodeLabel = 'Kod pocztowy'
    schoolSecretariat = 'Sekretariat'
    schoolSecEmailLabel = 'Adres email szkoły'
    schoolSecPhoneLabel = 'Nr telefonu'
    schoolSchoolSizeLabel = 'Liczba uczniów'
    schoolVoivodeshipLabel = 'Województwo'
    schoolRspoLabel= 'Numer RSPO szkoły'
    schoolRegonLabel = 'REGON szkoły'
    schoolNumberOfStudentsLabel = 'Liczba uczniów szkoły'
    schoolPoviatLabel = 'Powiat'
    schoolGminaLabel = 'Gmina'
    schoolLocalityLabel = 'Miejscowość'
    schoolPostOfficeLocalityLabel = 'Miejscowość poczty'
    schoolWebsiteLabel = 'Strona internetowa'
    emailLoginLabel = 'Email użytkownika (login)'

    schoolBasicDataShort = 'Dane podstawowe'
    schoolTypeLabelShort = 'Typ'
    schoolNameLabelShort = 'Nazwa'
    schoolNipLabelShort = 'NIP'
    schoolAddressLabelShort = 'Adres'
    schoolStreetLabelShort = 'Ulica'
    schoolHouseNumberLabelShort = 'Numer'
    schoolCityLabelShort = 'Miejscowość'
    schoolPostalCodeLabelShort = 'Kod'
    schoolSecretariatShort = 'Sekretariat'
    schoolSecEmailLabelShort = 'Email szkoły'
    schoolSecPhoneLabelShort = 'Tel'
    schoolSchoolSizeLabelShort = 'Uczniowie'
    schoolVoivodeshipLabelShort = 'Województwo'
    schoolRspoLabelShort= 'RSPO'
    schoolRegonLabelShort = 'REGON'
    schoolNumberOfStudentsLabelShort = 'Uczniów'
    schoolPoviatLabelShort = 'Powiat'
    schoolGminaLabelShort = 'Gmina'
    schoolLocalityLabelShort = 'Miejscowość'
    schoolPostOfficeLocalityLabelShort = 'Poczta'
    schoolWebsiteLabelShort = 'WWW'
    emailLoginLabelShort = 'Email/Login'

    /*
    Formularz szkoły - komunikaty walidacyjne
     */
    schoolFormValidationErrorName = 'Proszę wpisać nazwę szkoły'
    schoolFormValidationErrorType = 'Proszę wybrać typ szkoły'
    schoolFormValidationErrorNIP = 'NIP szkoły jest niepoprawny'
    schoolFormValidationErrorStreet = 'Proszę wpisać adres szkoły'
    schoolFormValidationErrorHouseNumber = 'Proszę podać numer posesji'
    schoolFormValidationErrorCity = 'Proszę wpisać miejscowość'
    schoolFormValidationErrorPostalCode = 'Proszę wpisać kod pocztowy XX-XXX'
    schoolFormValidationErrorSecEmail = 'Proszę podać prawidłowy adres email'
    schoolFormValidationErrorSecPhone = 'Proszę podać numer telefonu'
    schoolFormValidationErrorSchoolSize = 'Proszę wybrać rozmiar szkoły'
    schoolFormValidationErrorVoivodeship = 'Proszę wybrać województwo'

    /*
    Formularz użytkownika
     */
    userFormHeader = 'Dane kierownika zespołu ds. ewaluacji w szkole'
    userAccountHeader = 'Tworzenie konta dostępowego do platformy Ewaluacja.Online'
    userFormIntroduction = 'Teraz utworzymy konto dostępowe dla osoby koordynującej pracę zespołu do spraw ewaluacji w Państwa szkole. Proszę na początek o wymyślenie loginu i hasła, którymi ta osoba ' +
        'będzie logowała się na platformę Ewaluacja.Online. Hasło musi składać się z co najmniej 8 znaków, zawierać wielką literę, małą literę oraz cyfrę. Musi być inne niż login.'
    userFormIntroductionPersonalData = 'W następnej kolejności proszę zapoznać się z klauzulą informacyjną RODO oraz w przypadku jej akceptacji wpisać dane osoby, dla której tworzymy konto. ' +
        'Brak akceptacji oznacza, że nie będzie możliwe utworzenie konta.'
    userFirstName = 'Imię'
    userLastName = 'Nazwisko'
    userEmail = 'Adres email'
    userEmailShort = 'Email'
    userEmailRepeated = 'Email powtórnie'
    userPhone = 'Nr telefonu'
    userPhoneShort = 'Telefon'
    userRodoHeader = 'Klauzula RODO'
    userRodoText = 'Informujemy, że dane osobowe przekazane w niniejszym formularzu będą przechowywane i przetwarzane przez nas na potrzeby prawidłowego działania usług oferowanych na portalu Ewaluacja.Online. ' +
        'Dane te nie będą przekazywane innym podmiotom. W każdym momencie macie Państwo prawo do uzyskania informacji, jakie dane, dotyczące Państwa są u nas w systemie, macie możliwość ich zmiany oraz usunięcia. ' +
        'Dane przechowywane są na serwerach administrowanych przez firmę i-Pi Sp. z o.o. z dołożeniem wszelkich starań o ich bezpieczeństwo. ' +
        'Dane te niezbędne nam są po to, aby utworzyć konto na portalu Ewaluacja.Online i widoczne będą w zakresie kont dostępowych szkoły. Akceptując tę klauzulę, wyrażacie Państwo zgodę na powyżej przedstawione ' +
        'zasady przechowywania i przetwarzania danych'
    userRodoAccepted = 'Oświadczam, że zapoznałam(em) się z powyższą klauzulą, wyrażam zgodę na przechowywanie i przetwarzanie moich danych wg zapisów tej klauzuli'
    userLogin = 'Login'
    userPassword = 'Hasło'
    userPasswordRepeated = 'Hasło powtórnie'
    userRole = 'Stanowisko'

    /*
    Formularz użytkownika - komunikaty walidacyjne
     */
    userFormValidationErrorFirstname = 'Proszę podać imię'
    userFormValidationErrorLastName = 'Proszę podać nazwisko'
    userFormValidationErrorEmail = 'Proszę podać adres email'
    userFormValidationErrorEmailRepeated = 'Wpisano różne adresy'
    userFormValidationErrorPhone = 'Proszę podać numer telefonu'
    userFormValidationErrorRodoAccepted = 'Zaakceptowanie klauzuli RODO jest wymagane'
    userFormValidationErrorLogin = 'Proszę wpisać login (minimum 5 znaków)'
    userFormValidationErrorPassword = 'Hasło musi zawierać małą literę, dużą literę, cyfrę i musi składać się z co najmniej 8 znaków. Dodatkowo nie może być takie samo jak login'
    userFormValidationErrorPasswordRepeated = 'Wpisano różne hasła'
    userFormValidationErrorRole = 'Proszę wybrać stanowisko użytkownika'

    surveyParamsForm = {
        maxSurveyInstances: 'Maksymalna liczba ankiet:',
        maxPupils: 'Dla uczniów',
        maxTeachers: 'Dla nauczycieli',
        maxParents: 'Dla rodziców'
    }
}

export default Msg_pl;