import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import Utils from "../../logic/Utils";
import $ from "jquery";
import Logger from "../../logic/Logger";
import {CopyToClipboard} from 'react-copy-to-clipboard';

class Button extends Component {

    static propTypes = {
        size: PropTypes.oneOf([1, 2, 3, 4]),
        href: PropTypes.string,
        onClickAction: PropTypes.func,
        color: PropTypes.oneOf(['primary', 'secondary', 'info', 'danger', 'dark']),
        dataToggle: PropTypes.string,
        dataTarget: PropTypes.string,
        fixedWidth: PropTypes.string,
        internalLinkTo: PropTypes.string,
        font: PropTypes.oneOf(['Roboto', 'Poppins', 'Open Sans', 'Segoe UI']),
        popoverTitle: PropTypes.func,
        popoverHTML: PropTypes.func,
        copyToClipboardText: PropTypes.string,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        color: 'primary',
        size: 1,
        disabled: false
    }

    buttonID = Utils.randomID();

    componentDidMount() {
        this.popovers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.popovers();
    }

    popovers = () => {
        const {popoverTitle, popoverHTML} = this.props;
        if (popoverHTML) {
            Logger.debug(popoverTitle());
            Logger.debug(popoverHTML());
            if (popoverHTML) {
                $('#' + this.buttonID).popover({
                    html: true,
                    container: 'body',
                    trigger: 'focus',
                    placement: 'auto',
                    title: popoverTitle(),
                    content: popoverHTML()
                }).on('show.bs.popover', function () {
                    $($(this).data("bs.popover").getTipElement()).css("max-width", "700px");
                });
            }
        }
    }

    render() {
        const {size, href, onClickAction, dataToggle, color, dataTarget, fixedWidth, internalLinkTo, font, copyToClipboardText, children} = this.props;

        let buttonClass, buttonStyle;

        const basicClasses = 'btn btn-' + color;
        switch (size) {
            case 1:
                buttonClass = basicClasses + ' btn-sm';
                buttonStyle = {
                    padding: '0.3rem 0.8rem'
                }
                break;
            case 2:
                buttonClass = basicClasses + ' btn-sm';
                buttonStyle = {}
                break;
            case 3:
                buttonClass = basicClasses + ' btn-md';
                buttonStyle = {
                    padding: '0.8rem 2rem'
                }
                break;
            case 4:
                buttonClass = basicClasses;
                buttonStyle = {}
                break;
        }

        if (fixedWidth) {
            buttonStyle.width = fixedWidth;
        }

        if (font) {
            buttonStyle.fontFamily = font + ', Sans-Serif';
        }

        let content;
        if (children) {
            if (href) {
                if (copyToClipboardText) {
                    content = (
                        <a tabIndex="0" id={this.buttonID} href={href} target="_blank" className={buttonClass} style={buttonStyle} data-toggle={dataToggle}
                           data-target={dataTarget}>
                            <CopyToClipboard text={copyToClipboardText}>
                                <span>
                                    {children}
                                </span>
                            </CopyToClipboard>
                        </a>
                    );
                } else {
                    content = (
                        <a tabIndex="0" id={this.buttonID} href={href} target="_blank" className={buttonClass} style={buttonStyle} data-toggle={dataToggle}
                           data-target={dataTarget}>
                            {children}
                        </a>
                    );
                }
            } else if (internalLinkTo) {
                if (copyToClipboardText) {
                    content = (
                        <NavLink tabIndex="0" id={this.buttonID} to={internalLinkTo} className={buttonClass} onClick={onClickAction} style={buttonStyle} data-toggle={dataToggle}
                                 data-target={dataTarget}>
                            <CopyToClipboard text={copyToClipboardText}>
                                <span>
                                    {children}
                                </span>
                            </CopyToClipboard>
                        </NavLink>
                    );
                } else {
                    content = (
                        <NavLink tabIndex="0" id={this.buttonID} to={internalLinkTo} className={buttonClass} onClick={onClickAction} style={buttonStyle} data-toggle={dataToggle}
                                 data-target={dataTarget}>
                            {children}
                        </NavLink>
                    );
                }
            } else {
                if (copyToClipboardText) {
                    content = (
                        <CopyToClipboard text={copyToClipboardText}>
                            <button tabIndex="0" id={this.buttonID} className={buttonClass} style={buttonStyle} disabled={this.props.disabled}>
                                {children}
                            </button>
                        </CopyToClipboard>
                    );
                } else {
                    content = (
                        <button tabIndex="0" id={this.buttonID} type="button" className={buttonClass} onClick={onClickAction} style={buttonStyle} data-toggle={dataToggle}
                                data-target={dataTarget} disabled={this.props.disabled}>
                            {children}
                        </button>
                    );
                }
            }
        } else
            {
                content = null;
            }

        return (
            <>
                {content}
            </>
        );
        }
        }


        export default Button;