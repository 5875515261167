import React from 'react';
import icon from "../../../img/bazawiedzy/icon.jpg";

const PriceListDescriptionItem = props => {
    return (
        <div className="row">
            <div className="col-2">
                <img src={icon} alt="" style={{paddingTop: '7px'}}/>
            </div>
            <div className="col">
                <p className="text-left mbr-text mbr-fonts-style display-7" style={{fontSize: '0.9em'}}>{props.children}</p>
            </div>
        </div>
    );
};

export default PriceListDescriptionItem;