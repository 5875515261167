class ValidationMessage {
    /**
     * @type {string}
     */
    key;

    /**
     * @type {string}
     */
    text;


    static get = (key, text) => {
        const err = new ValidationMessage();
        err.key = key;
        err.text = text;
        return err;
    }
}

export default ValidationMessage;