import React from 'react';
import PropTypes from "prop-types";

const ErrorMessage = props => {
    const errorsList = props.messagesList.map((errorMessage, index) => (
        <li key={index} className="" style={{
            color: 'red',
            // listStyle: 'url(https://icons.iconarchive.com/icons/icojam/onebit/32/exclamation-mark-red-icon.png)'
            // background: 'url(https://icons.iconarchive.com/icons/iconarchive/red-orb-alphabet/32/Exclamation-mark-icon.png) no-repeat left center',
            background: 'url(https://icons.iconarchive.com/icons/double-j-design/ravenna-3d/32/Exclamation-icon.png) no-repeat left center',
            paddingLeft: '40px',
            margin: '10px 0',
            border: '0px solid blue',
            lineHeight: '32px'
        }}>{errorMessage}</li>
    ));

    let classesNames;

    if (props.cols) {
        classesNames = "col-lg-" + props.cols + " align-content-center";
    } else {
        classesNames = "col-lg-6 align-content-center";
    }

    return (
        <div className={classesNames} style={{border: '0px solid red', margin: '0.5rem auto', padding: '0.1rem 0.5rem'}}>
            <div style={{border: '0px solid red', textAlign: 'center'}}>
                <ul style={{listStyleType: 'none', margin: '0 auto', padding: 0, border: '0px solid green', display: 'inline-block', textAlign: 'left'}}>
                    {errorsList}
                </ul>
            </div>
        </div>
    );
};

ErrorMessage.propTypes = {
    messagesList: PropTypes.array,
    cols: PropTypes.number
}

export default ErrorMessage;