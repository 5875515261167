import ApiEngine from "./ApiEngine";
import InitialDataLoadedAction from "../store/actions/profile/InitialDataLoadedAction";
import ApiUrl from "./ApiUrl";

class DictionariesFetcher {

    /**
     * @private
     * @static
     * @field
     * @type {boolean}
     */
    static schoolTypesLoaded = false;

    /**
     * @private
     * @static
     * @field
     * @type {boolean}
     */
    static schoolSizesLoaded = false;

    /**
     * @private
     * @static
     * @field
     * @type {boolean}
     */
    static userRolesLoaded = false;

    /**
     * @private
     * @static
     * @field
     * @type {boolean}
     */
    static voivodeshipsLoaded = false;

    /**
     * @private
     * @static
     * @field
     * @type {boolean}
     */
    static connectionError = false;

    /**
     * @private
     * @static
     * @field
     * @type {boolean}
     */
    static fetchingStarted = false;

    /**
     * @public
     * @static
     * @method
     * @param {function} dispatch
     */
    static fetchAllDictionaries = (dispatch) => {
        /*DictionariesFetcher.fetchSchoolTypes(dispatch);
        DictionariesFetcher.fetchSchoolSizes(dispatch);
        DictionariesFetcher.fetchUserRoles(dispatch);
        DictionariesFetcher.fetchVoivodeships(dispatch);*/
        DictionariesFetcher.startFetchingDictionaries(dispatch);
    }

    /**
     * @public
     * @static
     * @method
     * @param {function} dispatch
     */
    static fetchAllDictionariesIfErrorOccurred = (dispatch) => {
        if (DictionariesFetcher.connectionError) {
            console.log('fetchAllDictionariesIfErrorOccurred');
            DictionariesFetcher.connectionError = false;
            DictionariesFetcher.fetchAllDictionaries(dispatch);
        }
    }

    /**
     * @public
     * @static
     * @method
     * @return {boolean}
     */
    static errorOccurred = () => {
        return DictionariesFetcher.connectionError;
    }

    /**
     * @private
     * @static
     * @param url
     * @param func
     */
    static fetchDictionary = (url, func) => {
        if (!DictionariesFetcher.connectionError) {
            ApiEngine.getOpen(url, (response) => {
                console.log('Loaded: ' + url + "\tstarted: " + DictionariesFetcher.fetchingStarted + "\terror: " + DictionariesFetcher.connectionError);
                func(response.data);
            }, error => {
                console.log('Problem z łącznością z serwerem API');
                DictionariesFetcher.connectionError = true;
                DictionariesFetcher.fetchingStarted = false;
            });
        }
    }

    /**
     * @private
     * @static
     * @method
     * @param {function} dispatch
     */
    static startFetchingDictionaries = dispatch => {
        if (!DictionariesFetcher.fetchingStarted) {
            DictionariesFetcher.fetchingStarted = true;
            DictionariesFetcher.fetchSchoolTypes(dispatch);
        } else {
            console.log('Fetching already started. Cannot run again until currently running fetching finishes');
        }
    }

    /**
     * @private
     * @static
     * @param {function} dispatch
     */
    static fetchSchoolTypes = (dispatch) => {
        DictionariesFetcher.fetchDictionary(ApiUrl.API.OPEN.DICTIONARY.SCHOOL_TYPES, responseData => {
            ApiEngine.SCHOOL_TYPES = responseData;
            DictionariesFetcher.schoolTypesLoaded = true;
            DictionariesFetcher.fetchSchoolSizes(dispatch);
        });
    }

    /**
     * @private
     * @static
     * @param {function} dispatch
     */
    static fetchSchoolSizes = (dispatch) => {
        DictionariesFetcher.fetchDictionary(ApiUrl.API.OPEN.DICTIONARY.SCHOOL_SIZES, responseData => {
            ApiEngine.SCHOOL_SIZES = responseData;
            DictionariesFetcher.schoolSizesLoaded = true;
            DictionariesFetcher.fetchUserRoles(dispatch);
        });
    }

    /**
     * @private
     * @static
     * @param {function} dispatch
     */
    static fetchUserRoles = (dispatch) => {
        DictionariesFetcher.fetchDictionary(ApiUrl.API.OPEN.DICTIONARY.USER_ROLES, responseData => {
            ApiEngine.USER_ROLES = responseData;
            DictionariesFetcher.userRolesLoaded = true;
            DictionariesFetcher.fetchVoivodeships(dispatch);
        });
    }

    /**
     * @private
     * @static
     * @param {function} dispatch
     */
    static fetchVoivodeships = (dispatch) => {
        DictionariesFetcher.fetchDictionary(ApiUrl.API.OPEN.DICTIONARY.VOIVODESHIPS, responseData => {
            ApiEngine.VOIVODESHIPS = responseData;
            DictionariesFetcher.voivodeshipsLoaded = true;
            DictionariesFetcher.setInitialDataLoadedState(dispatch);
        });
    }

    /**
     * @private
     * @static
     * @param {function} dispatch
     */
    static setInitialDataLoadedState = dispatch => {
        if (DictionariesFetcher.schoolTypesLoaded
            && DictionariesFetcher.schoolSizesLoaded
            && DictionariesFetcher.userRolesLoaded
            && DictionariesFetcher.voivodeshipsLoaded) {
            InitialDataLoadedAction.run(dispatch, true);
        } else {
            DictionariesFetcher.connectionError = true;
        }
        DictionariesFetcher.fetchingStarted = false;
    }
}

export default DictionariesFetcher;