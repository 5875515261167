import TextInput from "./controls/TextInput";
import React from "react";
import RadioGroup from "./controls/RadioGroup";
import FieldType from "./FieldType";
import FieldParams from "./FieldParams";
import Select from "./controls/Select";
import Checkbox from "./controls/Checkbox";
import School from "../../model/School";
import Range from "./controls/Range";

class Field {
    name;
    label;
    fieldType;
    errorsHTML;

    constructor(name, label, fieldType) {
        this.name = name;
        this.label = label;
        this.fieldType = fieldType;
        this.errorsHTML = null;
    }

    static get = (name, label, fieldType) => {
        return new Field(name, label, fieldType)
    }

    /**
     * @public
     * @method
     * @param {FieldParams} fieldParams
     * @return {null|*}
     */
    getReactElement(fieldParams) {
        const validationResult = fieldParams.validationResult;
        this.errorsHTML = validationResult ? validationResult.getErrorsHTMLForKey(this.name) : null;
        switch (this.fieldType) {
            case FieldType.text:
                return this.textInput(fieldParams, false);
            case FieldType.radio:
                return this.radioGroup(fieldParams);
            case FieldType.select:
                return this.select(fieldParams);
            case FieldType.checkbox:
                return this.checkbox(fieldParams);
            case FieldType.password:
                return this.textInput(fieldParams, true);
            case FieldType.number:
                return this.textInput(fieldParams, false);
            case FieldType.range:
                return this.rangeInput(fieldParams);
            default:
                return null;
        }
    }

    prefixTagWithErrors(tag) {
        return (
            <>
                {this.errorsHTML}
                {tag}
            </>
        );
    }

    makeID(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    /**
     *
     * @param {FieldParams} fieldParams
     * @param {boolean} isPassword
     * @return {TextInput}
     */
    textInput(fieldParams, isPassword) {
        const {fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent} = fieldParams;
        const tag = <TextInput label={this.label} name={this.name} fieldValue={String(fieldValue)} onChangeFunc={onChangeFunc} ratio={responsiveRatio}
                               marginBottom={marginBelowComponent} isPassword={isPassword}/>;
        return this.prefixTagWithErrors(tag);
    }

    /**
     *
     * @param {FieldParams} fieldParams
     * @return {JSX.Element}
     */
    rangeInput(fieldParams) {
        const {fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent, from, to} = fieldParams;
        const tag = (<Range id={this.makeID(6)} label={this.label} name={this.name}
                           from={from} to={to} ratio={responsiveRatio} onChangeFunc={onChangeFunc} fieldValue={fieldValue} marginBottom={marginBelowComponent}/>);
        return this.prefixTagWithErrors(tag);
    }

    /**
     *
     * @param {FieldParams} fieldParams
     * @return {RadioGroup}
     */
    radioGroup(fieldParams) {
        const {fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent, dictionary} = fieldParams;
        const tag = <RadioGroup label={this.label} values={dictionary} name={this.name} ratio={responsiveRatio} marginBottom={marginBelowComponent} fieldValue={String(fieldValue)}
                                onChangeFunc={onChangeFunc}/>;
        return this.prefixTagWithErrors(tag);
    }

    /**
     * @param {FieldParams} fieldParams
     * @return {Select}
     */
    select(fieldParams) {
        const {fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent, dictionary} = fieldParams;
        const tag = <Select label={this.label} onChangeFunc={onChangeFunc} fieldValue={String(fieldValue)} name={this.name} values={dictionary} ratio={responsiveRatio}
                            marginBottom={marginBelowComponent}/>;
        return this.prefixTagWithErrors(tag);
    }

    /**
     * @param {FieldParams} fieldParams
     * @return {Checkbox}
     */
    checkbox(fieldParams) {
        const {fieldValue, onChangeFunc, responsiveRatio, marginBelowComponent} = fieldParams;
        const tag = <Checkbox label={this.label} name={this.name} onChangeFunc={onChangeFunc} ratio={responsiveRatio} marginBottom={marginBelowComponent} checked={fieldValue}
                              labelFollowingCheckbox={false}/>;
        return this.prefixTagWithErrors(tag);
    }

    /**
     * @param e Event
     * @returns {{fieldName, fieldValue: *}}
     */
    static readFromEvent = e => {
        e.persist();
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        return {
            fieldName: name,
            fieldValue: value
        }
    }
}

export default Field;