import React, {Component} from 'react';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../../i18n/Msg";
import underConstructionIMG from '../../img/under_construction.png';
import Subpage from "../../components/website/Subpage";
import Row from "../../components/website/Row";
import Col from "../../components/website/Col";
import Text from "../../components/website/Text";
import PageSurveyWaves from "./PageSurveyWaves";

class PageStatistics extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem, Msg.of().menu.statistics.label);
    }

    render() {
        const profileState = State.getProfileState(this.props);
        const underConstructionURL = 'url(' + underConstructionIMG + ')';
        if (profileState.isLogged()) {
            return (
                <>
                    <Subpage title={"Zestawienia i statystyki"} small={true}>
                        <Row style={{minHeight: '31rem'}}>
                            <Col width={6} style={{backgroundImage: underConstructionURL, backgroundSize: 'cover'}} padding="0" paddingLeftRight="0" align="justify">
                                <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                                    <Text style={{padding: '2rem 2rem 1rem', color: 'black'}}>
                                        <strong>
                                            W tym miejscu będą mogli Państwo zobaczyć wyniki z bieżących badań.
                                            Po zakończeniu badania otrzymają Państwo darmowy raport.
                                        </strong>
                                    </Text>
                                    <Text style={{padding: '2rem 2rem 10rem', color: 'black'}}>
                                        <strong>
                                            Zakładka
                                            będzie gotowa wkrótce. W obecnym momencie jesteśmy na etapie końcowych testów oprogramowania.
                                        </strong>
                                    </Text>
                                </div>
                            </Col>
                        </Row>
                    </Subpage>
                </>
            );
        } else {
            return <PageLoginUser/>
        }
    }
}


const PageStatisticsConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageStatistics)

PageStatistics.propTypes = {};

export default PageStatisticsConnectedToRedux;