import Action from "./Action";
import ActionBuilder from "./ActionBuilder";
import Logger from "../logic/Logger";

class BaseReducer {
    /**
     * @type {object}
     */
    initialState;

    /**
     * @type {Array}
     */
    handledActions;

    reducerName;


    constructor(reducerName, initialState) {
        this.reducerName = reducerName;
        this.initialState = initialState;
    }

    /**
     * @public
     * @method
     * @description funkcja reducera
     * @param {object} state
     * @param {{type: string, payload: Object}} plainAction
     * @return {object}
     */
    reduce = (state = this.initialState, plainAction) => {
        const action = ActionBuilder.buildAction(plainAction.type, plainAction.payload);
        if (this.handles(action)) {
            // Logger.debug('Reducer ' + this.reducerName + ' handles action: ' + action.type);
            const actionHandleResult = action.handle(state);
            if(actionHandleResult) {
                return actionHandleResult;
            } else {
                Logger.debug('ACTION ERROR - looks like ActionBuilder did not do the job - handles function is: ')
                Logger.debug(action.handle.toString())
                return state;
            }
        } else {
            // Logger.debug('Reducer ' + this.reducerName + ' does not handle: ' +  action.type);
            return state;
        }
    }

    /**
     *
     * @param {Action} action
     * @return {boolean}
     */
    handles = action => {
        if (this.handledActions) {
            if (this.handledActions.find(actionName => actionName === action.type)) {
                return true;
            }
        }
        return false;
    }

    isPlainObject = (obj) => {
        if (typeof obj !== 'object' || obj === null) return false;
        let proto = obj;

        while (Object.getPrototypeOf(proto) !== null) {
            proto = Object.getPrototypeOf(proto);
        }

        return Object.getPrototypeOf(obj) === proto;
    }

}

export default BaseReducer;