import {combineReducers} from 'redux';
import ProfileReducer from "./reducers/ProfileReducer";
import ProfileState from "./states/ProfileState";
import ApiEngine from "../logic/ApiEngine";
import SurveyReducer from "./reducers/SurveyReducer";

class State {

    /**
     * @field
     * @type {string[]}
     */
    listOfStates;


    constructor(listOfStates) {
        this.listOfStates = listOfStates;
    }

    static builder = () => {
        return new State([]);
    }

    /**
     * @public
     * @static
     * @method
     * @description Zwraca metodę mapDispatchToProps, która dodaje do propsów metodę dispatch
     * @return {function(function)}
     */
    static mapDispatchToProps = () => {
        const func = (
            dispatch => {
                const objectResult = {
                    dispatch: dispatch
                }
                return objectResult;
            }
        );
        return func;
    }

    /**
     *
     * @param stateName
     * @return {State}
     */
    add = stateName => {
        this.listOfStates = [...this.listOfStates, stateName];
        return this;
    }

    list = () => {
        return this.listOfStates;
    }


    /**
     * @public
     * @method
     * @description Zwraca metodę mapStateToProps, która dodaje do propsów te stany, które zostały dodane metodami budującymi,
     * np. State.builder().profile().mapStateToProps() spowoduje, że w propsach znajdzie się ProfileState
     * @return {function(*): Object}
     */
    mapStateToProps = () => {
        const func = (state) => {
            const dataObject = {};
            this.listOfStates.forEach(value => {
                dataObject[value] = state[value];
            });
            return dataObject;
        }
        return func;
    }

    /**
     *
     * @return {State}
     */
    profile = () => {
        return this.add(State.PROFILE_STATE);
    }

    survey = () => {
        return this.add(State.SURVEY_STATE);
    }

    static PROFILE_STATE = 'profileState';
    static SURVEY_STATE = 'surveyState';

    /**
     * Zwraca stan ProfileState
     * @param {Object} props
     * @return {ProfileState}
     */
    static getProfileState = props => {
        return props[State.PROFILE_STATE];
    }

    /**
     * Zwraca stan SurveyState
     * @param {Object} props
     * @return {SurveyState}
     */
    static getSurveyState = props => {
        return props[State.SURVEY_STATE];
    }

    /**
     * Zwraca stan ProfileState
     * @return {ProfileState}
     */
    static getProfileStateFromStore = () => {
        return ApiEngine.STORE.getState()[State.PROFILE_STATE];
    }

    static getRootReducer = () => {
        const profileReducerFunc = ProfileReducer.get();
        const surveyReducerFunc = SurveyReducer.get();
        return combineReducers({
            [State.PROFILE_STATE]: profileReducerFunc,
            [State.SURVEY_STATE]: surveyReducerFunc
        })
    }

}

export default State;