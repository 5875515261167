import React from 'react';
import PropTypes from 'prop-types';

export const Col = (props) => {
    const {width, align, padding, paddingBottom, paddingLeft, paddingRight, paddingTop, paddingLeftRight, paddingTopBottom} = props;
    let style = props.style;
    const colClasses = "col-12 col-lg-" + String(width);
    if (align || padding || paddingBottom || paddingLeft || paddingRight || paddingTop || paddingLeftRight || paddingTopBottom) {
        if (!style) {
            style = {}
        }
        if (align) style.textAlign = align;
        if (padding) style.padding = padding;
        if (paddingBottom) style.paddingBottom = paddingBottom;
        if (paddingLeft) style.paddingLeft = paddingLeft;
        if (paddingTop) style.paddingTop = paddingTop;
        if (paddingRight) style.paddingRight = paddingRight;
        if (paddingLeftRight) style.paddingLeft = style.paddingRight = paddingLeftRight;
        if (paddingTopBottom) style.paddingTop = style.paddingBottom = paddingTopBottom;

    }
    return (
        <>
            <div className={colClasses} style={style}>
                {props.children}
            </div>
        </>
    );
};

Col.propTypes = {
    width: PropTypes.number,
    style: PropTypes.object,
    align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
    padding: PropTypes.string,
    paddingLeft: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingRight: PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingTopBottom: PropTypes.string,
    paddingLeftRight: PropTypes.string
}

Col.defaultProps = {
    width: 12
}

export default Col;

