import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import Msg from "../../i18n/Msg";

MenuItem.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string.isRequired,
    button: PropTypes.bool,
    onClickFunc: PropTypes.func,
    active: PropTypes.bool,
    onChangeMenuFunc: PropTypes.func,
    activeMenuItem: PropTypes.string
};

function MenuItem(props) {

    let styleObject;
    if (props.button) {
        styleObject = {backgroundColor: '#d0d0d0', borderRadius: '3px', marginLeft: '10px'};
    } else {
        styleObject = {};
    }

    let style;

    if (props.active || (props.activeMenuItem && props.activeMenuItem === props.label)) {
        style = {
            fontSize: '0.8rem',
            textDecoration: 'underline'
        }
    } else {
        style = {
            fontSize: '0.8rem'
        }
    }

    let onClickFuncToRun;

    if (props.onClickFunc) {
        if(props.onChangeMenuFunc) {
            onClickFuncToRun = () => {
                props.onChangeMenuFunc();
                props.onClickFunc();
            }
        } else {
            onClickFuncToRun = () => {
                props.onClickFunc();
            }
        }
    } else {
        if(props.onChangeMenuFunc) {
            onClickFuncToRun = () => {
                props.onChangeMenuFunc();
            }
        } else {
            onClickFuncToRun = () => {};
        }
    }

    let link;
    if (props.onClickFunc) {
        link = (
            <a className="nav-link link text-black display-4" onClick={onClickFuncToRun} style={style}>
                {props.label}
            </a>
        );
    } else {
        link = (
            <NavLink className="nav-link link text-black display-4" to={props.to} style={style} onClick={onClickFuncToRun}>
                {props.label}
            </NavLink>
        );
    }

    return (
        <>
            <li className="nav-item" style={styleObject}>
                {link}
            </li>
        </>
    );
}

export default MenuItem;