import React, {Component} from 'react';
import Utils from "../../logic/Utils";

class ScrollToMeOnRender extends Component {
    state = {}

    myRef = React.createRef();

    componentDidMount() {
        Utils.scrollToRef(this.myRef)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        Utils.scrollToRef(this.myRef)
    }

    render() {
        return (
            <>
                <div ref={this.myRef} style={{height: 10, width: 100}}> </div>
            </>
        );
    };
};

export default ScrollToMeOnRender;