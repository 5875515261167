import React from 'react';
import linkIcon from '../../img/link-icon.png'

const ExternalLinkItem = (props) => {
    return (
        <>
            <div className="row">
                <table>
                    <tbody>
                    <tr>
                        <td style={{padding: '0.5rem 2rem'}}>
                            <img src={linkIcon} alt={props.label}/>
                        </td>
                        <td>
                            <a href={props.link} target="_blank">
                                <strong>{props.label}</strong>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ExternalLinkItem;