class SchoolUser {

    static fieldNames = {
        eduInstitutionID: 'eduInstitutionID',
        firstname: 'firstname',
        lastname: 'lastname',
        email: 'email',
        phone: 'phone',
        password: 'password',
        userRole: 'userRole',
        emailRepeated: 'emailRepeated',
        passwordRepeated: 'passwordRepeated'
    }

    /**
     * @public
     * @field
     * @type {number}
     * @description ID szkoły
     */
    eduInstitutionID;

    /**
     * @public
     * @field
     * @type {string}
     * @description email i jednocześnie login
     */
    email;
    emailRepeated;

    /**
     * @public
     * @field
     * @type {string}
     * @description hasło
     */
    password;
    passwordRepeated;

    /**
     * @public
     * @field
     * @type {string}
     * @description imię
     */
    firstName;

    /**
     * @public
     * @field
     * @type {string}
     * @description nazwisko
     */
    lastName;

    /**
     * @public
     * @field
     * @type {string}
     * @description numer telefonu
     */
    phoneNo;

    /**
     * @public
     * @field
     * @type {number}
     * @description typ użytkownika
     */
    userRole;


    constructor(eduInstitutionID, email, password, firstName, lastName, phoneNo, userRole) {
        this.eduInstitutionID = eduInstitutionID;
        this.email = email;
        this.password = password;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNo = phoneNo;
        this.userRole = userRole;
    }

    /**
     *
     * @param {number} eduInstitutionID
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phoneNo
     * @param {number} userRole
     * @return {SchoolUser}
     */
    static get = (eduInstitutionID, email, password, firstName, lastName, phoneNo, userRole) => {
        return new SchoolUser(eduInstitutionID, email, password, firstName, lastName, phoneNo, userRole);
    }

    static fromObject = obj => {
        const {eduInstitutionID, email, password, firstName, lastName, phoneNo, userRole} = obj;
        return new SchoolUser(eduInstitutionID, email, password, firstName, lastName, phoneNo, userRole);
    }
}

export default SchoolUser;
