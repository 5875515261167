import React from 'react';
import {Link} from "react-router-dom";

const LinkInText = props => {
    let {to, target, children, color} = props;
    if (!target) {
        target = "_blank";
    }
    return (
        <>
            <a href={to} target={target}>
                <strong className="text-warning" ref={(el) => {
                    if (el && color) {
                        return el.style.setProperty("color", color, "important")
                    } else {
                        return null;
                    }
                }}>
                {children}
                    </strong></a>
                    </>
                    );
                };

export default LinkInText;