import React, {Component} from 'react';
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import TitleBanner from "../components/website/TitleBanner";
import ApiEngine from "../logic/ApiEngine";
import School from "../model/School";
import SchoolForm from "../form/SchoolForm";
import User from "../model/User";
import UserForm from "../form/UserForm";
import Utils from "../logic/Utils";
import ValidationResult from "../logic/ValidationResult";
import BackForward from "../components/visual/BackForward";
import InfoTable from "../components/visual/InfoTable";
import Msg from "../i18n/Msg";
import ApiUrl from "../logic/ApiUrl";
import preloader39 from "../img/preloader39.svg";
import Field from "../form/components/Field";

class PageRegister extends Component {


    state = {
        school: School.getDefault(),
        user: User.getDefault(),
        validationResult: ValidationResult.get(),
        formStep: 1,
        formForwardClicked: false,
        submittingStage: 0, // 0 - not submitting/not submitted, 1 - submitting in progress, 2 - submitteds
        activationStatus: 0 //0 - aktywacja trwa, 1 - aktywacja zakończona sukcesem, 2 - błędy
    }

    activation = {} //mapa typu "kod aktywacji":boolean, gdzie flaga określa, czy aktywacja tym kodem była już dokonywana. Chodzi o to, aby nie zwielokrotniać żądań

    /**
     * @param {object} e zdarzenie
     * @param {string} objectName nazwa obiektu w state
     */
    handleStateObject = (e, objectName) => {
        e.persist();
        this.setState(prevState => {
            const fromEvent = Field.readFromEvent(e);
            const newState = prevState;
            newState[objectName][fromEvent.fieldName] = fromEvent.fieldValue;
            return newState;
        })
    }

    /**
     * @description funkcja generująca funkcje handle dla formularza
     * @param objectName
     * @return {function}
     */
    handleGenerator = objectName => {
        const handleFunc = e => {
            this.handleStateObject(e, objectName);
        }
        return handleFunc;
    }

    handleFormForward = e => {
        e.preventDefault();
        this.setState({formForwardClicked: true});
        let validationResult;
        const currentStep = this.state.formStep;
        switch (currentStep) {
            case 1:
                validationResult = this.state.school.validate();
                break;
            case 2:
                validationResult = this.state.user.validate();
                break;
            default:
                validationResult = ValidationResult.get();
                break;
        }
        if (validationResult.isValid()) {
            this.setState({formStep: currentStep + 1, validationResult: ValidationResult.get()});
        } else {
            this.setState(prevState => {
                return {...prevState, validationResult};
            })
        }
    }

    handleFormBackward = e => {
        e.preventDefault();
        const currentStep = this.state.formStep;
        this.setState({
            submittingStage: 0 //not submitting, not submitted
        });
        if (currentStep > 1) {
            this.setState({formForwardClicked: true, formStep: currentStep - 1});
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const codePrev = prevProps.match && prevProps.match.params ? prevProps.match.params.code : undefined;
        const codeNow = this.props.match && this.props.match.params ? this.props.match.params.code : undefined;
        if(codePrev && !codeNow) {
            this.setState({
                validationResult: ValidationResult.get(),
                formStep: 1,
                formForwardClicked: false,
                submittingStage: 0,
                activationStatus: 0
            })
        }
        if (this.state.formForwardClicked) {
            Utils.scrollToTop();
            this.setState({formForwardClicked: false});
        }
    }

    formStep1 = (formSectionStyle) => {
        console.log('STEP 1');
        console.log(this.props);
        return (
            <>
                <section className="mbr-section article content1 cid-rz87GNwyCo" id="content1-2c" style={formSectionStyle}>
                    {SchoolForm.get(this.state.school, this.handleGenerator('school'), this.state.validationResult)}
                </section>
                <BackForward forwardFunc={this.handleFormForward} hideBack={true}/>

            </>
        );
    }

    formStep2 = (formSectionStyle) => {
        return (
            <>
                <section className="mbr-section article content1 cid-rz87GNwyCo" style={formSectionStyle}>
                    {UserForm.get(this.state.user, this.handleGenerator('user'), this.state.validationResult)}
                </section>
                <BackForward backFunc={this.handleFormBackward} forwardFunc={this.handleFormForward}/>
            </>
        );
    }

    formStep3 = (formSectionStyle, schoolInfo, userInfo) => {
        const msg = Msg.get();
        const errorsHTML = this.state.validationResult.getErrorsHTMLForAllKeys();
        const oksHTML = this.state.validationResult.getOksHTMLForKey('r', {fontSize: '1.2rem', textAlign: 'center', marginTop: '1.875rem'});
        return (
            <>
                <section className="mbr-section article content1 cid-rz87GNwyCo" style={formSectionStyle}>
                    {
                        (!oksHTML || oksHTML.length === 0)
                        &&
                        <div className="container align-center" style={{padding: '1rem 0'}}>
                            <div className="align-center">
                                <p style={{textTransform: 'uppercase', marginBottom: 0}}><strong>{msg.common.summary}</strong></p>
                            </div>
                        </div>
                    }
                    <div className="container">
                        {
                            (!oksHTML || oksHTML.length === 0)
                            &&
                            <div className="row">
                                <div className="col col-12 col-lg-6" style={{paddingBottom: '3rem'}}>
                                    <InfoTable header={msg.common.school} content={schoolInfo}/>
                                </div>
                                <div className="col col-12 col-lg-6" style={{paddingBottom: '3rem'}}>
                                    <InfoTable header={msg.common.user} content={userInfo}/>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col col-12 align-center">
                                <div className="align-left" style={{display: 'inline-block'}}>
                                    {errorsHTML}
                                    {oksHTML}
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.submittingStage === 1) //submitting in progress
                            && (
                                <div className="row" style={{paddingBottom: '2rem'}}>
                                    <div className="col col-12 align-center">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                                 aria-valuemax="100"
                                                 style={{width: '100%'}}>
                                                Trwa wysyłanie. Proszę czekać
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </section>
                {
                    (this.state.submittingStage !== 1) //not submitting and not submitted)
                    && (!oksHTML || oksHTML.length === 0)
                    && (
                        <BackForward backLabel={msg.common.correctData} forwardLabel={msg.common.register} backFunc={this.handleFormBackward}
                                     forwardFunc={this.handleRegister}/>

                    )
                }
            </>
        );
    }

    /*makeSchoolInfoObject = (eduInstitution) => {
        return {
            [Msg.schoolNameLabelShort]: eduInstitution.name,
            [Msg.schoolTypeLabelShort]: ApiEngine.nameOfSchoolType(eduInstitution.type),
            [Msg.schoolSchoolSizeLabelShort]: ApiEngine.nameOfSchoolSize(eduInstitution.schoolSize),
            [Msg.schoolAddressLabelShort]: (
                <>
                    {eduInstitution.street} {eduInstitution.houseNumber}<br/>
                    {eduInstitution.postalCode} {eduInstitution.city}<br/>
                </>
            ),
            [Msg.schoolVoivodeshipLabelShort]: ApiEngine.nameOfVoivodeship(eduInstitution.voivodeship),
            [Msg.schoolSecretariatShort]: (
                <>
                    <table className="table table-sm table-borderless" style={{marginBottom: 0}}>
                        <tbody>
                        <tr>
                            <th className="align-left" style={{textAlign: 'left'}}><img src={phoneIcon} alt="Tel:"/></th>
                            <td style={{width: '100%'}}>{eduInstitution.secPhone}</td>
                        </tr>
                        <tr>
                            <th className="align-left" style={{textAlign: 'left'}}><img src={emailIcon} alt="Email:"/></th>
                            <td style={{width: '100%'}}>{eduInstitution.secEmail}</td>
                        </tr>
                        </tbody>
                    </table>
                </>
            ),
            [Msg.schoolNipLabelShort]: eduInstitution.nip,
        }
    }

    makeUserInfoObject = schoolUser => {
        return {
            [Msg.userFirstName]: schoolUser.firstname,
            [Msg.userLastName]: schoolUser.lastname,
            [Msg.userRole]: ApiEngine.nameOfUserRoles(schoolUser.userRole),
            [Msg.userEmailShort]: schoolUser.email,
            [Msg.userPhoneShort]: schoolUser.phone,
            [Msg.userLogin]: schoolUser.login
        }
    }*/


    handleRegister = e => {
        e.preventDefault();
        const school = this.state.school;
        const user = this.state.user;
        this.setState({
            submittingStage: 1 //submitting in progress
        });
        // eduInstitution.registrationUser = schoolUser;
        ApiEngine.postOpen(ApiUrl.API.OPEN.REGISTER, JSON.stringify(user.toSend(school)),
            /**
             * @param {ValidationResult} validationResult
             */
            (validationResult) => {
                this.setState({
                    validationResult: validationResult.withMessagesFromApiTranslated(Msg.get().registration),
                    submittingStage: 2 //submitted
                })
            },
            /**
             * @param {ValidationResult} validationResult
             */
            (validationResult) => {
                this.setState({
                    validationResult: validationResult.withMessagesFromApiTranslated(Msg.get().registration),
                    submittingStage: 2 //submitted
                })
            })
    }

    handleActivation = code => {
        const activation = this.activation;
        if (!activation[code]) {
            this.activation[code] = true;
            console.log('Aktywacja: ' + code);
            ApiEngine.postOpen(ApiUrl.API.OPEN.REGISTER_ACTIVATION, code, /**
                 * @param {ValidationResult} validationResult
                 */
                (validationResult) => {
                    console.log('SUCCESS');
                    const returnedActivationStatus = validationResult.isValid() ? 1 : 2;

                    this.setState({
                        validationResult: validationResult.withMessagesFromApiTranslated(Msg.get().activation),
                        activationStatus: returnedActivationStatus //success or error
                    })
                },
                /**
                 * @param {ValidationResult} validationResult
                 */
                (validationResult) => {

                    this.setState({
                        validationResult: validationResult.withMessagesFromApiTranslated(Msg.get().activation),
                        activationStatus: 2 //error
                    })
                }
            );
        } else {
            console.log('Zablokowane powtórne wysłanie żądania aktywacyjnego');
        }
    }


    render() {
        const code = this.props.match && this.props.match.params ? this.props.match.params.code : undefined;

        if (!code) {
            const {school, user} = this.state;
            let formToDisplay;
            const formSectionStyle = {paddingBottom: 0, paddingTop: 0};
            switch (this.state.formStep) {
                case 1:
                    formToDisplay = this.formStep1(formSectionStyle);
                    break;
                case 2:
                    formToDisplay = this.formStep2(formSectionStyle);
                    break;
                case 3:
                    formToDisplay = this.formStep3(formSectionStyle, school.getInfoObject(), user.getInfoObject());
                    break;
                default:
                    break;
            }
            return (
                <>
                    <ScrollToTopOnMount/>
                    <TitleBanner text="REJESTRACJA"/>
                    {formToDisplay}
                </>
            );
        } else {
            this.handleActivation(code);
            let messageToDisplay;
            switch (this.state.activationStatus) {
                case 0: // aktywacja trwa
                    messageToDisplay = (
                        <>
                            <img alt="loading..." src={preloader39}/>
                            <h4>&nbsp;</h4>
                            <h4 style={{color: 'green', textAlign: 'center'}}>Proszę czekać</h4>
                            <h4 style={{color: 'green', textAlign: 'center'}}>Trwa przetwarzanie</h4>
                        </>
                    )
                    break;
                case 1: // aktywacja zakończona sukcesem
                    messageToDisplay = (
                        <>
                            <h4 style={{color: 'green', textAlign: 'center'}}>Dziękujemy, konto zostało aktywowane</h4>
                            <h4 style={{color: 'green', textAlign: 'center'}}>Życzymy owocnej pracy z systemem :)</h4>
                        </>
                    )
                    break;
                case 2: // aktywacja nieudana/błędy
                    const errorsHTML = this.state.validationResult.getErrorsHTMLForAllKeys();
                    messageToDisplay = (
                        <>
                            <h6 style={{color: 'red', textAlign: 'center'}}>Konto nie mogło zostać aktywowane</h6>
                            <h6>{errorsHTML}</h6>
                            {/*<GreenButton label={Msg.of().menu.register.label} internalLink={Msg.of().menu.register.link}/>*/}
                        </>
                    )
                    break;
                default:
                    break;
            }
            return (
                <>
                    <ScrollToTopOnMount/>
                    <TitleBanner text="AKTYWACJA KONTA"/>
                    <section className="mbr-section article content1 cid-rz87GNwyCo" id="content1-2c">
                        <div className="container">
                            <div className="media-container-row">
                                <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12 align-center">
                                    {messageToDisplay}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );

        }

    };
}

export default PageRegister;