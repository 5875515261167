import Action from "../../Action";
import ProfileState from "../../states/ProfileState";
import ApiEngine from "../../../logic/ApiEngine";
import ApiUrl from "../../../logic/ApiUrl";

class LogoutAction extends Action {
    static TYPE = "LogoutAction";

    constructor(payload) {
        super(LogoutAction.TYPE, payload,
            /**
             *
             * @param {ProfileState} prevState
             * @return {Object}
             */
            prevState => {
                return ProfileState.get(prevState.initialDataLoaded, null, false, null, null, prevState.activeMenuItem, prevState.admin);
            });
    }

    static get = () => {
        return new LogoutAction();
    }

    /**
     * @public
     * @static
     * @method
     * @description Wysłanie żądania wylogowania użytkownika
     * @param dispatch metoda dispatch Reduxa
     * @param funcSuccess funkcja wykonywana, jeśli wylogowanie przebiegło prawidłowo
     * @param funcError funkcja wykonywana, jeśli wylogowanie zakończyło się błędem
     */
    static run = (dispatch, funcSuccess, funcError) => {
        ApiEngine.token = '';
        ApiEngine.getSecured(ApiUrl.API.SECURED.USER.PROFILE.LOGOUT, null, () => {
            dispatch(LogoutAction.get().plain());
            funcSuccess();
        }, funcError, null);
    }

    static sessionExpired = (dispatch) => {
        if(dispatch) {
            dispatch(LogoutAction.get().plain());
        }
    }
}

export default LogoutAction;