import React, {Component} from 'react';
import jCaptcha from 'js-captcha';
import ApiEngine from "../../logic/ApiEngine";

class Captcha extends Component {
    state = {}

    render() {
        let mess = ApiEngine.messages;
        return (
            <>
                <label htmlFor="kpcz" style={{textAlign: 'justify'}}>
                    <small>
                        {mess.CaptchaInfo}
                    </small>
                </label>
                <div style={{width: '100%'}}>
                    <input id="kpcz" name="kpcz" className="jCaptcha form-control display-7 col-auto" type="text"
                           placeholder={mess.CaptchaPlaceholder}
                           style={{padding: '5px', minHeight: '30px', fontSize: '100%', display: 'inline-block', minWidth: '300px'}}
                    />

                    {/*<input className="jCaptcha" type="text" placeholder="Type in result please"/>*/}
                </div>
            </>
        );
    };
}

export default Captcha;