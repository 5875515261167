import Action from "../../Action";
import ProfileState from "../../states/ProfileState";

class ShowPreloaderAction extends Action {
    static TYPE = "ShowPreloaderAction";

    constructor(payload) {
        super(ShowPreloaderAction.TYPE, payload,
            /**
             *
             * @param {ProfileState} prevState
             * @return {Object}
             */
            prevState => {
                let newState = ProfileState.get(prevState.initialDataLoaded, prevState.token, prevState.authenticated, prevState.schoolUser, prevState.eduInstitution, prevState.activeMenuItem);
                newState.showPreloader = payload.show;
                return newState;
            });
    }

    static get = show => {
        return new ShowPreloaderAction({show});
    }

    /**
     * @public
     * @static
     * @method
     * @description Wysłanie żądania wylogowania użytkownika
     * @param {function({payload: ?Object, type: !string})} dispatch metoda dispatch Reduxa
     * @param {boolean} show flaga określająca czy strona z preloaderem ma być wyświetlana czy nie
     */
    static run = (dispatch, show) => {
        console.log('Show preloader: ' + show);
        dispatch(ShowPreloaderAction.get(show).plain());
    }
}

export default ShowPreloaderAction;