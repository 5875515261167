import React, {Component} from 'react';
import preloader39 from "../../img/preloader39.svg";
import PropTypes from "prop-types";
import TitleBanner from "../website/TitleBanner";

class Preloader extends Component {

    state = {
        timeoutOccurred: false
    }

    timeoutHandler = null;

    updateTimeout = () => {
        if(this.props.timeout && this.props.showPreloader) {
            if(this.timeoutHandler) {
                clearTimeout(this.timeoutHandler);
            }
            this.timeoutHandler = setTimeout(() => {
                this.timeoutHandler = null;
                this.setState({timeoutOccurred: true});
            }, this.props.timeout * 1000);
        }
        if(!this.props.showPreloader && this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
        }
    }

    componentDidMount() {
        this.updateTimeout();
    }

    componentDidUpdate() {
        this.updateTimeout();
    }

    componentWillUnmount() {
        if(this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
        }
    }


    render() {
        const props = this.props;
        let titleBanner = null;

        if(props.titleBanner) {
            titleBanner = (
                <TitleBanner text={props.titleBanner} small={true}/>
            );
        }


        const contentBeforeTimeoutOccurred = (
            <>
                <div className="row">
                    <div className="col align-center">
                        <img src={preloader39} alt="Loading..."/>
                    </div>
                </div>
                <div className="row">
                    <div className="col align-center" style={{padding: "1rem"}}>
                        <h4 className="text-info">{props.text}</h4>
                    </div>
                </div>
            </>
        );

        const contentAfterTimeoutOccurred = (
            <>
                <div className="row">
                    <div className="col align-center" style={{padding: "1rem"}}>
                        <h4 style={{color: "red"}}><strong>{props.timeoutText}</strong></h4>
                    </div>
                </div>
            </>
        );

        const contentOfPreloader = this.state.timeoutOccurred? contentAfterTimeoutOccurred : contentBeforeTimeoutOccurred;

        const preloaderDIV = (
            <>
                {titleBanner}
                <div className="container" style={{minHeight: '31rem', paddingTop: '5rem', marginBottom: 0}}>
                    <div className="row align-items-center" >
                        <div className="col">
                            {contentOfPreloader}
                        </div>
                    </div>
                </div>
            </>
        );

        let content;
        if (props.showPreloader) {
            content = preloaderDIV;
        } else {
            content = props.children;
        }

        return (
            <>
                {content}
            </>
        );
    }
}



Preloader.propTypes = {
    showPreloader: PropTypes.bool.isRequired,
    titleBanner: PropTypes.string,
    text: PropTypes.string,
    timeout: PropTypes.number,
    timeoutText: PropTypes.string
};

Preloader.defaultProps = {
    text: 'Trwa przetwarzanie...',
    timeoutText: 'Przepraszamy. Serwer jest konserwowany i chwilowo nie odpowiada. Prosimy spróbować później',
    timeout: 90,
}

export default Preloader;