import React from 'react';
import PropTypes from 'prop-types';

export const Row = (props) => {
    let style;
    if(!props.style) {
        style = {}
    } else {
        style = props.style;
    }
    return (
        <>
            <div className="row justify-content-center" style={style}>
                {props.children}
            </div>
        </>
    );
};

Row.propTypes = {
    style: PropTypes.object
}

export default Row;

