import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import TitleBanner from "../../components/website/TitleBanner";
import ValidationResult from "../../logic/ValidationResult";
import ApiEngine from "../../logic/ApiEngine";
import ApiUrl from "../../logic/ApiUrl";
import Field from "../../form/components/Field";
import SchoolForm from "../../form/SchoolForm";
import Msg from "../../i18n/Msg";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import ScrollToTopOnMount from "../../components/helpers/ScrollToTopOnMount";
import InfoTable from "../../components/visual/InfoTable";
import Subpage from "../../components/website/Subpage";
import Row from "../../components/website/Row";
import Col from "../../components/website/Col";

class PageSchoolProfile extends Component {

    state = {
        school: {},
        user: {},
        validationResult: ValidationResult.get()
    }

    /**
     * @param {object} e zdarzenie
     * @param {string} objectName nazwa obiektu w state
     */
    handleStateObject = (e, objectName) => {
        e.persist();
        this.setState(prevState => {
            const fromEvent = Field.readFromEvent(e);
            const newState = prevState;
            newState[objectName][fromEvent.fieldName] = fromEvent.fieldValue;
            return newState;
        })
    }

    /**
     * @description funkcja generująca funkcje handle dla formularza
     * @param objectName
     * @return {function}
     */
    handleGenerator = objectName => {
        const handleFunc = e => {
            this.handleStateObject(e, objectName);
        }
        return handleFunc;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem, Msg.of().menu.schoolProfile.label);
    }


    render() {
        const profileState = State.getProfileState(this.props);
        if (profileState.isLogged()) {
            return (
                <Subpage title={"Profil szkoły"} small={true}>
                    <Row>
                        <Col style={{minHeight: '31rem'}}>
                            <InfoTable header={Msg.of().common.school} content={profileState.eduInstitution.getInfoObject(profileState.schoolUser.email)} width="100%"/>
                        </Col>
                    </Row>
                </Subpage>
            );
            {/*<ScrollToTopOnMount/>
                    <TitleBanner text="Profil szkoły" small={true}/>
                    <section className="mbr-section article content1 cid-rz87GNwyCo">
                    <InfoTable header={Msg.of().common.eduInstitution} content={profileState.eduInstitution.getInfoObject(profileState.schoolUser.email)}/>
                    </section>*/
            }
            {/*{SchoolForm.get(profileState.eduInstitution, this.generatorOfHandleSurveyParamsChangeFunction('eduInstitution'), this.state.validationResult)}*/
            }
        } else {
            return <PageLoginUser/>
        }
    }
}


const PageSchoolProfileConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageSchoolProfile)

PageSchoolProfile.propTypes = {};

export default PageSchoolProfileConnectedToRedux;