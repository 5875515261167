import React from 'react';
import Utils from "../../../logic/Utils";
import PropTypes from 'prop-types';
import ResponsiveRatio from "../ResponsiveRatio";

const Select = props => {
    let {values, name, ratio, label, marginBottom, onChangeFunc, fieldValue} = props;
    const selectID = Utils.escapeDiacriticsAndSpaces(label+name);
    let options = [];
    for (let [value, label] of Object.entries(values)) {
        let newOption = (
            <option value={value} key={value}>{label}</option>
        );
        options = [...options, newOption];
    }
    return (
        <>
            <div className="row align-items-center" style={{"marginBottom": marginBottom}}>
                <div className={ratio.getLabelClasses()} style={{marginTop: '0.6rem'}}>
                    <p className="h6">{label}</p>
                </div>
                <div className={ratio.getControlClasses()}>
                    <select className="custom-select" id={selectID} style={{minHeight: "2.5rem", paddingTop: '0px', paddingBottom: '0px'}}
                            onChange={onChangeFunc} name={name} value={String(fieldValue)}>
                        {options}
                    </select>
                </div>
            </div>

        </>
    );
};

Select.propTypes = {
    values: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    ratio: PropTypes.shape(ResponsiveRatio.shape()).isRequired,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    onChangeFunc: PropTypes.func.isRequired,
    fieldValue: PropTypes.string.isRequired
}

Select.defaultProps = {
    ratio: ResponsiveRatio.default()
}

export default Select;