import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../logic/Logger";

export const Section = (props) => {
    let style = {};
    if(props.padding) {
        style = {padding: props.padding}
    }
    return (
        <>
            <section className="mbr-section form1 cid-rz85Klm51j" id="form1-2a" style={style}>
                <div className="container">
                    {props.children}
                </div>
            </section>
        </>
    );
};

Section.propTypes = {
    padding: PropTypes.string
}

export default Section;

