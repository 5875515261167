import React from 'react';
import PropTypes from 'prop-types';

const BigHeader = props => {
    const style = {lineHeight: '3rem'}
    const styleWhole = {}
    if(props.bg) {
        style.backgroundColor = props.bg;
        styleWhole.backgroundColor = props.bg;
    }
    if(props.padding) {
        styleWhole.padding = props.padding;
    }
    return (
        <>
            <section className="mbr-section content4 cid-rz6iCdPWkO" id="content4-l" style={styleWhole}>
                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-12">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2" style={style}>
                                {props.children}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

BigHeader.propTypes = {
    bg: PropTypes.string,
    padding: PropTypes.string
}

export default BigHeader;