import React from 'react';

const SectionHeader = props => {
    return (
        <>
            <h4 style={{margin: "2rem 0"}} className="text-primary font-weight-bold">{props.text}</h4>
        </>
    );
};

export default SectionHeader;