import ApiUrl from "./ApiUrl";
import Msg_pl from "../i18n/Msg_pl";
import DictionariesFetcher from "./DictionariesFetcher";
import ValidationResult from "./ValidationResult";
import User from "../model/User";
import School from "../model/School";
import $ from 'jquery';
import Survey from "../model/Survey";
import State from "../store/State";
import ApiCache from "./ApiCache";
import ApiCacheElement from "./ApiCacheElement";
import Logger from "./Logger";
import SurveyParams from "../model/SurveyParams";
import SchoolUser from "../model/SchoolUser";
import EduInstitution from "../model/EduInstitution";
import StatisticData from "../model/StatisticData";
import IntStringMapEntry from "../model/IntStringMapEntry";
import LogoutAction from "../store/actions/profile/LogoutAction";

class ApiEngine {

    /**
     * @typedef AppState
     * @type {object}
     * @property {!boolean} [initialDataLoaded] - flaga określająca, czy ApiEngine został zainicjowany poprawnie, np czy słowniki zostały pobrane
     * @property {?string} [token] - token autoryzacyjny JWT
     * @property {!boolean} [authenticated] - flaga określająca, czy użytkownik jest zalogowany
     */

    /**
     * @typedef ResponseForRequest
     * @type {object}
     * @property {string[]} [oks]
     * @property {string[]} [warnings]
     * @property {string[]} [errors]
     * @property {Object.<string, *>} [data]
     * @property {string} [token]
     * @property {boolean} [valid]
     */

    /**
     * @callback callbackFunction
     */

    /*
     * Dane statyczne
     */

    /**
     * @static
     * @description Słownik adresów API
     * @type {ApiUrl}
     */
    static URL = new ApiUrl();

    /**
     * @static
     * @description token autoryzacyjny do requestów secured
     * @type {string}
     */
    static token;


    /**
     * @static
     * @description Redux Store
     * @type {object}
     */
    static STORE;

    /**
     * @static
     * @description Słownik komunikatów wielojęzycznych
     * @type {Msg_pl}
     */
    static messages = new Msg_pl();

    /**
     * @public
     * @static
     * @description Metoda REDUXa "dispatch" wstrzykiwana do ApiEngine w tym celu, żeby ApiEngine mógł uruchomić akcję sessionTimeout w requestSecured
     */
    static dispatchMethodForSessionTimeout;

    /*
     * Słowniki pobierane z bazy danych
     */
    static SCHOOL_TYPES = {};
    static SCHOOL_SIZES = {};
    static USER_ROLES = {};
    static VOIVODESHIPS = {};

    static nameOfSchoolType = code => {
        return ApiEngine.SCHOOL_TYPES[code];
    }

    static nameOfSchoolSize = code => {
        return ApiEngine.SCHOOL_SIZES[code];
    }

    static nameOfUserRoles = code => {
        return ApiEngine.USER_ROLES[code];
    }

    static nameOfVoivodeship = code => {
        return ApiEngine.VOIVODESHIPS[code];
    }

    /**
     * @static
     * @function refreshStateFunc
     * @description Funkcja musi być wywołana przy każdej zmianie stanu
     * @type {function}
     */
    static refreshStateFunc;

    /*/!**
     * @static
     * @description Stan globalny aplikacji
     * @type {AppState}
     *!/
    static state = {
        API_ENGINE_READY: false,
        token: null,
        authenticated: false,
    }*/

    /*/!**
     * @static
     * @function
     * @description Funkcja zmieniająca stan. Stan należy zmieniać tylko i wyłącznie przez tę funkcję, tak jak w React.js, chociaż akurat ta funkcja nie jest częścią reacta, ale wywołuje funkcję reacta setState,
     * znajdującą się w klasie Site, poprzez pośrednika, którym jest funkcja refreshStateFunc
     * @param {AppState} newState
     *!/
    static setState = (newState) => {
        Object.assign(ApiEngine.state, newState);
        ApiEngine.refreshStateFunc();
    }*/

    /*/!**
     * @static
     * @function
     * @description Ustawia flagę gotowości dla ApiEngine
     * @param {boolean} ready
     *!/
    static setApiEngineReady = (ready) => {
        ApiEngine.setState({
            initialDataLoaded: ready
        });
    }*/

    /*/!**
     * @static
     * @function
     * @description Funkcja wywoływana z zewnątrz, z klasy Site. Powoduje zainicjowanie ApiEngine
     * @param {callbackFunction} refreshStateFunc Jest to funkcja, która jest wywoływana przy każdej zmianie stanu i powinna wywoływać funkcję setState z React
     *!/
    static init = (refreshStateFunc) => {
        ApiEngine.refreshStateFunc = refreshStateFunc;
        DictionariesFetcher.fetchAllDictionaries();
    }*/

    /**
     * @static
     * @method
     * @description
     * @param {function(Action)} dispatch
     */
    static init = dispatch => {
        DictionariesFetcher.fetchAllDictionaries(dispatch)
    }

    /**
     * @static
     * @method
     * @description
     * @param {function(Action)} dispatch
     */
    static reInitAfterInitError = dispatch => {
        DictionariesFetcher.fetchAllDictionariesIfErrorOccurred(dispatch);
    }

    /**
     * Czy aplikacja jest uruchomiona w trybie produkcyjnym
     * @return {boolean}
     */
    static isProductionMode() {
        return process.env.NODE_ENV === 'production'
    }

    /**
     * Czy aplikacja jest uruchomiona w trybie deweloperskim
     * @return {boolean}
     */
    static isDevMode() {
        return !ApiEngine.isProductionMode();
    }

    /*/!**
     * @static
     * @function
     * @description Funkcja wywoływana po zainicjowaniu ostatniego słownika w klasie DictionariesFetcher w celu odświeżenia stanu po zakończeniu pobierania wszystkich słowników
     *!/
    static afterInitAction = () => {
        ApiEngine.setApiEngineReady(true);
    }*/

    /*testJquery() {
        let jQuery = window.jQuery;
        if (typeof jQuery != 'undefined') {
            // jQuery is loaded => print the version
            alert("jQuery działa w wersji: " + jQuery.fn.jquery);
        } else {
            alert('jQuery nie działa');
        }
    }*/


    /**
     * @static
     * @function
     * @description Wywołanie requesta metodą POST, niewymagającego autoryzacji
     * @param {string} url Adres url, pod który wysyłane jest żądanie
     * @param {object} formData Obiekt BODY, parametry żądania
     * @param {function} funcSuccess Funkcja wywoływana, kiedy przyjdzie response z sukcesem
     * @param {function} funcError Funkcja wywoływana, jeśli wystąpi błąd żądania
     */
    static postOpen = (url, formData, funcSuccess, funcError) => {

        /*jQuery.post(url, formData, funcSuccess, "json").fail(funcError).then(r => {
            console.log(`Request to ${url} failed`)
        });*/


        const dataToSend = typeof formData === 'string' ? formData : JSON.stringify(formData)

        $.ajax({
            url: url,
            type: "POST",
            //headers: {"Access-Control-Allow-Origin": "*"},
            // data: JSON.stringify(formData),x
            data: dataToSend,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: response => {
                const validationResult = ValidationResult.buildFromResponse(response);
                funcSuccess(validationResult);
            },
            error: response => {
                const validationResult = ValidationResult.buildFromResponse(response);
                funcError(validationResult);
            }
        })
    }

    /**
     * @static
     * @function
     * @description wysyła formularz kontaktowy na specjalny adres inny niż API
     * @param {string} from
     * @param {string} email
     * @param {string} phone
     * @param {string} subject
     * @param {string} message
     * @param {function} funcSuccess
     * @param {function} funcError
     */
    static postContactFormMessage = (from, email, phone, subject, message, funcSuccess, funcError) => {
        const formData = {
            from, email, phone, subject, message, recipients: ['info@surneo.pl', 'lt@i-pi.pl']
        }
        ApiEngine.postOpen(ApiUrl.API.OPEN.CONTACT.FORM, formData, funcSuccess, () => {
            ApiEngine.postContactForm(from, email, phone, subject, message, funcSuccess, funcError);
        });
    }

    /**
     * @static
     * @function
     * @description wysyła formularz kontaktowy na specjalny adres inny niż API
     * @param {string} from
     * @param {string} email
     * @param {string} phone
     * @param {string} subject
     * @param {string} message
     * @param {function} funcSuccess
     * @param {function} funcError
     */
    static postContactForm = (from, email, phone, subject, message, funcSuccess, funcError) => {
        const formData = {
            from, email, phone, subject, message, recipients: ['info@surneo.pl', 'd.cholost@kbpretendent.eu']
        }
        console.log(formData);
        $.ajax({
            url: ApiUrl.CONTACT_FORM_URL,
            type: "POST",
            data: formData,
            contentType: "application/x-www-form-urlencoded; charset=utf-8",
            dataType: "json",
            success: response => {
                if (funcSuccess) {
                    funcSuccess(response);
                } else {
                    console.log('SUCCESS');
                    console.log(response);
                }
            },
            error: response => {
                if (funcError) {
                    funcError(response);
                } else {
                    console.log('ERROR');
                    console.log(response);
                }
            }
        })
    }


    /**
     * @private
     * @param {object} obj
     * @return {string}
     */
    static objectToUrlParams = obj => {
        let str = "";
        for (const key in obj) {
            if(obj.hasOwnProperty(key)) {
                if (str !== "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(obj[key]);
            }
        }
        return str;
    }

    /**
     * @private
     * @param {string} url
     * @param {object} formData
     * @return {string}
     */
    static requestURL_GET(url, formData) {
        return url + "?" + ApiEngine.objectToUrlParams(formData);
    }

    /**
     * @static
     * @function
     * @description Wywołanie requesta metodą GET, niewymagającego autoryzacji
     * @param {string} url Adres url, pod który wysyłane jest żądanie, powinien zawierać również parametry
     * @param {callbackFunction} funcSuccess Funkcja wywoływana, kiedy przyjdzie response z sukcesem
     * @param {callbackFunction} [funcError] Funkcja wywoływana, jeśli wystąpi błąd żądania
     */
    static getOpen = (url, funcSuccess, funcError) => {
        $.get(url, response => {
            const validationResult = ValidationResult.buildFromResponse(response);
            funcSuccess(validationResult);
        }, "json").fail(funcError);
    }


    /**
     * @private
     * @static
     * @function
     * @description Wywołanie requesta wymagającego autoryzacji. Jest to funkcja ogólna, dla której metoda requesta podana jest parametrem. Nie powinna być wywoływana z zewnątrz.
     * Należy ją traktować jako prywatną w tej klasie
     * @param {string} url Adres url, pod który wysyłane jest żądanie
     * @param {string} httpMethod Metoda HTTP dla żądania, np: 'post', 'get' itd.
     * @param {object} formData Obiekt BODY, parametry żądania
     * @param {function(ValidationResult)} funcSuccess Funkcja wywoływana, kiedy przyjdzie response z sukcesem
     * @param {function} funcError Funkcja wywoływana, jeśli wystąpi błąd żądania
     * @param {callbackFunction} callbackFunc Funkcja wstrzykiwana jako parametr do wywołania funcSuccess, dzięki czemu funcSuccess można zaimplementować w ApiEngine, a
     * callbackFunc przyjąć z zewnątrz jako argument i wywołać ją wewnątrz funcSuccess
     * @param {string} [token] opcjonalny token autoryzacyjny. Jeśli nie jest podany, wówczas token odczytywany jest z Redux Store
     */
    static requestSecured = (url, httpMethod, formData, funcSuccess, funcError, callbackFunc, token) => {
        if (!token) {
            const profileState = State.getProfileStateFromStore();
            token = profileState.token;
        }
        if (token) {
            const needWaiting = ApiCache.needWaiting(url, formData);
            let waitingTime = 1;
            if (needWaiting) {
                waitingTime = 3000;
                Logger.debug('waiting for request...');
            }
            setTimeout(() => {
                const apiCacheElement = ApiCache.get(url, formData);
                if (apiCacheElement) {
                    Logger.debug('REQUEST FROM CACHE: ' + ApiCache.key(url, formData));
                    ApiEngine.requestSecuredFuncSuccess(apiCacheElement.response, funcSuccess, funcError, callbackFunc);
                } else {
                    ApiCache.waitWith(url, formData);
                    Logger.debug('REQUEST SECURED: ' + ApiCache.key(url, formData) + " CACHE: " + (ApiCache.CACHE_ON ? 'ON' : 'OFF'));
                    let dataStr;
                    if(httpMethod === 'get') {
                        dataStr = null;
                    } else {
                        dataStr = JSON.stringify(formData);
                    }
                    $.ajax({
                        url: url,
                        type: httpMethod,
                        data: dataStr,
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        dataType: 'json',
                        contentType: "application/json; charset=utf-8",
                        success: response => {
                            if(httpMethod === 'get') {
                                ApiCache.put(url, formData, response);
                            }
                            ApiEngine.requestSecuredFuncSuccess(response, funcSuccess, funcError, callbackFunc);
                            ApiCache.releaseWaiting(url, formData);
                        },
                        error: response => {
                            if(response && response.status && response.status === 401) {
                                LogoutAction.sessionExpired(ApiEngine.dispatchMethodForSessionTimeout);
                            }
                            if (funcError) {
                                funcError(response);
                            } else {
                                Logger.error('Request error:');
                                Logger.error(response);
                            }
                        }
                    });
                }

            }, waitingTime);

        }
    }

    /**
     * @static
     * @private
     * @method
     * @param response
     * @param funcSuccess
     * @param funcError
     * @param callbackFunc Funkcja wstrzykiwana jako parametr do wywołania funcSuccess, dzięki czemu funcSuccess można zaimplementować w ApiEngine, a
     * callbackFunc przyjąć z zewnątrz jako argument i wywołać ją wewnątrz funcSuccess
     */
    static requestSecuredFuncSuccess = (response, funcSuccess, funcError, callbackFunc) => {
        const validationResult = ValidationResult.buildFromResponse(response);
        if (validationResult.isValid()) {
            if (funcSuccess) {
                funcSuccess(validationResult, callbackFunc);
            } else {
                Logger.info('Request result:');
                Logger.info(validationResult);
            }
        } else {
            validationResult.errors.forEach(error => {
                Logger.error(error.text)
            })
            if (funcError) {
                funcError(response);
            } else {
                Logger.error('Request error:');
                Logger.error(response);
            }
        }
    }

    /**
     * @static
     * @function
     * @description Wysłanie żądania wymagającego autoryzacji pod wskazany adres URL przy pomocy metody POST
     * @param {string} url Adres url, pod który wysyłane jest żądanie
     * @param {object} formData Obiekt BODY, parametry żądania
     * @param {callbackFunction} funcSuccess Funkcja wywoływana, kiedy przyjdzie response z sukcesem
     * @param {callbackFunction} funcError Funkcja wywoływana, jeśli wystąpi błąd żądania
     * @param {callbackFunction} [callbackFunc] Funkcja wstrzykiwana jako parametr do wywołania funcSuccess, dzięki czemu funcSuccess można zaimplementować w ApiEngine, a
     * callbackFunc przyjąć z zewnątrz jako argument i wywołać ją wewnątrz funcSuccess
     * @param {string} [token] opcjonalny token autoryzacyjny. Jeśli nie jest podany, wówczas token odczytywany jest z Redux Store
     */
    static postSecured = (url, formData, funcSuccess, funcError, callbackFunc = () => {
    }, token) => {
        ApiEngine.requestSecured(url, 'post', formData, funcSuccess, funcError, callbackFunc, token);
    }

    /**
     * @static
     * @function
     * @description Wysłanie żądania wymagającego autoryzacji pod wskazany adres URL przy pomocy metody GET
     * @param {string} url Adres url, pod który wysyłane jest żądanie
     * @param {object} formData Obiekt BODY, parametry żądania
     * @param {callbackFunction} funcSuccess Funkcja wywoływana, kiedy przyjdzie response z sukcesem
     * @param {callbackFunction} funcError Funkcja wywoływana, jeśli wystąpi błąd żądania
     * @param {callbackFunction} callbackFunc Funkcja wstrzykiwana jako parametr do wywołania funcSuccess, dzięki czemu funcSuccess można zaimplementować w ApiEngine, a
     * callbackFunc przyjąć z zewnątrz jako argument i wywołać ją wewnątrz funcSuccess
     * @param {string} [token] opcjonalny token autoryzacyjny. Jeśli nie jest podany, wówczas token odczytywany jest z Redux Store
     */
    static getSecured = (url, formData, funcSuccess, funcError, callbackFunc, token) => {
        if(formData && formData!== {} && Object.keys(formData).length > 0) {
            let isNotEmpty = false;
            Object.keys(formData).forEach(key =>  {
                if(key && key.trim().length > 0) {
                    isNotEmpty = true;
                }
            });
            if(isNotEmpty) {
                url = url + "?" + ApiEngine.objectToUrlParams(formData);
            } else {
            }
        }
        Logger.debug("GET SECURED URL: " + url)
        ApiEngine.requestSecured(url, 'get', {}, funcSuccess, funcError, callbackFunc, token);
    }

    static loadUrlTo = (url, toID) => {
        $('#' + toID).load(url);
    }

    /**
     * @description pobiera profil zalogowany, tj. obiekty schoolUser i eduInstitution
     * @param {function(SchoolUser, EduInstitution)} callbackFunc
     * @param {function} funcError funcError
     * @param {string} [token] opcjonalny token autoryzacyjny. Jeśli nie jest podany, odczytywany jest z Redux Stor
     */
    static loadProfile = (callbackFunc, funcError, token) => {
        ApiEngine.getSecured(ApiUrl.API.SECURED.USER.PROFILE.GET, {}, (validationResult, callbackFunc) => {
            const schoolUser = SchoolUser.fromObject(validationResult.data.user);
            const eduInstitution = EduInstitution.fromObject(validationResult.data.school);
            /*const resUser = validationResult.data.user;
            const user = User.get(resUser.firstname, resUser.lastname, resUser.email, resUser.phone,
                resUser.rodoAccepted, resUser.login, null, resUser.headOfEvaluationTeam, resUser.activationTime, resUser.userRole, resUser.id);
            const schoolDTO = resUser.schoolDTO;
            const {name, schoolType, nip, street, houseNumber, city, postalCode, secEmail, secPhone, schoolSize, voivodeship, id} = schoolDTO;
            const eduInstitution = School.get(name, schoolType, nip, street, houseNumber, city, postalCode, secEmail, secPhone, schoolSize, voivodeship, id);
            callbackFunc(user, eduInstitution);*/
            callbackFunc(schoolUser, eduInstitution);
        }, funcError, callbackFunc, token);
    }

    /*    /!**
         *
         * @param {function(User[])} callbackFunc
         * @param funcError
         *!/
        static usersList3 = (callbackFunc, funcError) => {
            ApiEngine.getSecured(ApiUrl.API.SECURED.USER.LIST, {}, response => {
                const validationResult = ValidationResult.buildFromResponse(response);
                const usersFromResponse = validationResult.data.users;
                const users = [];
                let index = 0;
                usersFromResponse.map(schoolUser => {
                    users[index++] = User.get(schoolUser.firstname, schoolUser.lastname, schoolUser.email, schoolUser.phone, schoolUser.rodoAccepted,
                        schoolUser.login, null, schoolUser.headOfEvaluationTeam, schoolUser.activationTime, schoolUser.userRole, schoolUser.id);
                })
                callbackFunc(users);
            }, funcError);
        }*/

    static getList(url, listNameInResponse, mapFunction, callbackFunc, funcError, formData) {
        if (!formData) {
            formData = {};
        }
        ApiEngine.getSecured(url, formData, (validationResult, callbackFunc) => {
            const collectionFromResponse = validationResult.data[listNameInResponse];
            const result = [];
            let index = 0;
            collectionFromResponse.map(objFromResponse => {
                result[index++] = mapFunction(objFromResponse);
            });
            callbackFunc(result);
        }, funcError, callbackFunc);
    }

    static surveyListAll = (callbackFunc, funcError) => {
        ApiEngine.getList(ApiUrl.API.SECURED.SURVEY.LIST.ALL, 'surveys', surveyFromResponse => {
            const {surID, srwID, surName, srwNumber, rdbID, testLink, params} = surveyFromResponse;
            let surveyParams;
            if(params) {
                const {id, schoolID, hash, maxPupils, maxTeachers, maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink} = params;
                surveyParams = SurveyParams.get(id, srwID, schoolID, hash, maxPupils, maxTeachers,
                    maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink);
            } else {
                surveyParams = null;
            }
            return Survey.get(surID, srwID, surName, srwNumber, rdbID, testLink, surveyParams);
        }, callbackFunc, funcError, {});
    }

    /**
     *
     * @param {function(User[])} callbackFunc
     * @param funcError
     */
    static usersList = (callbackFunc, funcError) => {
        ApiEngine.getList(ApiUrl.API.SECURED.USER.LIST, 'users', userFromResponse => {
            return User.get(userFromResponse.firstname, userFromResponse.lastname, userFromResponse.email, userFromResponse.phone, userFromResponse.rodoAccepted,
                userFromResponse.login, null, userFromResponse.headOfEvaluationTeam, userFromResponse.activationTime, userFromResponse.userRole, userFromResponse.id);
        }, callbackFunc, funcError, {});
    }


    /**
     *
     * @param callbackFunc
     * @param funcError
     */
    static surveyParamsList = (callbackFunc, funcError) => {
        ApiEngine.getList(ApiUrl.API.SECURED.SURVEY.PARAMS.LIST, 'surveyParamsTab', surveyParamsFromResponse => {
            const {id, srwID, schoolID, hash, maxPupils, maxTeachers, maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink} = surveyParamsFromResponse
            return SurveyParams.get(id, srwID, schoolID, hash, maxPupils, maxTeachers, maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink);
        }, callbackFunc, funcError)
    }


    /**
     * @public
     * @static
     * @method
     * @param {SurveyParams} surveyParams
     * @param {function} funcSuccess
     * @param {function} funcError
     */
    static setSurveyParams = (surveyParams, funcSuccess, funcError) => {
        ApiEngine.postSecured(ApiUrl.API.SECURED.SURVEY.PARAMS.SET, surveyParams, /** @param {ValidationResult} validationResult*/(validationResult) => {
            const surveyParams = validationResult.data["surveyParams"];
            const savedSurveyParams = SurveyParams.get(surveyParams.id, surveyParams.srwID, surveyParams.schoolID, surveyParams.hash, surveyParams.maxPupils,
                surveyParams.maxTeachers, surveyParams.maxParents, surveyParams.cookiesBlockade, surveyParams.ipBlockade, surveyParams.tokensMode, surveyParams.startLink);
            funcSuccess(savedSurveyParams);
        }, funcError);
    }

    /**
     * @public
     * @static
     * @method
     * @param {number} srwID
     * @param {function} funcSuccess
     * @param {function} funcError
     */
    static setSurveyParamsDefault = (srwID, funcSuccess, funcError) => {
        ApiEngine.postSecured(ApiUrl.API.SECURED.SURVEY.PARAMS.SET_DEFAULT, srwID, validationResult => {
            const surveyParams = validationResult.data["surveyParams"];
            const savedSurveyParams = SurveyParams.get(surveyParams.id, surveyParams.srwID, surveyParams.schoolID, surveyParams.hash, surveyParams.maxPupils,
                surveyParams.maxTeachers, surveyParams.maxParents, surveyParams.cookiesBlockade, surveyParams.ipBlockade, surveyParams.tokensMode, surveyParams.startLink);
            funcSuccess(savedSurveyParams);
        }, funcError);
    }

    /**
     * @public
     * @method
     * @param {string} hash
     * @param {function} funcSuccess
     * @param {function} funcError
     */
    static getSchoolNameByHash = (hash, funcSuccess, funcError) => {
        ApiEngine.getOpen(ApiEngine.requestURL_GET(ApiUrl.API.OPEN.BY_HASH.SCHOOL_NAME_GET, {hash}), /** @param {ValidationResult} validationResult*/validationResult => {
            if(validationResult.isValid()) {
                funcSuccess(validationResult.data);
            } else {
                funcError(validationResult.errors);
            }
        }, funcError);
    }

    /**
     * @public
     * @method
     * @param {string} hash
     * @param {string} schoolRSPO
     * @param {string} email
     * @param {function} funcSuccess
     * @param {function} funcError
     */
    static joinSchoolHomeOfficeSurvey = (hash, schoolRSPO, email, funcSuccess, funcError) => {
        const formData = {hash, schoolRSPO, email};
        ApiEngine.postOpen(ApiUrl.API.OPEN.BY_HASH.REGISTER, formData, /** @param {ValidationResult} validationResult*/validationResult => {
            if(validationResult.isValid()) {
                funcSuccess();
            } else {
                funcError(validationResult);
            }
        }, funcError);
    }

    static getQuoteDone = (srwHash, funcSuccess, funcError) => {
        ApiEngine.getSecured(ApiUrl.API.SECURED.SURVEY.QUOTE.GET, {srwHash}, (validationResult, callbackFunc)=> {
            funcSuccess(validationResult.data);
        }, funcError, funcSuccess);
    }

    static startMailingRPPWCP = (mode) => {
        ApiEngine.postSecured(ApiUrl.API.SECURED.MAILING.INVITATION.REALIZACJA_PODSTAWY_PROGRAMOWEJ_W_CZASIE_PANDEMII, {mode},
            validationResult => {Logger.info("OK")}, validationResult => {Logger.info("ERROR")});
    }

    static loadEmailContent = (hash, funcSuccess) => {
        ApiEngine.getOpen(ApiEngine.requestURL_GET(ApiUrl.API.OPEN.MAILING.SUPPORT.CONTENT, {emailHash: hash}), funcSuccess, () => {})
    }

    static cancelMailing = (hash, funcSuccess) => {
        ApiEngine.postOpen(ApiUrl.API.OPEN.MAILING.SUPPORT.CANCEL, hash, funcSuccess, () => {})
    }

    static getStatisticData = (srwHash, funcSuccess, funcError) => {
        ApiEngine.getSecured(ApiUrl.API.SECURED.SURVEY.STATS.GET, {srwHash}, (validationResult, callbackFunc)=> {
            /**
             * @type {Object[]}
             */
            const statsTable = validationResult.data.stats;
            const statisticDataTable = statsTable.map(value => StatisticData.fromObject(value));
            funcSuccess(statisticDataTable);
        }, funcError, funcSuccess);
    }

    static getQuests = (srwHash, funcSuccess, funcError) => {
        ApiEngine.getSecured(ApiUrl.API.SECURED.SURVEY.STATS.QUESTS, {srwHash}, (validationResult, callbackFunc)=> {
            /**
             * @type {Object[]}
             */
            const statsTable = validationResult.data.quests;
            const table = statsTable.map(value => IntStringMapEntry.fromObject(value));
            funcSuccess(table);
        }, funcError, funcSuccess);
    }

    static getItems = (srwHash, funcSuccess, funcError) => {
        ApiEngine.getSecured(ApiUrl.API.SECURED.SURVEY.STATS.ITEMS, {srwHash}, (validationResult, callbackFunc)=> {
            /**
             * @type {Object[]}
             */
            const statsTable = validationResult.data.items;
            const table = statsTable.map(value => IntStringMapEntry.fromObject(value));
            funcSuccess(table);
        }, funcError, funcSuccess);
    }

    static getCols = (srwHash, funcSuccess, funcError) => {
        ApiEngine.getSecured(ApiUrl.API.SECURED.SURVEY.STATS.COLS, {srwHash}, (validationResult, callbackFunc)=> {
            /**
             * @type {Object[]}
             */
            const statsTable = validationResult.data.cols;
            const table = statsTable.map(value => IntStringMapEntry.fromObject(value));
            funcSuccess(table);
        }, funcError, funcSuccess);
    }

}

export default ApiEngine;