import React from 'react';
import TitleBanner from "../components/website/TitleBanner";
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import GreenButton from "../components/visual/GreenButton";
import SectionHeader from "../components/visual/SectionHeader";
import LinkInText from "../components/visual/LinkInText";
import Msg from "../i18n/Msg";
import {connect} from 'react-redux';

const PageOffer = () => {
    let liStyle = {
        paddingLeft: '0.8rem',
        paddingBottom: '1.1rem'
    }
    const olLiStyle = {
        paddingLeft: '1rem',
        paddingBottom: '0.8rem'
    }
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="NASZA OFERTA"/>
            <section className="mbr-section article content1 cid-rz82kzAdZw" id="content1-1n">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-8">
                            <SectionHeader text="Oferta"/>
                            <p style={{textAlign: 'justify'}}>
                                Wychodząc naprzeciw oczekiwaniom placówek oświatowych stworzyliśmy portal Ewaluacja Online, który jest pierwszym narzędziem w Polsce, wspomagającym wewnętrzną ewaluację placówek oświatowych.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                <strong>W obszarach ewaluacji dotyczących szkół podstawowych oferujemy kompleksowe, profesjonalne wsparcie na każdym etapie realizacji działania szkoły.</strong>
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Celem naszego portalu jest umożliwienie szkołom i placówkom oświatowym przeprowadzenia profesjonalnych badań ewaluacyjnych wśród:
                            </p>
                            <ul>
                                <li>Uczniów;</li>
                                <li>Rodziców / Opiekunów;</li>
                                <li>Nauczycieli.</li>
                            </ul>
                            <p style={{textAlign: 'justify'}}>
                                Nasza oferta obejmuje możliwość przeprowadzenia badań przed, w&nbsp;trakcie i&nbsp;po zakończeniu realizacji programów.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Portal zawiera już przygotowane profesjonalne narzędzie badawcze tworzone przez specjalistów w zakresie edukacji,
                                socjologii oraz psychologii. Na podstawie wyników badań otrzymają Państwo raport badawczy,
                                który posłuży do podniesienia jakości podejmowanych przez Państwa działań programowych.
                            </p>
                            {/*<p style={{textAlign: 'justify'}}>
                                Już teraz mogą Państwo przeprowadzić ewaluację na portalu Ewaluacja Online. W tym celu proszę zarejestrować się na stronie
                                <NavLink to={Msg.of().menu.contact.link}>
                                    <strong> pod tym linkiem </strong>
                                </NavLink>
                                oraz postępować wg dalszych wskazówek.
                            </p>*/}
                            <SectionHeader text="Tematyka badań"/>
                            <p style={{textAlign: 'justify'}}>
                                Tematy badań uwzględniają kierunki polityki oświatowej państwa, plany nadzoru kuratorów oświaty właściwych
                                dla szkół oraz wskazania zawarte w rozporządzeniu MEN o nadzorze pedagogicznym i wymaganiach państwa wobec szkół.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                3 lipca 2020 r. Ministerstwo Edukacji Narodowej na podstawie art. 60 ust. 3 pkt. 1 ustawy z dnia
                                14 grudnia 2016 r. – Prawo oświatowe (Dz. U. z 2020 r. poz. 910)
                                ustaliło <LinkInText to="https://www.gov.pl/web/edukacja/kierunki-polityki-oswiatowej">
                                następujące kierunki realizacji polityki oświatowej państwa w roku szkolnym 2020/2021:</LinkInText>

                            </p>

                            <ol style={{listStyleType: 'decimal', fontWeight: 'bold'}}>
                                <li style={olLiStyle}><span style={{fontWeight: 'normal'}}>
                                    Wdrażanie nowej podstawy programowej w szkołach ponadpodstawowych ze szczególnym uwzględnieniem edukacji przyrodniczej i matematycznej. Rozwijanie samodzielności,
                                    innowacyjności i kreatywności uczniów.
                                </span></li>
                                <li style={olLiStyle}><span style={{fontWeight: 'normal'}}>
                                    Wdrażanie zmian w kształceniu zawodowym, ze szczególnym uwzględnieniem kształcenia osób dorosłych.
                                </span></li>
                                <li style={olLiStyle}><span style={{fontWeight: 'normal'}}>
                                    Zapewnienie wysokiej jakości kształcenia oraz wsparcia psychologiczno – pedagogicznego wszystkim uczniom z uwzględnieniem zróżnicowania ich potrzeb rozwojowych i  edukacyjnych.
                                </span></li>
                                <li style={olLiStyle}><span style={{fontWeight: 'normal'}}>
                                    Wykorzystanie w procesach edukacyjnych narzędzi i zasobów cyfrowych oraz metod kształcenia na odległość. Bezpieczne i efektywne korzystanie z technologii cyfrowych.
                                </span></li>
                                <li style={olLiStyle}><span style={{fontWeight: 'normal'}}>
                                    Działania wychowawcze szkoły. Wychowanie do wartości, kształtowanie postaw i respektowanie norm społecznych.
                                </span></li>
                            </ol>

                            {/* <ol style={{listStyleType: 'upper-roman', fontWeight: 'bold'}}>
                                <li style={{paddingLeft: '20px'}}><span style={{fontWeight: 'normal'}}>Profilaktyka w szkołach i placówkach oświatowych.</span></li>
                                <li style={{paddingLeft: '20px'}}><span style={{fontWeight: 'normal'}}>Wychowanie do wartości przez kształcenie postaw obywatelskich i patriotycznych.</span></li>
                                <li style={{paddingLeft: '20px'}}><span style={{fontWeight: 'normal'}}>Wdrożenie nowej podstawy programowej kształcenia ogólnego w szkołach podstawowych i ponadpodstawowych.</span></li>
                                <li style={{paddingLeft: '20px'}}><span style={{fontWeight: 'normal'}}>Rozwijanie kompetencji matematycznych uczniów.</span></li>
                                <li style={{paddingLeft: '20px'}}><span style={{fontWeight: 'normal'}}>Rozwijanie kreatywności, przedsiębiorczości i kompetencji cyfrowych uczniów, w tym bezpieczne i celowe wykorzystania technologii informacyjno-komunikacyjnych w realizacji podstawy programowej kształcenia ogólnego.</span></li>
                                <li style={{paddingLeft: '20px'}}><span style={{fontWeight: 'normal'}}>Kształcenie u uczniów kompetencji kluczowych.</span></li>
                            </ol>*/}

                            <SectionHeader text="Cele badań"/>

                            <p style={{textAlign: 'justify'}}>
                                Szkoły podstawowe są adresatami w szczególności kierunków dotyczących <strong>zapewnienia wysokiej jakości kształcenia
                                oraz wsparcia psychologiczno – pedagogicznego wszystkich uczniów, wykorzystania w procesach edukacyjnych narzędzi i zasobów
                                cyfrowych oraz wzmocnienia działań wychowawczych, kształtowania postaw i respektowania norm społecznych.</strong>
                            </p>
                            <br/>
                            <p>
                                W roku szkolnym 2020/2021 w szkołach podstawowych i ponadpodstawowych <strong>kontrola</strong> obejmie:
                            </p>
                            <ul style={{textAlign: 'justify', marginBottom: 0}}>
                                <li style={liStyle}>„Zgodność z przepisami prawa funkcjonowania monitoringu wizyjnego w szkołach”</li>
                                <li style={liStyle}>„Zgodność z przepisami prawa organizowania zajęć w grupie do pięciu uczniów lub w formie indywidualnej oraz udzielania uczniom pomocy psychologiczno-pedagogicznej w formie zindywidualizowanej ścieżki
                                    kształcenia”</li>
                            </ul>
                            <br/>
                            <p style={{textAlign: 'justify'}}>
                                <strong>W zakresie ewaluacji problemowych</strong> (60% wszystkich ewaluacji) ewaluacja zewnętrzna w szkołach podstawowych będzie prowadzona w obszarze wymagań:
                            </p>
                            <ul style={{textAlign: 'justify', marginBottom: 0}}>
                                <li style={liStyle}>Procesy edukacyjne są zorganizowane w sposób sprzyjający uczeniu się</li>
                                <li style={liStyle}>Uczniowie nabywają wiadomości i umiejętności określone w podstawie programowej</li>
                            </ul>
                            <br/>
                            <p>
                                <strong>Monitorowanie w szkołach podstawowych obejmie</strong>
                            </p>
                            <ul style={{textAlign: 'justify', marginBottom: 0}}>
                                <li style={liStyle}>Uwzględnianie zróżnicowanych potrzeb edukacyjnych uczniów w procesie kształcenia oraz</li>
                                <li style={liStyle}>Kształcenie u uczniów kompetencji kluczowych</li>
                            </ul>
                            <SectionHeader text="Ankiety przykładowe"/>
                            <p>
                                Przykładowe ankiety można zobaczyć, klikając na poniższe linki:
                            </p>
                            <ul>
                                <li><a href="https://demo.surneo.pl/take?srw=1581344308945" target="_blank" rel="noreferrer noopener" className="text-warning"><strong>Ankieta dla uczniów</strong></a></li>
                                <li><a href="https://demo.surneo.pl/take?srw=1581344374360" target="_blank" rel="noreferrer noopener" className="text-warning"><strong>Ankieta dla rodziców / opiekunów</strong></a></li>
                                <li><a href="https://demo.surneo.pl/take?srw=1581343856834" target="_blank" rel="noreferrer noopener" className="text-warning"><strong>Ankieta dla nauczycieli</strong></a></li>
                            </ul>
                            <SectionHeader text="Metoda działania"/>
                            <p style={{textAlign: 'justify'}}>
                                Ankiety przeprowadzane są poprzez przesłanie linku do grup objętych badaniem. Pozwolą one Państwu uzyskać informacje o wybranym
                                obszarze podlegającym ewaluacji.
                            </p>
                            <SectionHeader text="Państwa zadania to wyłącznie:"/>
                            <ol style={{listStyleType: 'decimal', fontWeight: 'bold', textAlign: 'justify'}}>
                                <li style={liStyle}><span style={{fontWeight: 'normal'}}>Wybór obszaru do ewaluacji</span></li>
                                <li style={liStyle}><span style={{fontWeight: 'normal'}}>
                                    Przesłanie linku bezpośrednio do rodziców/opiekunów i nauczycieli, m.in. poprzez elektroniczny dziennik. W przypadku badań uczniów proponujemy, aby badania zostały przeprowadzone np. podczas zajęć z
                                    informatyki lub godzin wychowawczych.
                                </span></li>
                            </ol>
                            <SectionHeader text="Zalety naszego systemu badawczego"/>
                            <ul style={{textAlign: 'justify'}}>
                                <li style={liStyle}>Narzędzia badawcze przygotowane przez profesjonalistów w dziedzinie ewaluacji.</li>
                                <li style={liStyle}>Ankiety gotowe dla kierunków ewaluacji określonych przez Ministerstwo Edukacji Narodowej oraz Kuratorium Oświaty.</li>
                                <li style={liStyle}>Nieograniczona liczba badanych respondentów – możliwość przeprowadzenia badania wśród 200 lub np. 1000 uczniów w szkole.</li>
                                <li style={liStyle}>Raport ewaluacyjny zawierający całościowe wyniki badania, w formie wykresów i opisów, uwzględniający wybrane zmienne, tj. wiek czy płeć badanych.</li>
                            </ul>
                            <p style={{textAlign: 'justify'}}>
                                Już teraz mogą Państwo przeprowadzić ewaluację na portalu Ewaluacja Online. W tym celu proszę zarejestrować się na stronie
                                <NavLink to={Msg.of().menu.contact.link}>
                                    <strong> pod tym linkiem </strong>
                                </NavLink>
                                oraz postępować wg dalszych wskazówek.
                            </p>
                        </div>
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-4">
                            <img src="../assets/images/img33.jpg" alt="" title=""/>
                        </div>
                    </div>
                </div>
            </section>
            <GreenButton label="Dowiedz się więcej" internalLink={Msg.of().menu.infoGraphic.link}/>
            <GreenBottomBanner cennik={true}/>
        </>
    );
};

export default PageOffer;