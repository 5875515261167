import Action from "../../Action";
import ProfileState from "../../states/ProfileState";

class SetActiveMenuItemAction extends Action {
    static TYPE = "SetActiveMenuItemAction";

    /**
     * @param {{activeMenuItem: string}} payload
     */
    constructor(payload) {
        super(SetActiveMenuItemAction.TYPE, payload,
            /**
             *
             * @param {ProfileState} prevState
             * @return {Object}
             */
            prevState => {
                return ProfileState.get(prevState.initialDataLoaded, prevState.token, prevState.authenticated,
                    prevState.schoolUser, prevState.eduInstitution, payload.activeMenuItem, prevState.admin);
            });
    }

    /**
     * @param {string} menuItem
     * @return {SetActiveMenuItemAction}
     */
    static get = (menuItem) => {
        return new SetActiveMenuItemAction({activeMenuItem: menuItem});
    }

    /**
     * @public
     * @static
     * @method
     * @description Wysłanie żądania wylogowania użytkownika
     * @param {function({payload: ?Object, type: !string})} dispatch metoda dispatch Reduxa
     * @param {string} oldActiveMenuItem dotychczasowa aktywna pozycja menu
     * @param {string} newActiveMenuItem nowa aktywna pozycja menu
     */
    static run = (dispatch, oldActiveMenuItem, newActiveMenuItem) => {
        if(oldActiveMenuItem !== newActiveMenuItem) {
            dispatch(SetActiveMenuItemAction.get(newActiveMenuItem).plain());
        }
    }
}

export default SetActiveMenuItemAction;