import React from 'react';
import PropTypes from 'prop-types';

export const Text = (props) => {
    let style = props.style;
    if (!style) {
        style = {}
    }
    if (props.color) {
        style.color = props.color;
    }
    if (props.fontSize) {
        style.fontSize = props.fontSize;
    }
    if (props.fontWeight) {
        style.fontWeight = props.fontWeight;
    }
    if (props.italic) {
        style.fontStyle = "italic";
    }
    if(props.font) {
        style.fontFamily = props.font + ', Sans-Serif'
    }
    return (
        <>
            <div className="mbr-text mbr-fonts-style display-7" style={style}>
                {props.children}
            </div>
        </>
    );
};

Text.propTypes = {
    style: PropTypes.object,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    italic: PropTypes.bool,
    font: PropTypes.oneOf(['Roboto', 'Poppins', 'Open Sans', 'Segoe UI'])
}

export default Text;

