import React from 'react';
import PropTypes from 'prop-types';
import GreenButton from "./GreenButton";
import Msg from "../../i18n/Msg";

const BackForward = props => {
    const sectionStyleForGreenButtons = {padding: '1rem 0 3rem'};
    const greenButtonLinkStyle = {width: props.buttonWidth};
    const colClasses = (props.hideBack || props.hideForward)? "col-12" : "col-12 col-sm-6";
    const alignBack = props.hideForward? "center" : "right";
    const alignForward = props.hideBack? "center" : "left";

    const makeBackButton = align => {
        return !props.hideBack &&
            (
                <div className={colClasses}>
                    <GreenButton label={props.backLabel} onClickFunc={props.backFunc} align={align} sectionStyle={sectionStyleForGreenButtons} linkStyle={greenButtonLinkStyle}/>
                </div>
            );
    }

    const makeForwardButton = align => {
        return !props.hideForward &&
            (
                <div className={colClasses}>
                    <GreenButton label={props.forwardLabel} onClickFunc={props.forwardFunc} align={align} sectionStyle={sectionStyleForGreenButtons} linkStyle={greenButtonLinkStyle}/>
                </div>
            );
    }


    const backButtonAligned = makeBackButton(alignBack);
    const forwardButtonAligned = makeForwardButton(alignForward);
    const backButtonCentered = makeBackButton("center");
    const forwardButtonCentered = makeForwardButton("center");

    return (
        <>
            <div className="container align-center">
                <div className="row d-none d-sm-flex">
                    {backButtonAligned}
                    {forwardButtonAligned}
                </div>
                <div className="row d-sm-none">
                    {forwardButtonCentered}
                    {backButtonCentered}
                </div>
            </div>
        </>
    );
};

BackForward.propTypes = {
    backLabel: PropTypes.string,
    forwardLabel: PropTypes.string,
    backFunc: PropTypes.func,
    forwardFunc: PropTypes.func,
    hideBack: PropTypes.bool,
    hideForward: PropTypes.bool,
    buttonWidth: PropTypes.string
}

BackForward.defaultProps = {
    backLabel: Msg.get().common.back,
    forwardLabel: Msg.get().common.next,
    hideBack: false,
    hideForward: false,
    buttonWidth: '15rem'
}

export default BackForward;