import React, {Component} from 'react';
import Menu from "../components/website/Menu";
import PageHome from "./PageHome";
import PageAboutUs from "./PageAboutUs";
import PageOffer from "./PageOffer";
import PageInfoGraphic from "./PageInfoGraphic";
import PageTestimonials from "./PageTestimonials";
import PagePriceList from "./PagePriceList";
import PageLoginUser from "./PageLoginUser";
import PageContact from "./PageContact";
import ContactBanner from "../components/website/ContactBanner";
import Footer from "../components/website/Footer";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import ApiEngine from "../logic/ApiEngine";
import PageRegister from "./PageRegister";
import PageUsefulLinks from "./PageUsefulLinks";
import {connect} from 'react-redux';
import State from "../store/State";
import PageNews from "./PageNews";
import Msg from "../i18n/Msg";
import PageSchoolProfile from "./panel/PageSchoolProfile";
import PageSchoolClasses from "./panel/PageSchoolClasses";
import PageSurveyTemplates from "./panel/PageSurveys";
import PageSurveyWaves from "./panel/PageSurveyWaves";
import PageStatistics from "./panel/PageStatistics";
import PageSystemUsers from "./panel/PageSystemUsers";
import Preloader from "../components/visual/Preloader";
import DictionariesFetcher from "../logic/DictionariesFetcher";
import PageInterest from "./PageInterest";
import Logger from "../logic/Logger";
import PageAdminHome from "./admin/PageAdminHome";
import PageAdminMailing from "./admin/PageAdminMailing";
import PageShowEmail from "./PageShowEmail";
import PageCancelMailing from "./PageCancelMailing";
import PageSurveys from "./panel/PageSurveys";

class Root extends Component {


    state = {
        activation: {},
        menuHasToBeShown: !window.location.href.includes(Msg.of().menu.interest.linkPartToExcludeMenu) && !window.location.href.includes(Msg.of().menu.showEmail.linkPartToExcludeMenu)
    }


    constructor(props, context) {
        super(props, context);
    }


    componentDidMount() {
        ApiEngine.init(this.props.dispatch);
    }

    render() {
        const profileState = State.getProfileState(this.props);
        ApiEngine.dispatchMethodForSessionTimeout = this.props.dispatch;
        if (!profileState.initialDataLoaded && DictionariesFetcher.errorOccurred()) {
            ApiEngine.reInitAfterInitError(this.props.dispatch);
        }

        const menuHasToBeShown = this.state.menuHasToBeShown


        return (
            <Router>
                {menuHasToBeShown && <Menu/>}
                <section className="engine"/>
                <Switch>
                    <Route exact path="/" component={PageHome}/>
                    <Route path={Msg.of().menu.aboutUs.link} component={PageAboutUs}/>
                    <Route path={Msg.of().menu.news.link} component={PageNews}/>
                    <Route path={Msg.of().menu.offer.link} component={PageOffer}/>
                    <Route path={Msg.of().menu.infoGraphic.link} component={PageInfoGraphic}/>
                    <Route path={Msg.of().menu.usefulLinks.link} component={PageUsefulLinks}/>
                    <Route path={Msg.of().menu.testimonials.link} component={PageTestimonials}/>
                    <Route path={Msg.of().menu.priceList.link} component={PagePriceList}/>
                    <Route path={Msg.of().menu.panel.link} render={() => {
                        if (!this.state.menuHasToBeShown) {
                            this.setState({menuHasToBeShown: true})
                        }
                        if (profileState.isLogged()) {
                            return (
                                <Preloader showPreloader={!profileState.initialDataLoaded}>
                                    <Preloader showPreloader={profileState.showPreloader} titleBanner="Proszę czekać...">
                                        <PageSurveys/>
                                    </Preloader>
                                </Preloader>
                            )
                        } else {
                            return (
                                <Preloader showPreloader={!profileState.initialDataLoaded}
                                           titleBanner='Łączenie z serwerem'
                                           timeout={5}
                                           timeoutText="Przepraszamy. Serwer nie odpowiada. Prosimy spróbować później..."
                                           text="Nawiązywanie połączenia z serwerem">
                                    <PageLoginUser admin={false}/>
                                </Preloader>
                            )
                        }
                    }}/>
                    <Route path={Msg.of().menu.admin.link}>
                        {profileState.isAdminLogged() ? <Redirect push to={Msg.of().menu.adminHome.link}/> : <PageLoginUser admin={true}/>}
                    </Route>
                    <Route path={Msg.of().menu.adminHome.link}>
                        {profileState.isAdminLogged() ? <PageAdminHome/> : <Redirect push to={Msg.of().menu.admin.link}/>}
                    </Route>
                    <Route path={Msg.of().menu.adminMailing.link}>
                        {profileState.isAdminLogged() ? <PageAdminMailing/> : <Redirect push to={Msg.of().menu.admin.link}/>}
                    </Route>
                    <Route path={Msg.of().menu.contact.link} render={() => (
                        <PageContact/>
                    )}/>
                    <Route path={Msg.of().menu.register.link} render={() => (
                        <PageRegister/>
                    )}/>
                    <Route path={'/aktywacja/:code'} component={PageRegister}/>

                    <Route path={Msg.of().menu.schoolProfile.link} component={PageSchoolProfile}/>
                    {/*<Route path={Msg.of().menu.systemUsers.link} component={PageSystemUsers}/>*/}
                    {/*<Route path={Msg.of().menu.schoolClasses.link} component={PageSchoolClasses}/>*/}
                    <Route path={Msg.of().menu.surveys.link} component={PageSurveyTemplates}/>
                    {/*<Route path={Msg.of().menu.surveyWaves.link} component={PageSurveyWaves}/>*/}
                    {/*<Route path={Msg.of().menu.statistics.link} component={PageStatistics}/>*/}

                    <Route path={Msg.of().menu.interest.link} component={PageInterest}/>
                    <Route path={Msg.of().menu.showEmail.link} component={PageShowEmail}/>
                    <Route path={Msg.of().menu.cancelMailing.link} component={PageCancelMailing}/>

                </Switch>
                <ContactBanner/>
                <Footer/>
            </Router>
        );
    }
}

const RootConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(Root);

export default RootConnectedToRedux;