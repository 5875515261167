import React from 'react';
import TitleBanner from "../components/website/TitleBanner";
import PriceListDescriptionItem from "../components/website/pricelist/PriceListDescriptionItem";
import PriceListPackage from "../components/website/pricelist/PriceListPackage";
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import QuotesTable from "../components/website/pricelist/QuotesTable";
import Row from "../components/website/Row";
import Col from "../components/website/Col";
import TextParagraph from "../components/website/TextParagraph";
import {NavLink} from "react-router-dom";
import Msg from "../i18n/Msg";

const PagePriceList = () => {
    const disabled = true;
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="CENNIK NASZYCH USŁUG"/>
            <section className="pricing-table3 cid-rz88a5mOjQ" id="pricing-tables3-2j" style={{paddingTop: '40px'}}>
                <div className="container">
                    <Row>
                        <Col align="center">
                            <TextParagraph align="center" style={{color: 'rgb(118,118,118)'}}>
                                <strong>
                                    Szanowni Klienci
                                </strong>
                            </TextParagraph>
                        </Col>
                        <Col width={11}>
                            <TextParagraph style={{color: 'rgb(118,118,118)'}}>
                                {/*<strong>*/}
                                    Informujemy, że poniższe pakiety dostępne będą od 16 sierpnia 2021.
                                    Obecnie oferujemy darmowy dostęp do badania pilotażowego.
                                    Każda szkoła podstawowa w wiadomości e-mail otrzymała indywidualne zaproszenie do wzięcia w nim udziału.
                                    W zaproszeniu tym znajdziecie Państwo link do potwierdzenia udziału.
                                {/*</strong>*/}
                            </TextParagraph>
                            <TextParagraph style={{color: 'rgb(118,118,118)'}} align="center">
                                {/*<strong>*/}
                                    Chętnie odpowiemy na Państwa pytania poprzez <NavLink to={Msg.of().menu.contact.link}><strong>formularz
                                    kontaktowy</strong></NavLink>
                                {/*</strong>*/}
                            </TextParagraph>
                        </Col>
                    </Row>
                    <div className="media-container-row">
                        <PriceListPackage price="400" name="Pakiet M" disabled={disabled}>
                            <PriceListDescriptionItem>
                                <QuotesTable pupils={200} parents={200} teachers={20}/>
                            </PriceListDescriptionItem>
                            {/*<PriceListDescriptionItem>
                               Ankiety dla uczniów:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 200</strong></span>
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Ankiety dla rodziców:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 200</strong></span>
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Ankiety dla nauczycieli:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 20</strong></span>
                            </PriceListDescriptionItem>*/}
                            {/* <PriceListDescriptionItem>
                                Liczba uczniów szkoły: <br/><span style={{fontSize: '1.3em'}}><strong>do 200</strong></span>
                            </PriceListDescriptionItem>*/}
                            <PriceListDescriptionItem>
                                Zautomatyzowany system ankietowy
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Gotowe ankiety zgodne z wytycznymi MEN
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Przyjazny w obsłudze system zarządzania badaniem
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Szczegółowy raport po zakończeniu badania
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Indywidualnie przygotowane zalecenia
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Opieka działu obsługi klienta na każdym etapie badania
                            </PriceListDescriptionItem>

                        </PriceListPackage>
                        <PriceListPackage price="580" name="Pakiet L" disabled={disabled}>
                            <PriceListDescriptionItem>
                                <QuotesTable pupils={500} parents={500} teachers={50}/>
                            </PriceListDescriptionItem>
                            {/*<PriceListDescriptionItem>
                                Ankiety dla uczniów:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 500</strong></span>
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Ankiety dla rodziców:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 500</strong></span>
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Ankiety dla nauczycieli:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 50</strong></span>
                            </PriceListDescriptionItem>*/}
                            {/*<PriceListDescriptionItem>
                                Liczba uczniów szkoły: <br/><span style={{fontSize: '1.3em'}}><strong>201 - 500</strong></span>
                            </PriceListDescriptionItem>*/}
                            <PriceListDescriptionItem>
                                Zautomatyzowany system ankietowy
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Gotowe ankiety zgodne z wytycznymi MEN
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Przyjazny w obsłudze system zarządzania badaniem
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Szczegółowy raport po zakończeniu badania
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Indywidualnie przygotowane zalecenia
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Opieka działu obsługi klienta na każdym etapie badania
                            </PriceListDescriptionItem>
                        </PriceListPackage>
                        <PriceListPackage price="800" name="Pakiet XL" disabled={disabled}>
                            <PriceListDescriptionItem>
                                <QuotesTable pupils={2500} parents={2500} teachers={250}/>
                            </PriceListDescriptionItem>
                            {/*<PriceListDescriptionItem>
                                Ankiety dla uczniów:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 2500</strong></span>
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Ankiety dla rodziców:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 2500</strong></span>
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Ankiety dla nauczycieli:
                                <br/><span style={{fontSize: '1.3em'}}><strong>do 250</strong></span>
                            </PriceListDescriptionItem>*/}
                            {/* <PriceListDescriptionItem>
                                Liczba uczniów szkoły: <br/><span style={{fontSize: '1.3em'}}><strong>od 500</strong></span>
                            </PriceListDescriptionItem>*/}
                            <PriceListDescriptionItem>
                                Zautomatyzowany system ankietowy
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Gotowe ankiety zgodne z wytycznymi MEN
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Przyjazny w obsłudze system zarządzania badaniem
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Szczegółowy raport po zakończeniu badania
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Indywidualnie przygotowane zalecenia
                            </PriceListDescriptionItem>
                            <PriceListDescriptionItem>
                                Opieka działu obsługi klienta na każdym etapie badania
                            </PriceListDescriptionItem>
                        </PriceListPackage>
                    </div>
                </div>
            </section>
            <GreenBottomBanner oferta={true}/>
        </>
    );
};

export default PagePriceList;