class IntStringMapEntry {

    /**
     * @public
     * @field
     * @type {number}
     */
    key;

    /**
     * @public
     * @field
     * @type {string}
     */
    value;


    constructor(key, value) {
        this.key = key;
        this.value = value;
    }

    /**
     * @public
     * @static
     * @method
     * @param {number} key
     * @param {string} value
     * @return {IntStringMapEntry}
     */
    static get = (key, value) => {
        return new IntStringMapEntry(key, value);
    }

    /**
     * @public
     * @static
     * @method
     * @param {object} obj
     * @return {IntStringMapEntry}
     */
    static fromObject = obj => {
        const {key, value} = obj;
        return new IntStringMapEntry(key, value);
    }
}

export default IntStringMapEntry;