import React from 'react';
import PropTypes from 'prop-types';

const TitleBanner = props => {
    let bannerContent;
    if(props.small) {
        bannerContent = (
            <>
                <section className="mbr-section content4 cid-rz7YWhWzMl" id="content4-1c" style={{paddingBottom: 0, paddingTop: '5.7rem'}}>
                    <div className="container">
                        <div className="media-container-row">
                            <div className="title col-12 col-md-8">
                                <h2 className="align-center mbr-fonts-style display-2" style={{paddingBottom: 0, fontSize: '1.5rem'}}>
                                    <strong>
                                        {props.text}
                                    </strong>
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    } else {
        bannerContent = (
            <>
                <section className="mbr-section content4 cid-rz7YWhWzMl" id="content4-1c">
                    <div style={{height: "0.5rem", width: "100%"}}>&nbsp;</div>
                    <div className="container">
                        <div className="media-container-row">
                            <div className="title col-12 col-md-8">
                                <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                    <strong>
                                        {props.text}
                                    </strong>
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
    return (
        <>
        {bannerContent}
        </>
    );
};

TitleBanner.propTypes = {
    text: PropTypes.string.isRequired
}

export default TitleBanner;