import React, {Component} from 'react';
import {connect} from "react-redux";
import State from "../store/State";
import ApiEngine from "../logic/ApiEngine";

class PageShowEmail extends Component {

    state = {
        content: ''
    }

    emailHash;

    loadEmail = () => {
        ApiEngine.loadEmailContent(this.emailHash, response => {
            this.setState({content: response.data.content})
        })
    }

    componentDidMount() {
        this.loadEmail();
    }

    render() {
        const profileState = State.getProfileState(this.props);
        this.emailHash = this.props.match && this.props.match.params ? this.props.match.params.hash : undefined;
        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: this.state.content }}>
                </div>
            </>
        );
    }
}


const PageShowEmailConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageShowEmail)

PageShowEmail.propTypes = {
};

export default PageShowEmailConnectedToRedux;