import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from "../helpers/ScrollToTopOnMount";
import TitleBannerWithoutMenu from "./TitleBannerWithoutMenu";

export const LandingPage = (props) => {
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBannerWithoutMenu title={props.title} subtitle={props.subtitle} banner={props.banner}/>

            <section className="mbr-section form1 cid-rz85Klm51j" id="form1-2a">
                <div className="container">
                    {props.children}
                </div>
            </section>
        </>
    );
};

LandingPage.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired
}

export default LandingPage;

