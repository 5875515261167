import React, {Component} from 'react';
import Root from "./pages/Root";
import {Provider} from 'react-redux';
import {createStore} from "redux";
import State from "./store/State";
import ApiEngine from "./logic/ApiEngine";


class App extends Component {

    state = {
        site: {

        }
    }

    render() {
        const STORE = createStore(State.getRootReducer());
        ApiEngine.STORE = STORE;
        const result = (
            <Provider store={STORE}>
                <Root/>
            </Provider>
        );
        return result;
    }
}

export default App;
