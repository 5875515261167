import React from 'react';

const TeamMemberDescription = props => {

    return (
        <>
            {/*<p style={{marginBottom: '0px'}}>
                <strong>
                    Dr Adam Ustrzycki
                </strong>
            </p>
            <p>
                Ekspert w krajowych i międzynarodowych projektach edukacyjnych.
            </p>
            <p style={{marginBottom: '0px'}}>
                <strong>
                    Doświadczenie:
                </strong>
            </p>
            <p style={{textAlign: 'justify'}}>
                Praca na stanowisku wykładowcy akademickiego w Wyższej Szkole Bankowej w Opolu i we Wrocławiu - wykłada statystykę, analizę danych oraz socjologię.
                W latach 2005-2016 praca etatowa w uczelniach wyższych: Katolicki Uniwersytet Lubelski Jana Pawła II w Lublinie, Wyższa Szkoła Zarządzania i&nbsp;
                Administracji w Opolu, Szkoła Wyższa im. Bogdana Jańskiego w Opolu.
            </p>
            <p style={{textAlign: 'justify'}}>
                Kierownictwo w 18 projektach badawczych, sporządzenie ponad 70 raportów z badań społecznych i rynkowych.
            </p>*/}

            <div className="card">
                <div className="card-header" id={`heading${props.id}`} style={{backgroundColor: '#fff', borderBottom: 0}}>
                    <table>
                        <tbody>
                        <tr>
                            {/*<td style={{padding: 30}}>
                                <p>
                                    <img src="http://icons.iconarchive.com/icons/graphicloads/folded/64/check-contact-folded-icon.png" alt="personIcon"/>
                                </p>
                            </td>*/}
                            <td>
                                <h2 className="mb-0" style={{lineHeight: '2rem', fontSize: '1.3rem', color: '#767676'}}>
                                    <strong>{props.name}</strong>
                                </h2>
                                <p style={{lineHeight: '2rem', fontSize: '1rem', color: '#767676', marginBottom: 0}}>
                                    <strong>{props.role}</strong>
                                </p>
                                {/*<button style={{padding: 0, margin: 0, border: 0, fontSize: '1rem', letterSpacing: 0, color: '#49ad5c'}}
                                        className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${props.id}`} aria-controls={`collapse${props.id}`} aria-expanded="true">
                                    więcej...
                                </button>*/}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div id={`collapse${props.id}`} className="collapse show" aria-labelledby={`heading${props.id}`} data-parent={`#dep${props.parentId}`}>
                    <div className="card-body" style={{fontSize: '1rem', paddingTop: 0}}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeamMemberDescription;