class FieldType {
    static text = 1;
    static radio = 2;
    static select = 3;
    static checkbox = 4;
    static password = 5;
    static number = 6;
    static range = 7;
}

export default FieldType;