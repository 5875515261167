import ValidationResult from "../logic/ValidationResult";
import Msg from "../i18n/Msg";
import Utils from "../logic/Utils";
import Field from "../form/components/Field";
import ApiEngine from "../logic/ApiEngine";

class User {

    static fieldNames = {
        firstname: 'firstname',
        lastname: 'lastname',
        email: 'email',
        phone: 'phone',
        rodoAccepted: 'rodoAccepted',
        login: 'login',
        password: 'password',
        userRole: 'userRole',
        emailRepeated: 'emailRepeated',
        passwordRepeated: 'passwordRepeated'
    }

    /**
     * @public
     * @field
     * @description ID z bazy danych
     * @type {number}
     */
    id;

    /**
     * @public
     * @field
     * @description Imię
     * @type {string}
     */
    firstname;

    /**
     * @public
     * @field
     * @description Nazwisko
     * @type {string}
     */
    lastname;

    /**
     * @public
     * @field
     * @description Adres email
     * @type {string}
     */
    email;
    emailRepeated;

    /**
     * @public
     * @field
     * @description Numer telefonu
     * @type {string}
     */
    phone;

    /**
     * @public
     * @field
     * @description Czy została zaakceptowana klauzula RODO
     * @type {boolean}
     */
    rodoAccepted;

    /**
     * @public
     * @field
     * @description Nazwa użytkownika (login)
     * @type {string}
     */
    login;

    /**
     * @public
     * @field
     * @description Hasło użytkownika
     * @type {string}
     */
    password;
    passwordRepeated;

    /**
     * @public
     * @field
     * @description Czy osoba jest kierownikiem zespołu ewaluacyjnego
     * @type {boolean}
     */
    headOfEvaluationTeam;

    /**
     * @public
     * @field
     * @description Czy adres email został zweryfikowany
     * @type {string}
     */
    activationTime;

    /**
     * @public
     * @field
     * @description Typ użytkownika
     * @type {number}
     */
    userRole;


    // noinspection DuplicatedCode
    constructor(firstname, lastname, email, phone, rodoAccepted, login, password, headOfEvaluationTeam, activationTime, userRole, id) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.phone = phone;
        this.rodoAccepted = rodoAccepted;
        this.login = login;
        this.password = password;
        this.headOfEvaluationTeam = headOfEvaluationTeam;
        this.activationTime = activationTime;
        this.userRole = userRole;
        this.id = id;
    }

    static get = (firstname, lastname, email, phone, rodoAccepted, login, password, headOfEvaluationTeam, activationTime, userRole, id) => {
        return new User(firstname, lastname, email, phone, rodoAccepted, login, password, headOfEvaluationTeam, activationTime, userRole, id);
    }

    static getDefault = () => {
        // return User.get('', '', '', '', false, '', '', true, false, '');
        let ed = User.get('Ed', 'Kowalski', 'lt@i-pi.pl', '123123123', true, 'edzio', 'Kupa1234', true, true, '1', undefined);
        ed.passwordRepeated = ed.password;
        ed.emailRepeated = ed.email;
        return ed;
    }

    /**
     * @public
     * @method
     * @description ustawia pole obiektu przekazane w evencie i zwraca nowy obiekt schoolUser
     * @param e event
     * @return {User} nowy obiekt schoolUser
     */
    returnNewFromEvent(e) {
        const fromEvent = Field.readFromEvent(e);
        this[fromEvent.fieldName] = fromEvent.fieldValue;
        const newUser = new User(this.firstname, this.lastname, this.email, this.phone, this.rodoAccepted,
            this.login, this.password, this.headOfEvaluationTeam, this.activationTime, this.userRole, this.id);
        return newUser;
    }

    validate() {
        const result = ValidationResult.get();
        const names = User.fieldNames;
        if (!this.firstname || this.firstname.length < 2) {
            result.addError(names.firstname, Msg.userFormValidationError.firstname);
        }
        if (!this.lastname || this.lastname.length < 2) {
            result.addError(names.lastname, Msg.userFormValidationError.lastName);
        }
        if (!this.email || !Utils.validateEmail(this.email)) {
            result.addError(names.email, Msg.userFormValidationError.email);
        }
        if (!this.emailRepeated || this.email !== this.emailRepeated) {
            result.addError(names.emailRepeated, Msg.userFormValidationError.emailRepeated);
        }
        if (!this.rodoAccepted) {
            result.addError(names.rodoAccepted, Msg.userFormValidationError.rodoAccepted);
        }
        if (!this.login || this.login.length < 5) {
            result.addError(names.login, Msg.userFormValidationError.login);
        }
        if (!this.password || !Utils.validatePassword(this.password) || this.password === this.login) {
            result.addError(names.password, Msg.userFormValidationError.password);
        }
        if (!this.passwordRepeated || this.password !== this.passwordRepeated) {
            result.addError(names.passwordRepeated, Msg.userFormValidationError.passwordRepeated);
        }
        if (!this.userRole) {
            result.addError(names.userRole, Msg.userFormValidationError.role);
        }
        return result;
    }

    /**
     *
     * @param {School} school
     * @return {{firstname: string, password: string, phone: string, rodoAccepted: boolean, headOfEvaluationTeam: string,
     * schoolDTO: {secEmail: string|*, schoolSize: string|*, nip: string|*, city: string|string|*, street: string|*, postalCode: string|string|*, name: *, schoolType: *, houseNumber: string|*, postalCity: string, secPhone: string|*, voivodeship: string|*},
     * login: string, activationTime: string, userRole: number, email: string, lastname: string}}
     */
    toSend(school) {
        const result = {
            "firstname": this.firstname,
            "lastname": this.lastname,
            "email": this.email,
            "phone": this.phone,
            "rodoAccepted": this.rodoAccepted,
            "login": this.login,
            "password": this.password,
            "headOfEvaluationTeam": "true",
            "activationTime": this.activationTime,
            "userRole": this.userRole,
            "schoolDTO": {
                "name": school.name,
                "schoolType": school.type,
                "nip": school.nip,
                "street": school.street,
                "houseNumber": school.houseNumber,
                "postalCode": school.postalCode,
                "postalCity": '',
                "city": school.city,
                "secEmail": school.secEmail,
                "secPhone": school.secPhone,
                "schoolSize": school.schoolSize,
                "voivodeship": school.voivodeship,
            }
        }
        return result;
    }

    /**
     *
     * @return {{string: string|*}}
     */
    getInfoObject() {
        const user = this;
        return {
            [Msg.userFirstName]: user.firstname,
            [Msg.userLastName]: user.lastname,
            [Msg.userRole]: ApiEngine.nameOfUserRoles(user.userRole),
            [Msg.userEmailShort]: user.email,
            [Msg.userPhoneShort]: user.phone,
            [Msg.userLogin]: user.login
        }
    }
}

export default User;