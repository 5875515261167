import React from 'react';
import PropTypes from 'prop-types';
import Utils from "../../../logic/Utils";
import ResponsiveRatio from "../ResponsiveRatio";

const RadioGroup = props => {
    let {values, name, inline, ratio, label, marginBottom, fieldValue, onChangeFunc} = props;
    let radios = [];
    for (let [value, label] of Object.entries(values)) {
        const newRadioID = Utils.escapeDiacriticsAndSpaces(value + label);
        let checked = false;
        if(value === fieldValue) {
            checked = true;
        }
        if (inline) {
            let newRadioInline = (
                <div className="form-check form-check-inline" key={newRadioID}>
                    <input className="form-check-input" type="radio" name={name} id={newRadioID} value={value} checked={checked} onChange={onChangeFunc}/>
                    <label className="form-check-label" htmlFor={newRadioID}>{label}</label>
                </div>
            );
            radios = [...radios, newRadioInline];
        } else {
            let newRadioPlain = (
                <div className="form-check" key={newRadioID}>
                    <input className="form-check-input form-control-sm" type="radio" name={name} id={newRadioID} value={value} checked={checked} onChange={onChangeFunc}/>
                    <label className="form-check-label form-control-sm" htmlFor={newRadioID} style={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                        {label}
                    </label>
                </div>
            );
            radios = [...radios, newRadioPlain];
        }
    }


    return (
        <>

            <div className="row align-items-center" style={{"marginBottom": marginBottom}}>
                <div className={ratio.getLabelClasses()} style={{marginTop: '0.6rem'}}>
                    <p className="h6">{label}</p>
                </div>
                <div className={ratio.getControlClasses()}>
                    {radios}
                </div>
            </div>

            {/*<div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                <label className="form-check-label" htmlFor="inlineRadio1">1</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                <label className="form-check-label" htmlFor="inlineRadio2">2</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" disabled/>
                <label className="form-check-label" htmlFor="inlineRadio3">3 (disabled)</label>
            </div>

            <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                <label className="form-check-label" htmlFor="exampleRadios1">
                    Default radio
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                <label className="form-check-label" htmlFor="exampleRadios2">
                    Second default radio
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" disabled/>
                <label className="form-check-label" htmlFor="exampleRadios3">
                    Disabled radio
                </label>
            </div>*/}
        </>
    );
};

RadioGroup.propTypes = {
    values: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    ratio: PropTypes.shape(ResponsiveRatio.shape()).isRequired,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    onChangeFunc: PropTypes.func.isRequired,
    fieldValue: PropTypes.string.isRequired
}

RadioGroup.defaultProps = {
    ratio: ResponsiveRatio.default()
}

export default RadioGroup;