import BaseReducer from "../BaseReducer";
import ProfileState from "../states/ProfileState";
import LoginAction from "../actions/profile/LoginAction";
import LogoutAction from "../actions/profile/LogoutAction";
import InitialDataLoadedAction from "../actions/profile/InitialDataLoadedAction";
import ShowPreloaderAction from "../actions/profile/ShowPreloaderAction";
import SetActiveMenuItemAction from "../actions/profile/SetActiveMenuItemAction";

class ProfileReducer extends BaseReducer {

    constructor() {
        super('ProfileReducer', ProfileState.initial());
        super.handledActions = [LoginAction.TYPE, LogoutAction.TYPE, InitialDataLoadedAction.TYPE, ShowPreloaderAction.TYPE, SetActiveMenuItemAction.TYPE];
    }

    /**
     * @public
     * @static
     * @method
     * @description metoda zwracająca funkcję reducera
     * @return {function(Object=, {type: string, payload: Object}): *}
     */
    static get = () => {
        return new ProfileReducer().reduce;
    }


}

export default ProfileReducer;