import React from 'react';
import TitleBanner from "../components/website/TitleBanner";
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import TestimonialsItem from "../components/website/TestimonialsItem";
import Msg from "../i18n/Msg";
import RefDGP from "../img/referencjeDGP.png";
import LinkInText from "../components/visual/LinkInText";
import {connect} from 'react-redux';

const PageTestimonials = () => {
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="NASZE REFERENCJE"/>
            {/*<TestimonialsItem
                date="30.09.2020"
                person="Dominika Sikora-Malicka"
                role="zastępca redaktora naczelnego Dziennika Gazety Prawnej"
            >
                Korporacja Badawcza Pretendent Sp. z o.o. z siedzibą we Wrocławiu ul. Elizy Orzeszkowej 40/9
                przygotowała INFOR BIZNES Sp. z o.o. 01-042 Warszawa, ul. Okopowa 58/72 projekt
                badawczy wśród jednostek samorządu terytorialnego, w szczególności dyrektorów szkół na temat
                sytuacji w edukacji szkolnej.
                Badanie zostały zrealizowane w okresie od 10 sierpnia do 28 sierpnia 2020 r. poprzez badania
                ilościowe techniką CAWI wśród jednostek samorządu terytorialnego oraz wśród dyrektorów
                szkół na temat sytuacji w edukacji szkolnej .
                Łącznie przebadano N= 502 respondentów, w tym N=145 przedstawicieli JST oraz N=357
                dyrektorów szkół.
                Powyższe projekty zostały w pełni zrealizowany zgodnie z wytycznymi zamawiającego
                i przyjęty bez zastrzeżeń. W ramach współpracy opracowano raport końcowy.
                <p>
                    <br/>
                    <LinkInText to={RefDGP} color="green">Pobierz plik</LinkInText>
                </p>
            </TestimonialsItem>*/}
            <TestimonialsItem
                date="14.08.2019"
                person="Dorota Siemaszko-Babij"
                role="Dyrektor Szkoły Podstawowej nr 2 im. Jana Pawła II w Twardogórze"
            >
                Szkoła to dość ściśle zamknięte środowisko. Czasem trudno spojrzeć obiektywnie na to, co dzieje się w jej wnętrzu,
                będąc w orbicie… Firma zewnętrzna przeprowadzająca ewaluację pomaga spojrzeć na obraz szkoły, zachowując dystans i obiektywizm,
                patrząc globalnie na jednostkę i procesy w niej zachodzące. To co było ważne dla mnie we współpracy ze specjalistami z Ewaluacja Online,
                to kontakt ze środowiskiem naukowym, świeże pytania, które padły i&nbsp;nowe spojrzenie na kilka sfer pracy szkoły.
                Bardzo istotna była również pomoc w interpretacji wyników i ankiet oraz sam dobór narzędzi badawczych.
            </TestimonialsItem>
            {/*<TestimonialsItem
                date="11.06.2018"
                person="Rafał Lew-Starowicz"
                role="Zastępca Dyrektora Departamentu Podręczników, Programów i Innowacji w Ministerstwie Edukacji Narodowej"
            >
                <p>
                    Korporacja Badawcza Pretendent sp. z o.o. z siedziba we Wrocławiu ul. Orzeszkowej 40/9 zrealizowała badanie społeczne w zakresie
                    rekrutacji uczestników badania Zogniskowanych Wywiadów Grupowych, realizacji badania transkrypcji i raportu badań Zogniskowanych
                    Wywiadów Grupowych w projekcie: "Zmiany w systemie edukacji w Polsce odpowiedzią na oczekiwania społeczne i zmiany gospodarcze".
                </p>
                <p>
                    W ramach badania przeprowadzano N=36 Zogniskowanych Wywiadów Grupowych zgodnie z metodologia i scenariuszem zatwierdzonymi
                    przez Zamawiajacego. Łącznie przebadano N=288 osób.
                </p>
                <p>
                    Na podstawie wyników badan opracowano i przygotowano raport analityczny - zbiór wniosków wynikajacych z analizy przygotowanych
                    transkrypcji wywiadów, porównań i analiz informacji wynikajacych z realizacji całego badania.
                </p>
                <p>
                    Badanie zostało zrealizowane w okresie od września 2017 roku do 15 lutego 2018 r.
                </p>
                <p>
                    Wartość podpisanej umowy wyniosła 55 350 zł (słownie: pięćdziesiąt pięć tysięcy trzysta pięćdziesiąt złotych) brutto.
                </p>
                <p>
                    Badanie przyjęto bez zastrzeżeń.
                </p>
            </TestimonialsItem>*/}

            <hr style={{margin: "70px 0px"}}/>

            <section className="mbr-section article content1 cid-rz84WVEt2Y" id="content1-22">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <p style={{textAlign: 'justify'}}>
                                <span style={{fontSize: '1.15rem'}}>
                                    Już teraz mogą Państwo zasięgnąć informacji o możliwościach przeprowadzenia ewaluacji w Państwa szkole w roku szkolnym 2021/2022
                                    poprzez portal Ewaluacja Online. W tym celu proszę skorzystać z formularza znajdującego się
                                </span>
                                <NavLink to={Msg.of().menu.contact.link} style={{fontSize: '1.15rem', backgroundColor: 'rgb(255, 255, 255)'}}>
                                    <strong> pod tym linkiem </strong>
                                </NavLink>
                               {/* <span style={{fontSize: '1.15rem'}}>
                                    oraz postępować wg dalszych wskazówek.
                                </span>*/}
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <GreenBottomBanner/>
        </>
    );
}

export default PageTestimonials;