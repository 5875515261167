import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import TitleBanner from "../../components/website/TitleBanner";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../../i18n/Msg";

class PageSchoolClasses extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem, Msg.of().menu.schoolClasses.label);
    }

    render() {
        const profileState = State.getProfileState(this.props);
        if (profileState.isLogged()) {
            return (
                <>
                    <TitleBanner text="Klasy szkolne" small={true}/>

                </>
            );
        } else {
            return <PageLoginUser/>
        }
    }
}


const PageSchoolClassesConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageSchoolClasses)

PageSchoolClasses.propTypes = {};

export default PageSchoolClassesConnectedToRedux;