class SurveyState {
    /**
     * @type {Object.<string, SurveyStats>}
     */
    surveys;

    constructor(surveys) {
        this.surveys = surveys;
    }

    /**
     *
     * @param {string} srwHash
     * @return {Date}
     */
    lastUpdateStatsTime(srwHash) {
        const sStats = this.surveys[srwHash];
        if(sStats && sStats.lastUpdateStats) {
            return sStats.lastUpdateStats;
        } else {
            return new Date(2000,1,1);
        }
    }

    /**
     *
     * @param {string} srwHash
     * @return {Date}
     */
    lastUpdateLabelsTime(srwHash) {
        const sStats = this.surveys[srwHash];
        if(sStats && sStats.lastUpdateLabels) {
            return sStats.lastUpdateLabels;
        } else {
            return new Date(2000,1,1);
        }
    }

    /**
     * @public
     * @param {Object.<string, SurveyStats>} surveys
     * @return {SurveyState}
     */
    static get = surveys => {
        return new SurveyState(surveys);
    }

    /**
     * @public
     * @return {SurveyState}
     */
    static initial = () => {
        return SurveyState.get({});
    }
}

export default SurveyState;