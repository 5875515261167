import React from 'react';
import {NavLink} from "react-router-dom";
import Carousel from '../components/website/Carousel';
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import menLogo from '../img/loga/mein-logo.png';
import seoLogo from '../img/loga/seo-logo.png';
import ibeLogo from '../img/loga/ibe-logo.png';
import ibeEeLogo from '../img/loga/ibeee-logo.png';
import ckeLogo from '../img/loga/cke-logo.png';
import oreLogo from '../img/loga/ore-logo.png';
import GreenButton from "../components/visual/GreenButton";
import LinkInText from "../components/visual/LinkInText";
import BigHeader from "../components/visual/BigHeader";
import SectionHeader from "../components/visual/SectionHeader";
import Msg from "../i18n/Msg";
import {connect} from 'react-redux';
import State from "../store/State";
import SetActiveMenuItemAction from "../store/actions/profile/SetActiveMenuItemAction";

const PageHome = (props) => {
    const profileState = State.getProfileState(props);
    SetActiveMenuItemAction.run(props.dispatch, profileState.activeMenuItem, '');
    return (
        <>
            <ScrollToTopOnMount/>
            <Carousel/>

            <BigHeader>
                Rozpoczynamy nowy projekt badawczy
            </BigHeader>
            <section className="mbr-section article content1 cid-rz6immHSZe" id="content1-k">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12 align-center" style={{marginBottom: '1.5rem'}}>
                            <SectionHeader text="REALIZACJA PODSTAWY PROGRAMOWEJ W CZASIE PANDEMII"/>
                        </div>
                    </div>
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <p style={{textAlign: "justify"}}>
                                Serdecznie zapraszamy do udziału w nowym projekcie badawczym, którego realizację rozpoczynamy 25 maja 2021. Będzie on opierał się na badaniu,
                                realizowanym w
                                Szkołach Podstawowych na uczniach klas IV-VIII, przygotowanym przez ekspertów na podstawie wytycznych aktualnej podstawy programowej. Jego wynikiem
                                będzie bezpłatny raport, który pokaże w jaki sposób uczniowie oceniają swój rozwój w czasie nauki zdalnej
                                oraz stopień realizacji podstawy programowej. Udział w projekcie jest bezpłatny a indywidualne zaproszenie będzie wysłane na adres e-mail każdej
                                szkoły.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <GreenButton label="Dowiedz się więcej" internalLink={Msg.of().menu.news.link}/>

            <section className="mbr-section article content1 cid-rz6immHSZe" id="content1-k">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12 align-center" style={{marginBottom: '1.5rem'}}>
                            <SectionHeader text="EDUKACJA W CIENIU WIRUSA"/>
                        </div>
                    </div>
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <p style={{textAlign: "justify"}}>
                                Miło nam poinformować, że <LinkInText to="/">Ewaluacja.Online</LinkInText> wspólnie
                                z <LinkInText to="https://www.gazetaprawna.pl">Dziennikiem Gazetą Prawną</LinkInText> zrealizowały projekt badawczy
                                w celu poznania opinii na temat stanu edukacji szkolnej przed nowym rokiem szkolnym. Badanie skierowane było do dyrektorów szkół,
                                <strong> prezydentów miast, burmistrzów, wójtów</strong> i <strong>starostów</strong>. Chcieliśmy poznać opinie oraz sugestie, które w okresie
                                pandemii są bardzo ważne.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Efektem końcowym badania jest raport, do którego link znajduje się poniżej. Sądzimy iż informacje w nim zawarte pozwolą Państwu na poznanie opinii
                                środowiska nauczycielskiego i samorządowego.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <GreenButton label="Raport z badania" externalLink="https://ankieta.ewaluacja.online/home/Edukacja_w_cieniu_wirusa.pdf" blank={true}/>

            <BigHeader>
                Ewaluacja Online - wspomaganie wewnętrznej ewaluacji placówek oświatowych
            </BigHeader>

            <section className="mbr-section article content1 cid-rz6immHSZe" id="content1-k">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <p><strong></strong></p>
                            <p className="text-justify">
                                {/*Jako grupa ekspertów w dziedzinie ewaluacji szkolnej i tematyki badań ewaluacyjnych nawiązaliśmy partnerstwo z doświadczonym zespołem IT,
                                czego efektem jest ten portal.*/}

                                Portal ten jest efektem nawiązania partnerstwa doświadczonego zespołu IT oraz ekspertów w dziedzinie ewaluacji szkolnej i tematyki badań
                                ewaluacyjnych
                                w Internecie.
                                Członkami naszego zespołu są badacze z długoletnim doświadczeniem oraz programiści i&nbsp;administratorzy serwerów. Współpraca z pracownikami
                                uczelni
                                wyższych oraz szkół stanowi dodatkowo wsparcie merytoryczne.
                            </p>
                            <p className="text-justify">
                                Wychodząc naprzeciw oczekiwaniom placówek oświatowych stworzyliśmy portal Ewaluacja Online, który jest pierwszym narzędziem w Polsce,
                                wspomagającym wewnętrzną ewaluację placówek oświatowych.
                            </p>
                            <p className="text-left">
                                Celem naszego portalu jest możliwość przeprowadzenia profesjonalnych badań wśród odbiorców ewaluacji uczniów, rodziców, nauczycieli.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <GreenButton label="DOWIEDZ SIĘ WIĘCEJ" internalLink={Msg.of().menu.offer.link}/>


            <section className="mbr-section content4 cid-rz6tqme7kt" id="content4-s">


                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                Zalety systemu Ewaluacja Online</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features1 cid-rz6t762Sah" id="features1-r">


                <div className="container">
                    <div className="media-container-row">

                        <div className="card p-3 col-12 col-md-6 col-lg-3">
                            <div className="card-img pb-3">
                                <span className="mbr-iconfont mbri-growing-chart" style={{fontSize: '60px', color: 'rgb(73, 173, 92)', fill: 'rgb(73, 173, 92)'}}></span>
                            </div>
                            <div className="card-box">

                                <p className="mbr-text mbr-fonts-style display-4"><strong>
                                    Narzędzia badawcze przygotowane przez profesjonalistów w dziedzinie ewaluacji
                                </strong></p>
                            </div>
                        </div>

                        <div className="card p-3 col-12 col-md-6 col-lg-3">
                            <div className="card-img pb-3">
                                <span className="mbr-iconfont mbrib-setting2" style={{fontSize: '60px', color: 'rgb(73, 173, 92)', fill: 'rgb(73, 173, 92)'}}></span>
                            </div>
                            <div className="card-box">

                                <p className="mbr-text mbr-fonts-style display-4"><strong>
                                    Ankiety gotowe dla kierunków ewaluacji określonych przez Ministerstwa oraz Kuratorium Oświaty
                                </strong></p>
                            </div>
                        </div>

                        <div className="card p-3 col-12 col-md-6 col-lg-3">
                            <div className="card-img pb-3">
                                <span className="mbr-iconfont mbri-users" style={{fontSize: '60px', color: 'rgb(73, 173, 92)', fill: 'rgb(73, 173, 92)'}}></span>
                            </div>
                            <div className="card-box">

                                <p className="mbr-text mbr-fonts-style display-4"><strong>
                                    Nieograniczona liczba badanych respondentów – badanie wśród 200 lub np. 1000 uczniów w szkole
                                </strong></p>
                            </div>
                        </div>

                        <div className="card p-3 col-12 col-md-6 col-lg-3">
                            <div className="card-img pb-3">
                                <span className="mbr-iconfont mbri-edit" style={{fontSize: '60px', color: 'rgb(73, 173, 92)', fill: 'rgb(73, 173, 92)'}}></span>
                            </div>
                            <div className="card-box">

                                <p className="mbr-text mbr-fonts-style display-4"><strong>Raport po zakończeniu każdego badania z wynikami, uwzględniający zakres danego
                                    badania</strong><br/></p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className="mbr-section content4 cid-rz6z3qVI7d" id="content4-u">
                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                Poznaj ofertę systemu Ewaluacja Online
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cid-rz6v2qDrgy" id="content15-t">

                <div className="container">
                    <div className="media-container-row" style={{width: '100%'}}>
                        <div className="img-item item1" style={{width: '56%'}}>
                            <div className="img-cont">
                                <div className="mbr-overlay" style={{opacity: 0, backgroundColor: 'rgb(35, 35, 35)'}}></div>
                                <NavLink to={Msg.of().menu.infoGraphic.link}><img className="d-block w-100" src="assets/images/bazawiedzy-2-784x784.jpeg" alt=""
                                                                                  title=""/></NavLink>
                                <div className="img-caption">
                                    <p className="mbr-fonts-style align-left mbr-white display-5">
                                        <strong>
                                            Nasza Baza Wiedzy
                                        </strong>
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="img-item">
                            <div className="img-cont">
                                <div className="mbr-overlay" style={{opacity: 0, backgroundColor: 'rgb(35, 35, 35)'}}></div>
                                <NavLink to={Msg.of().menu.priceList.link}><img className="d-block w-100" src="assets/images/cennik-1306x870.jpg" alt="" title=""/></NavLink>
                                <div className="img-caption">
                                    <p className="mbr-fonts-style align-left mbr-white display-5">
                                        <strong>
                                            Poznaj cennik systemu Ewaluacji Online
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section content4 cid-rz6z3qVI7d" id="content4-u-2">
                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                Ważne strony
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content1 cid-rz6immHSZe">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://cke.gov.pl/" target="_blank"><img src={ckeLogo} className="d-block w-100" alt="Centralna Komisja Egzaminacyjna"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.gov.pl/web/edukacja" target="_blank"><img src={menLogo} className="d-block w-100" alt="Ministerstwo Edukacji Narodowej"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.ore.edu.pl/" target="_blank"><img src={oreLogo} className="d-block w-100" alt="Ośrodek Rozwoju Edukacji"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.npseo.pl/" target="_blank"><img src={seoLogo} className="d-block w-100" alt="System Ewaluacji Oświaty Nadzór Pedagogiczny"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.ibe.edu.pl/pl/" target="_blank"><img src={ibeLogo} className="d-block w-100" alt="Instytut Badań Edukacyjnych"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="http://eduentuzjasci.pl/" target="_blank"><img src={ibeEeLogo} className="d-block w-100" alt="Entuzjaści Edukacji"/></a>
                        </div>
                    </div>
                </div>
            </section>

            <GreenButton label="WIĘCEJ PRZYDATNYCH LINKÓW" internalLink={Msg.of().menu.usefulLinks.link}/>

            <GreenBottomBanner/>
        </>
    );
}

const PageHomeConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageHome);

export default PageHomeConnectedToRedux;