import React from 'react';
import TitleBanner from '../components/website/TitleBanner';
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import Department from "../components/website/aboutUs/Department";
import metodologiaImage from "../img/onas/metodologia1.jpg";
import supportImage from "../img/onas/obsluga_klienta1.jpg";
import itImage from "../img/onas/it1.jpg";
import TeamMemberDescription from "../components/website/aboutUs/TeamMemberDescription";
import {connect} from 'react-redux';
import State from "../store/State";
import SetActiveMenuItemAction from "../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../i18n/Msg";

const PageAboutUs = (props) => {
    const profileState = State.getProfileState(props);
    SetActiveMenuItemAction.run(props.dispatch, profileState.activeMenuItem, Msg.of().menu.aboutUs.label);
    return (

        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="POZNAJ NAS"/>
            <section className="mbr-section article content1 cid-rz7Zrv6I0M" id="content1-1e" style={{paddingBottom: '20px'}}>


                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
                            <p style={{textAlign: 'justify'}}>
                                Portal ten jest efektem nawiązania partnerstwa doświadczonego zespołu IT oraz ekspertów w dziedzinie ewaluacji szkolnej i tematyki badań
                                ewaluacyjnych w Internecie. Członkami naszego zespołu są badacze z długoletnim doświadczeniem oraz programiści i administratorzy serwerów.
                                Współpraca z pracownikami uczelni wyższych oraz szkół stanowi dodatkowo wsparcie merytoryczne.
                            </p>
                            <p>
                                <strong>
                                    Zapraszamy do zapoznania się z wybranymi osobami z naszego zespołu
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content1 cid-rz7Zrv6I0M" id="content1-1e" style={{paddingTop: '0px'}}>

                <div className="container">
                    <Department name="Zespół ds. metodologii" image={metodologiaImage} id="1">

                        <TeamMemberDescription
                            name="Katarzyna Baeck-Kamińska"
                            role="Ekspert ds. pedagogicznych"
                            id="1"
                            parentId="1"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Absolwentka filologii polskiej, studiów podyplomowych z zakresu nadzoru pedagogicznego, ewaluacji programów i projektów UE, psychologii zachowań
                                społecznych,
                                zarządzania w oświacie oraz przygotowujących do nadzoru pedagogicznego. Ukończyła wiele
                                specjalistycznych kursów dotyczących oceniania i egzaminowania, budowy narzędzi badawczych, kształtowania kompetencji i budowania postaw, planowania
                                pracy szkoły,
                                procesowego wspomagania szkół i placówek.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Współpracowała z Instytutem Badań Edukacyjnych i Zespołem EWD jako ekspert EWD.
                                Prowadziła szkolenia dla dyrektorów szkół i placówek oraz dla nauczycieli w projekcie <i>Wzmocnienie nadzoru pedagogicznego, etap II i III</i>,
                                realizowanym przez Erę Ewaluacji w partnerstwie z MEN oraz UJ. Posiada doświadczenie w zakresie kompleksowego wspomagania szkół jako ekspert
                                merytoryczny oraz współautor innowacyjnych rozwiązań procesu wspomagania.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Jest autorką i współautorką licznych prac i projektów badawczych dotyczących edukacji, prowadziła ewaluację projektów finansowanych z UE.
                                Członek Polskiego Towarzystwa Ewaluacji oraz Ogólnopolskiego Stowarzyszenia Kadry Kierowniczej Oświaty.
                                Obecnie pracuje jako nauczyciel konsultant w ośrodku doskonalenia nauczycieli, prowadzi szkolenia dla nauczycieli oraz dyrektorów.
                            </p>
                        </TeamMemberDescription>

                        <TeamMemberDescription
                            name="Ewa Norkowska"
                            role="Ekspert ds. pedagogicznych"
                            id="1"
                            parentId="1"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Mgr inż. technologii żywności (Akademia Rolnicza we Wrocławiu). Absolwentka studiów podyplomowych z zakresu zarządzania w oświacie, zarządzania
                                strategicznego,
                                zarządzania projektami, zarządzania bhp, prawa, przedsiębiorczości, doradztwa zawodowego, logopedii. Ukończyła kursy kwalifikacyjne
                                w zakresie nadzoru pedagogicznego, oligofrenopedagogiki, socjoterapii oraz wiele specjalistycznych kursów dotyczących m.in. procesowego
                                wspomagania szkół i placówek, budowy narzędzi badawczych, kształtowania kompetencji, planowania pracy szkoły. Nauczyciel dyplomowany,
                                12-letnie doświadczenie na stanowiskach kierowniczych w oświacie. Ekspert MEN ds. awansu zawodowego nauczycieli oraz doradztwa zawodowego.
                                Ekspert ds. kształcenia zawodowego w Radzie Konsultacyjnej przy Dolnośląskim Kuratorze Oświaty.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Ewaluacją i diagnozowaniem zajmuje się od 10 lat (tworzy projekty badawcze, prowadzi badania ewaluacyjne, jest autorką/współautorką raportów z
                                ewaluacji wewnętrznej i diagnoz). Współpracowała z Uniwersytetem Ekonomicznym w zakresie ewaluacji projektów finansowanych
                                ze środków Unii Europejskiej.

                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Obecnie pracuje jako nauczyciel konsultant w ośrodku doskonalenia nauczycieli o zasięgu regionalnym – prowadzi szkolenia dla dyrektorów szkół i
                                placówek oraz dla nauczycieli m.in.
                                w obszarze nadzoru pedagogicznego, w tym ewaluacji. Posiada doświadczenie w zakresie kompleksowego wspomagania szkół jako ekspert merytoryczny oraz
                                współautor
                                innowacyjnych rozwiązań procesu wspomagania. Jest autorką licznych publikacji w ogólnopolskich czasopismach oświatowych.

                            </p>
                        </TeamMemberDescription>


                        {/* <TeamMemberDescription
                            name="Dr Adam Ustrzycki"
                            role="Ekspert w krajowych i międzynarodowych projektach edukacyjnych"
                            id="1"
                            parentId="1"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Praca na stanowisku wykładowcy akademickiego w Wyższej Szkole Bankowej w Opolu i we Wrocławiu - wykłada statystykę, analizę danych oraz socjologię.
                                W latach 2005-2016 praca etatowa w uczelniach wyższych: Katolicki Uniwersytet Lubelski Jana Pawła II w Lublinie, Wyższa Szkoła Zarządzania i&nbsp;
                                Administracji w Opolu, Szkoła Wyższa im. Bogdana Jańskiego w Opolu.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Kierownictwo w 18 projektach badawczych, sporządzenie ponad 70 raportów z badań społecznych i rynkowych.
                            </p>
                        </TeamMemberDescription>*/}

                    </Department>
                    <Department name="Zespół obsługi klienta" image={supportImage} id="2">
                        {/*<TeamMemberDescription
                            name="Dariusz Chołost"
                            role="Koordynator ds. obsługi klienta"
                            id="3"
                            parentId="2"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Praca na stanowisku Koordynatora ds obsługi klienta, odpowiedzialny za bezpośredni kontakt z Klientem.
                                Ponad 10-letnie doświadczenie w obsłudze klientów w segmencie B2B oraz w JST.
                                Posiada doświadczanie w realizacji ponad 100 projektów badawczych.
                                Pomoc i opieka w zakresie funkcjonowania portalu Ewaluacja Online.
                            </p>
                        </TeamMemberDescription>*/}
                        <TeamMemberDescription
                            name="Kinga Ciszek-Niemczura"
                            role="Specjalista IT ds. wsparcia klienta"
                            id="4"
                            parentId="2"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Od 10 lat wspiera naszych klientów na wszystkich etapach prowadzenia badań ankietowych ze szczególnym uwzględnieniem wykorzystania systemów
                                informatycznych.
                                Prowadzi szkolenia, jest autorką kilku instrukcji obsługi do systemu Surneo. Cechuje się indywidualnym podejściem do klienta,
                                umiejętnością stawiania trafnych diagnoz i szybkiego rozwiązywania problemów.

                            </p>
                            <p style={{textAlign: 'justify'}}>

                            </p>
                        </TeamMemberDescription>
                    </Department>

                    <Department name="Dział IT" image={itImage} id="3">
                        <TeamMemberDescription
                            name="Adrian Huryn"
                            role="Ekspert w dziedzinie administracji i bezpieczeństwa sieciowego"
                            id="5"
                            parentId="3"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Ukończył Wyższą Szkołę Informatyki Stosowanej. Jest głównym administratorem serwerów, na których działa platforma Ewaluacja Online.
                                Specjalizuje się również w dziedzinie bezpieczeństwa, wdraża rozwiązania ograniczające ryzyko utraty danych oraz ataków hackerskich.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Posiada kilkunastoletnie doświadczenie w tej dziedzinie. Jest też autorem kilku publikacji.
                            </p>
                        </TeamMemberDescription>
                        <TeamMemberDescription
                            name="Łukasz Tworzydło"
                            role="Specjalista ds. projektowania i programowania systemów IT"
                            id="6"
                            parentId="3"
                        >
                            <p style={{textAlign: 'justify'}}>
                                Absolwent Politechniki Wrocławskiej. Od 2006 roku zajmuje się oprogramowaniem z dziedziny badań ankietowych.
                                Główny programista, opracowuje rozwiązania techniczne platformy Ewaluacja Online.
                                Jest też głównym twórcą systemu Surneo, wspierającego badania ankietowe CATI/CAWI/CAPI.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Posiada wieloletnie doświadczenie w dziedzinie projektowania aplikacji webowych oraz baz danych, jak również zajmuje się implementacją i
                                optymalizacją.
                            </p>
                        </TeamMemberDescription>
                    </Department>
                </div>

                {/*<div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-8">
                            <p style={{marginBottom: '0px'}}>
                                <strong>
                                    Dr Adam Ustrzycki
                                </strong>
                            </p>
                            <p>
                                Ekspert w krajowych i międzynarodowych projektach edukacyjnych.
                            </p>
                            <p style={{marginBottom: '0px'}}>
                                <strong>
                                    Doświadczenie:
                                </strong>
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Praca na stanowisku wykładowcy akademickiego w Wyższej Szkole Bankowej w Opolu i we Wrocławiu - wykłada statystykę, analizę danych oraz socjologię.
                                W latach 2005-2016 praca etatowa w uczelniach wyższych: Katolicki Uniwersytet Lubelski Jana Pawła II w Lublinie, Wyższa Szkoła Zarządzania i&nbsp;
                                Administracji w Opolu, Szkoła Wyższa im. Bogdana Jańskiego w Opolu.
                            </p>
                            <p style={{textAlign: 'justify'}}>
                                Kierownictwo w 18 projektach badawczych, sporządzenie ponad 70 raportów z badań społecznych i rynkowych.
                            </p>
                        </div>
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-4">
                            <img src="../assets/images/img11.jpg" alt="" title=""/>
                        </div>
                    </div>
                </div>*/}
            </section>
            <GreenBottomBanner/>
        </>
    );
};

const PageAboutUsConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageAboutUs);

export default PageAboutUsConnectedToRedux;