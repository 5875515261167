import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from "../helpers/ScrollToTopOnMount";
import TitleBanner from "./TitleBanner";
import Preloader from "../visual/Preloader";

export const Subpage = (props) => {
    return (
        <>
            <ScrollToTopOnMount/>
            <Preloader showPreloader={props.preloader} titleBanner="Proszę czekać...">
                <TitleBanner text={props.title} small={props.small}/>
                <section className="mbr-section form1 cid-rz85Klm51j" id="form1-2a">
                    <div className="container">
                        {props.children}
                    </div>
                </section>
            </Preloader>
        </>
    );
};

Subpage.propTypes = {
    title: PropTypes.string.isRequired,
    small: PropTypes.bool,
    preloader: PropTypes.bool
}

Subpage.defaultProps = {
    preloader: false
}

export default Subpage;

