import React from 'react';
import PropTypes from 'prop-types';

export const QuotesTable = (props) => {
    const {pupils, parents, teachers} = props;
    return (
        <>
            Liczba ankietowanych (max)
            <table style={{marginLeft: '0.5rem'}}>
                <tbody>
                <tr>
                    <td className="align-left">uczniów:</td>
                    <td className="align-right" style={{fontSize: '1.3em'}}><strong>{pupils}</strong></td>
                </tr>
                <tr>
                    <td className="align-left">rodziców:</td>
                    <td className="align-right" style={{fontSize: '1.3em'}}><strong>{parents}</strong></td>
                </tr>
                <tr>
                    <td className="align-left">nauczycieli:</td>
                    <td className="align-right" style={{fontSize: '1.3em'}}><strong>{teachers}</strong></td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

QuotesTable.propTypes = {
    pupils: PropTypes.number.isRequired,
    parents: PropTypes.number.isRequired,
    teachers: PropTypes.number.isRequired,
}

export default QuotesTable;

