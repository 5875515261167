class Action {
    /**
     * @public
     * @description Nazwa akcji
     * @type {!string}
     */
    type;

    /**
     * @public
     * @description obiekt zawierający wszystkie parametry akcji
     * @type {?object}
     */
    payload;

    /**
     * @public
     * @method
     * @description metoda obsługująca akcję, pobiera stary stan i zwraca nowy obiekt stanu, działa na parametrach zawartych w obiekcie payload
     * @param {object} state
     * @return {object}
     */
    handle;

    /**
     * @constructor
     * @private
     * @description Konstruktor klasy
     * @param {!string} type
     * @param {?object} payload
     * @param {function} handle
     */
    constructor(type, payload, handle) {
        this.type = type;
        this.payload = payload;
        this.handle = handle;
    }

    /**
     *
     * @returns {{payload: ?Object, type: !string}}
     */
    plain = () => {
        const type = this.type;
        const payload = this.payload;
        // const [type, payload] = this;
        return {
            type,
            payload
        }
    }






}

export default Action;