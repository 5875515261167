import React from 'react';
import PriceListPriceItem from "./PriceListPriceItem";
import {NavLink} from "react-router-dom";
import Msg from "../../../i18n/Msg";
import PropTypes from 'prop-types';

const PriceListPackage = props => {

    let button;

    if(props.disabled) {
        button = (
          <button disabled={true} className="btn btn-primary display-4">
              ZAMÓW
          </button>
        );
    } else {
        button = (
            <NavLink to={Msg.of().menu.contact.link} className="btn btn-primary display-4">
                ZAMÓW
            </NavLink>
        );
    }

    return (
        <div className=" col-12 col-lg-4 col-md-6 my-2">
            <div className="pricing" style={{padding: '2.3rem'}}>
                <PriceListPriceItem price={props.price} name={props.name}/>
                <div className="plan-body">
                    {props.children}
                    <div className="mbr-section-btn pt-4 text-center">
                        {button}
                    </div>
                </div>
            </div>
        </div>
    );
};

PriceListPackage.propTypes = {
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    disabled: PropTypes.bool
}

PriceListPackage.defaultProps = {
    disabled: false
}

export default PriceListPackage;