class SurveyParams {

    static fieldNames = {
        maxPupils: 'maxPupils',
        maxParents: 'maxParents',
        maxTeachers: 'maxTeachers',
    }

    /**
     * @public
     * @field
     * @type {number}
     */
    id;

    /**
     * @public
     * @field
     * @type {number}
     */
    srwID;

    /**
     * @public
     * @field
     * @type {number}
     */
    schoolID;

    /**
     * @public
     * @field
     * @type {string}
     */
    hash;

    /**
     * @public
     * @field
     * @type {number}
     */
    maxPupils;

    /**
     * @public
     * @field
     * @type {number}
     */
    maxTeachers;

    /**
     * @public
     * @field
     * @type {number}
     */
    maxParents;

    /**
     * @public
     * @field
     * @type {boolean}
     */
    cookiesBlockade;

    /**
     * @public
     * @field
     * @type {boolean}
     */
    ipBlockade;

    /**
     * @public
     * @field
     * @type {boolean}
     */
    tokensMode;

    /**
     * @public
     * @field
     * @type {string}
     */
    startLink;


    /**
     *
     * @param {number} id
     * @param {number} srwID
     * @param {number} schoolID
     * @param {string} hash
     * @param {number} maxPupils
     * @param {number} maxTeachers
     * @param {number} maxParents
     * @param {boolean} cookiesBlockade
     * @param {boolean} ipBlockade
     * @param {boolean} tokensMode
     * @param {string} startLink
     */
    constructor(id, srwID, schoolID, hash, maxPupils, maxTeachers, maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink) {
        this.id = id;
        this.srwID = srwID;
        this.schoolID = schoolID;
        this.hash = hash;
        this.maxPupils = maxPupils;
        this.maxTeachers = maxTeachers;
        this.maxParents = maxParents;
        this.cookiesBlockade = cookiesBlockade;
        this.ipBlockade = ipBlockade;
        this.tokensMode = tokensMode;
        this.startLink = startLink;
    }


    /**
     *
     * @param {number} id
     * @param {number} srwID
     * @param {number} schoolID
     * @param {string} hash
     * @param {number} maxPupils
     * @param {number} maxTeachers
     * @param {number} maxParents
     * @param {boolean} cookiesBlockade
     * @param {boolean} ipBlockade
     * @param {boolean} tokensMode
     * @param {string} startLink
     * @return {SurveyParams}
     */
    static get = (id, srwID, schoolID, hash, maxPupils, maxTeachers, maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink) => {
        return new SurveyParams(id, srwID, schoolID, hash, maxPupils, maxTeachers, maxParents, cookiesBlockade, ipBlockade, tokensMode, startLink);
    }


    /**
     *
     * @param {number} srwID
     * @param {number} schoolID
     * @param {number} maxSurveyInstances
     * @return {SurveyParams}
     */
    static getDefault = (srwID, schoolID, maxSurveyInstances) => {
        return SurveyParams.get(undefined, srwID, schoolID, undefined, maxSurveyInstances, maxSurveyInstances, maxSurveyInstances, true, false, true);
    }
}

export default SurveyParams;