import React from 'react';
import PropTypes from 'prop-types';

const InfoTable = props => {
    const {header, content} = props;
    const entries = Object.entries(content);
    const rows = [];
    let index = 0;
    for (let [label, value] of entries) {
        rows[index++] = (
            <tr key={index}>
                <th scope="row" className="align-left" style={{textAlign: 'left'}}>{label}</th>
                <td style={{width: '50%'}}>{value}</td>
            </tr>
        );
    }

    const table = (
        <div className="container">
            <div className="align-center">
                <p style={{textTransform: 'uppercase'}}><strong>{header}</strong></p>
            </div>
            <table className="table table-sm" style={{width: props.width, margin: 'auto'}}>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    );
    return (
        <>
            {table}
        </>
    );
};

InfoTable.propTypes = {
    header: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    width: PropTypes.string
}

InfoTable.defaultProps = {
    width: '25rem'
}

export default InfoTable;