import Action from "../../Action";
import ProfileState from "../../states/ProfileState";

class InitialDataLoadedAction extends Action {
    static TYPE = "InitialDataLoadedAction";

    /**
     * @constructor
     * @param {Object} payload
     */
    constructor(payload) {
        super(InitialDataLoadedAction.TYPE, payload,
            /**
             * @param {ProfileState} prevState
             * @return {Object}
             */
            prevState => {
                return ProfileState.get(this.payload.dataLoaded, prevState.token, prevState.authenticated, prevState.schoolUser, prevState.eduInstitution, prevState.activeMenuItem);
            }
        );
    }

    /**
     * @public
     * @static
     * @method
     * @param {boolean} dataLoaded
     * @return {InitialDataLoadedAction}
     */
    static get = dataLoaded => {
        return new InitialDataLoadedAction({dataLoaded});
    }

    /**
     * @public
     * @static
     * @method
     * @param {function(Object)} dispatch
     * @param {boolean} dataLoaded
     */
    static run = (dispatch, dataLoaded) => {
        dispatch(InitialDataLoadedAction.get(dataLoaded).plain());
    }
}

export default InitialDataLoadedAction;