import React from 'react';

const PriceListPriceItem = props => {
    return (
        <>
            <div className="plan-header">
                <div className="plan-price" style={{textAlign: 'center'}}>
                    {/*<span className="price-value mbr-fonts-style display-4">
                        KOSZT:
                    </span>*/}
                    <h3 className="plan-title mbr-fonts-style display-5" >
                        {props.name}
                    </h3>
                   {/* <span className="price-figure mbr-fonts-style display-1" style={{
                        fontSize: '2em',
                        margin: 'auto',
                        textAlign: 'center',
                        textDecoration: 'line-through',
                        color: '#999'
                    }}>
                        {props.price} zł
                    </span><br/>*/}
                    <span className="price-figure mbr-fonts-style display-1" style={{
                        fontSize: '3em',
                        margin: 'auto',
                        textAlign: 'center',
                        textDecoration: 'none'
                    }}>
                        {props.price/2} zł
                    </span><br/>
                    <span className="price-figure mbr-fonts-style display-1" style={{
                        fontSize: '0.8em',
                        margin: 'auto',
                        textAlign: 'center',
                        textDecoration: 'none'
                    }}>
                        Cena promocyjna
                    </span>
                    <hr/>
                </div>
            </div>
        </>
    );
};

export default PriceListPriceItem;