import React from 'react';
import {NavLink} from 'react-router-dom';
import Msg from "../../i18n/Msg";

const GreenBottomBanner = props => {
    let buttonLabel = null;
    let title = null;
    let text = null;
    let link = null;

    if(props.cennik){
        buttonLabel = 'ZOBACZ CENNIK';
        title = 'ATRAKCYJNE CENY';
        text = 'Zapraszamy do zapoznania się z cennikiem naszych usług. Oferta cenowa jest elastycznie dopasowana dla różnych wielkości placówek oświatowych';
        link = Msg.of().menu.priceList.link;
    } else if (props.oferta) {
        buttonLabel = 'WIĘCEJ';
        title = 'DLACZEGO MY?';
        text = 'Zapraszamy do zapoznania się ze szczegółowymi informacjami na temat metod naszego działania oraz do poznania zalet naszego systemu';
        link = Msg.of().menu.offer.link;
    } else {
        buttonLabel = 'POZNAJ OFERTĘ';
        title = 'POZNAJ NASZĄ OFERTĘ';
        text = 'Zapraszamy do zapoznania się z ofertą portalu Ewaluacja Online, który jest pierwszym narzędziem w Polsce, wspomagającym wewnętrzną ewaluację placówek oświatowych.';
        link = Msg.of().menu.offer.link;
    }

    return (
        <section className="mbr-section info2 cid-rz6Gc9mpal" id="info2-y">
            <div className="mbr-overlay" style={{
                opacity: 0.9,
                backgroundColor: 'rgb(50, 126, 64)'
            }}>
            </div>
            <div className="container">
                <div className="row main justify-content-center">
                    <div className="media-container-column col-12 col-lg-3 col-md-4">
                        <div className="mbr-section-btn align-left py-4">
                            <NavLink className="btn btn-white-outline display-4" to={link}>
                                {buttonLabel}
                            </NavLink>
                        </div>
                    </div>
                    <div className="media-container-column title col-12 col-lg-9 col-md-8">
                        <h2 className="align-right mbr-bold mbr-white pb-3 mbr-fonts-style display-2">
                            {title}
                        </h2>
                        <h3 className="mbr-section-subtitle align-right mbr-light mbr-white mbr-fonts-style display-5" style={{lineHeight: '37px'}}>
                            {text}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GreenBottomBanner;