import Msg from "../i18n/Msg";
import ApiEngine from "../logic/ApiEngine";

class EduInstitution {

    /**
     * @public
     * @field
     * @type {number}
     * @description identyfikator szkoły
     */
    id;

    /**
     * @public
     * @field
     * @type {string}
     * @description numer RSPO szkoły
     */
    rspo;

    /**
     * @public
     * @field
     * @type {string}
     * @description REGON szkoły
     */
    regon;

    /**
     * @public
     * @field
     * @type {string}
     * @description NIP szkoły
     */
    nip;

    /**
     * @public
     * @field
     * @type {string}
     * @description Nazwa szkoły
     */
    name;

    /**
     * @public
     * @field
     * @type {number}
     * @description Województwo
     */
    voivodeshipEnum;

    /**
     * @public
     * @field
     * @type {string}
     * @description Nazwa powiat
     */
    poviatName;

    /**
     * @public
     * @field
     * @type {string}
     * @description Powiat - kod terytorialny
     */
    poviatTerritorialCode;

    /**
     * @public
     * @field
     * @type {string}
     * @description Nazwa gminy
     */
    gminaName;

    /**
     * @public
     * @field
     * @type {string}
     * @description Gmina - kod terytorialny
     */
    gminaTerritorialCode;

    /**
     * @public
     * @field
     * @type {string}
     * @description Nazwa miejscowości
     */
    localityName;

    /**
     * @public
     * @field
     * @type {string}
     * @description Miejscowość - kod terytorialny
     */
    localityTerritorialCode;

    /**
     * @public
     * @field
     * @type {string}
     * @description Ulica
     */
    street;

    /**
     * @public
     * @field
     * @type {string}
     * @description Numer budynku
     */
    houseNo;

    /**
     * @public
     * @field
     * @type {string}
     * @description Numer lokalu
     */
    apartmentNo;

    /**
     * @public
     * @field
     * @type {string}
     * @description Kod pocztowy
     */
    postalCode;

    /**
     * @public
     * @field
     * @type {string}
     * @description Miejscowość lokalizacji poczty
     */
    postOfficeLocality;

    /**
     * @public
     * @field
     * @type {string}
     * @description Numer telefonu
     */
    phone;

    /**
     * @public
     * @field
     * @type {string}
     * @description Numer faksu
     */
    fax;

    /**
     * @public
     * @field
     * @type {string}
     * @description Adres email
     */
    email;

    /**
     * @public
     * @field
     * @type {string}
     * @description Strona WWW
     */
    website;

    /**
     * @public
     * @field
     * @type {boolean}
     * @description czy szkoła jest szkołą publiczną
     */
    isPublic;

    /**
     * @public
     * @field
     * @type {boolean}
     * @description czy szkoła jest szkołą specjalną
     */
    isSpecial;

    /**
     * @public
     * @field
     * @type {string}
     * @description Dyrektor szkoły
     */
    headmaster;

    /**
     * @public
     * @field
     * @type {string}
     * @description Data założenia placówki
     */
    creationDate;

    /**
     * @public
     * @field
     * @type {string}
     * @description Data rozpoczęcia działalności placówki
     */
    startOperationsDate;

    /**
     * @public
     * @field
     * @type {string}
     * @description Organ prowadzący szkołę
     */
    leadingOrganization;

    /**
     * @public
     * @field
     * @type {number}
     * @description Miejsce w strukturze
     */
    schoolStructureTypeEnum;

    /**
     * @public
     * @field
     * @type {string}
     * @description Numer RSPO jednostki nadrzędnej
     */
    parentEntityRSPO;

    /**
     * @public
     * @field
     * @type {string}
     * @description Nazwa jednostki nadrzędnej
     */
    parentEntityName;

    /**
     * @public
     * @field
     * @type {number}
     * @description Liczba uczniów
     */
    numberOfStudents;

    /**
     * @public
     * @field
     * @type {string}
     * @description Tereny sportowe
     */
    sportsAreas;

    /**
     * @public
     * @field
     * @type {string}
     * @description Nauczane języki
     */
    teachedLanguages;

    /**
     * @public
     * @field
     * @type {string}
     * @description Kod HASH szkoły
     */
    hash;


    constructor(id, rspo, regon, nip, name, voivodeshipEnum, poviatName, poviatTerritorialCode, gminaName, gminaTerritorialCode, localityName, localityTerritorialCode, street,
                houseNo, apartmentNo, postalCode, postOfficeLocality, phone, fax, email, website, isPublic, isSpecial, headmaster, creationDate, startOperationsDate,
                leadingOrganization, schoolStructureTypeEnum, parentEntityRSPO, parentEntityName, numberOfStudents, sportsAreas, teachedLanguages, hash) {
        this.id = id;
        this.rspo = rspo;
        this.regon = regon;
        this.nip = nip;
        this.name = name;
        this.voivodeshipEnum = voivodeshipEnum;
        this.poviatName = poviatName;
        this.poviatTerritorialCode = poviatTerritorialCode;
        this.gminaName = gminaName;
        this.gminaTerritorialCode = gminaTerritorialCode;
        this.localityName = localityName;
        this.localityTerritorialCode = localityTerritorialCode;
        this.street = street;
        this.houseNo = houseNo;
        this.apartmentNo = apartmentNo;
        this.postalCode = postalCode;
        this.postOfficeLocality = postOfficeLocality;
        this.phone = phone;
        this.fax = fax;
        this.email = email;
        this.website = website;
        this.isPublic = isPublic;
        this.isSpecial = isSpecial;
        this.headmaster = headmaster;
        this.creationDate = creationDate;
        this.startOperationsDate = startOperationsDate;
        this.leadingOrganization = leadingOrganization;
        this.schoolStructureTypeEnum = schoolStructureTypeEnum;
        this.parentEntityRSPO = parentEntityRSPO;
        this.parentEntityName = parentEntityName;
        this.numberOfStudents = numberOfStudents;
        this.sportsAreas = sportsAreas;
        this.teachedLanguages = teachedLanguages;
        this.hash = hash;
    }

    /**
     *
     * @param id
     * @param rspo
     * @param regon
     * @param nip
     * @param name
     * @param voivodeshipEnum
     * @param poviatName
     * @param poviatTerritorialCode
     * @param gminaName
     * @param gminaTerritorialCode
     * @param localityName
     * @param localityTerritorialCode
     * @param street
     * @param houseNo
     * @param apartmentNo
     * @param postalCode
     * @param postOfficeLocality
     * @param phone
     * @param fax
     * @param email
     * @param website
     * @param isPublic
     * @param isSpecial
     * @param headmaster
     * @param creationDate
     * @param startOperationsDate
     * @param leadingOrganization
     * @param schoolStructureTypeEnum
     * @param parentEntityRSPO
     * @param parentEntityName
     * @param numberOfStudents
     * @param sportsAreas
     * @param teachedLanguages
     * @param hash
     * @return {EduInstitution}
     */
    static get = (id, rspo, regon, nip, name, voivodeshipEnum, poviatName, poviatTerritorialCode, gminaName, gminaTerritorialCode, localityName, localityTerritorialCode, street,
                  houseNo, apartmentNo, postalCode, postOfficeLocality, phone, fax, email, website, isPublic, isSpecial, headmaster, creationDate, startOperationsDate,
                  leadingOrganization, schoolStructureTypeEnum, parentEntityRSPO, parentEntityName, numberOfStudents, sportsAreas, teachedLanguages, hash) => {
        return new EduInstitution(id, rspo, regon, nip, name, voivodeshipEnum, poviatName, poviatTerritorialCode,
            gminaName, gminaTerritorialCode, localityName, localityTerritorialCode, street,
            houseNo, apartmentNo, postalCode, postOfficeLocality, phone, fax, email,
            website, isPublic, isSpecial, headmaster, creationDate, startOperationsDate,
            leadingOrganization, schoolStructureTypeEnum, parentEntityRSPO, parentEntityName,
            numberOfStudents, sportsAreas, teachedLanguages, hash);
    }

    /**
     *
     * @param {object} obj
     * @return {EduInstitution}
     */
    static fromObject = obj => {
        const {
            id, rspo, regon, nip, name, voivodeshipEnum, poviatName, poviatTerritorialCode, gminaName, gminaTerritorialCode, localityName, localityTerritorialCode, street,
            houseNo, apartmentNo, postalCode, postOfficeLocality, phone, fax, email, website, isPublic, isSpecial, headmaster, creationDate, startOperationsDate,
            leadingOrganization, schoolStructureTypeEnum, parentEntityRSPO, parentEntityName, numberOfStudents, sportsAreas, teachedLanguages, hash
        } = obj;
        return new EduInstitution(id, rspo, regon, nip, name, voivodeshipEnum, poviatName, poviatTerritorialCode,
            gminaName, gminaTerritorialCode, localityName, localityTerritorialCode, street,
            houseNo, apartmentNo, postalCode, postOfficeLocality, phone, fax, email,
            website, isPublic, isSpecial, headmaster, creationDate, startOperationsDate,
            leadingOrganization, schoolStructureTypeEnum, parentEntityRSPO, parentEntityName,
            numberOfStudents, sportsAreas, teachedLanguages, hash);
    }

    /**
     *
     * @return {{string: string|*}}
     */
    getInfoObject(emailLogin) {
        return {
            [Msg.of().schoolRspoLabelShort]: this.rspo,
            [Msg.of().schoolRegonLabel]: this.regon,
            [Msg.of().schoolNameLabel]: this.name,
            [Msg.of().schoolAddressLabel]: this.street + (this.houseNo ? ' ' + this.houseNo : '') + (this.apartmentNo ? '/' + this.apartmentNo : ''),
            [Msg.of().schoolPostOfficeLocalityLabelShort]: this.postalCode + ' ' + (this.postOfficeLocality ? this.postOfficeLocality : this.localityName),
            [Msg.of().schoolLocalityLabel]: this.localityName,
            [Msg.of().schoolVoivodeshipLabel]: ApiEngine.nameOfVoivodeship(this.voivodeshipEnum),
            [Msg.of().schoolPoviatLabel]: this.poviatName,
            [Msg.of().schoolGminaLabel]: this.gminaName,
            [Msg.of().schoolNumberOfStudentsLabelShort]: this.numberOfStudents,
            [Msg.of().schoolWebsiteLabelShort]: this.website,
            [Msg.of().schoolSecEmailLabelShort]: this.email,
            [Msg.of().emailLoginLabelShort]: emailLogin ? emailLogin : ''
        }
    }
}

export default EduInstitution;