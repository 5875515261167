class Profile {
    /**
     * @public
     * @field
     * @type {string}
     */
    name;

    /**
     * @public
     * @field
     * @type {boolean}
     */
    logDebug;

    /**
     * @public
     * @field
     * @type {string}
     */
    apiURL;

    /**
     * @public
     * @field
     * @type {boolean}
     */
    panelEnabled;


    constructor(name, logDebug, apiURL, panelEnabled) {
        this.name = name;
        this.logDebug = logDebug;
        this.apiURL = apiURL;
        this.panelEnabled = panelEnabled;
    }

    /**
     * @param {string} name
     * @param {boolean} logDebug
     * @param {string} apiURL
     * @param {boolean} panelEnabled
     * @return {Profile}
     */
    static of = (name, logDebug, apiURL, panelEnabled) => {
        return new Profile(name,logDebug, apiURL, panelEnabled);
    }

    /**
     *
     * @return {boolean}
     */
    isDev(){
        return this.name === 'DEV'
    }

    /**
     *
     * @return {boolean}
     */
    isSandbox() {
        return this.name === 'SANDBOX'
    }

    /**
     *
     * @return {boolean}
     */
    isProd() {
        return this.name === 'PRODUCTION'
    }

}

export default Profile;