import React from 'react';
import PropTypes from 'prop-types';

const FormHeading = props => {

    const alertClasses = props.center? "alert alert-success align-center" : "alert alert-success";

    return (
        <>
            <div className={alertClasses} style={{marginLeft: '-1.5em', marginRight: '-1.5em'}} role="alert">
                <h6 className="text-uppercase" style={{margin: 0}}>{props.text}</h6>
            </div>
        </>
    );
};

FormHeading.propTypes = {
    text: PropTypes.string.isRequired,
    center: PropTypes.bool
}

export default FormHeading;