import React from 'react';
import PropTypes from 'prop-types';

export const TextParagraph = (props) => {
    let style = props.style;
    if(!style) {
        style = {}
    }
    if(props.align) {
        style.textAlign = props.align
    } else {
        style.textAlign = 'justify'
    }
    return (
        <>
            <div className="mbr-text mbr-fonts-style display-7">
                <p style={style}>
                    {props.children}
                </p>
            </div>
        </>
    );
};

TextParagraph.propTypes = {
    style: PropTypes.object,
    align: PropTypes.oneOf(['center', 'left', 'right', 'justify'])
}

export default TextParagraph;

