import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";

class Modal extends Component {


    render() {
        const {id, openingAction, title, showModalButtonText, showModalButtonWidth , buttonSize, buttonFont} = this.props;
        const {buttonPrimary, buttonSecondary, buttonInfo, buttonDanger, buttonDark} = this.props;
        const {buttonPrimaryAction, buttonSecondaryAction, buttonInfoAction, buttonDangerAction, buttonDarkAction} = this.props;
        const buttonDataTarget = "#" + id;


        return (
            <>
                <Button onClickAction={openingAction} dataToggle="modal" dataTarget={buttonDataTarget} size={buttonSize} fixedWidth={showModalButtonWidth} font={buttonFont}>
                    {showModalButtonText}
                </Button>
                <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{padding: 0}}>
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{
                        maxWidth: '3800px',
                        height: 'auto'
                    }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{margin: 'auto', paddingLeft: '50px'}}>{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{margin: 0}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" id="modalContentID">
                                <div className="container">
                                    {this.props.children}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button size={buttonSize} onClickAction={buttonPrimaryAction} color='primary' font={buttonFont}>{buttonPrimary}</Button>
                                <Button size={buttonSize} onClickAction={buttonSecondaryAction} color='secondary' font={buttonFont}>{buttonSecondary}</Button>
                                <Button size={buttonSize} onClickAction={buttonInfoAction} color='info' font={buttonFont}>{buttonInfo}</Button>
                                <Button size={buttonSize} onClickAction={buttonDangerAction} color='danger' font={buttonFont}>{buttonDanger}</Button>
                                <Button size={buttonSize} onClickAction={buttonDarkAction} color='dark' font={buttonFont}>{buttonDark}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


Modal.propTypes = {
    id: PropTypes.string.isRequired,
    showModalButtonText: PropTypes.string.isRequired,
    showModalButtonWidth: PropTypes.string,
    title: PropTypes.string.isRequired,
    openingAction: PropTypes.func,
    buttonPrimary: PropTypes.string,
    buttonSecondary: PropTypes.string,
    buttonInfo: PropTypes.string,
    buttonDanger: PropTypes.string,
    buttonDark: PropTypes.string,
    buttonPrimaryAction: PropTypes.func,
    buttonSecondaryAction: PropTypes.func,
    buttonInfoAction: PropTypes.func,
    buttonDangerAction: PropTypes.func,
    buttonDarkAction: PropTypes.func,
    buttonSize: PropTypes.oneOf([1, 2, 3, 4]),
    buttonFont: PropTypes.oneOf(['Roboto', 'Poppins', 'Open Sans', 'Segoe UI'])
};

Modal.defaultProps = {
    buttonSize: 1,
    buttonFont: 'Roboto'
}

export default Modal;