import React, {Component} from 'react';
import TitleBanner from "../components/website/TitleBanner";
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import ScrollToMeOnRender from "../components/helpers/ScrollToMeOnRender";
import ErrorMessage from "../components/visual/ErrorMessage";
import ConfirmMessage from "../components/visual/ConfirmMessage";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import Utils from "../logic/Utils";
import ApiEngine from "../logic/ApiEngine";
import {connect} from 'react-redux';
import State from "../store/State";
import SetActiveMenuItemAction from "../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../i18n/Msg";

class PageContact extends Component {
    state = {
        from: '',
        email: '',
        phone: '',
        message: '',
        subject: '',
        errorMessages: [],
        submitted: false,
        processing: false
    }

    errorMessages = {
        from: 'Proszę uzupełnić imię i nazwisko',
        email: 'Proszę podać prawidłowy adres email lub tylko numer telefonu, a pole adresu email pozostawić puste',
        message: 'Proszę wpisać wiadomość do wysłania, minimum 3 znaki',
        subject: 'Proszę wpisać temat wiadomości, minimum 3 znaki',
        phone: 'Proszę podać prawidłowy numer telefonu lub tylko adres email, a pole numeru telefonu pozostawić puste',
        emailORphone: 'Nie możemy odpowiedzieć na Państwa wiadomość, jeśli nie podano ani adresu email ani numeru telefonu. Prosimy o podanie co najmniej jednej formy kontaktu.'
    }

    validatePersonalName = personalName => {
        return personalName.length >= 3;
    }

    validateMessage = message => {
        return message.length >= 3;
    }

    handleContactFormEdit = e => {
        e.persist();
        this.setState({
            [e.target.name]: e.target.value,
            submitted: false
        });
    }

    handleContactFormSubmit = e => {
        e.preventDefault();
        let isOK = true;
        this.setState({
            errorMessages: [],
            processing: true
        });

        let {from, email, phone, subject, message} = this.state;
        from = from.trim();
        email = email.trim();
        phone = phone.trim();
        subject = subject.trim();
        message = message.trim();


        if (!this.validatePersonalName(from)) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, this.errorMessages.from]
            }));
            subject = 'Wiadomość z formularza kontaktowego ';
            isOK = false;
        } else {
            subject = 'Wiadomość z formularza kontaktowego';
            subject = 'Od: ' + from;
        }
        if (!email && !phone) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, this.errorMessages.emailORphone]
            }));
            isOK = false;
        }
        if (email && !Utils.validateEmail(email)) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, this.errorMessages.email]
            }));
            isOK = false;
        }
        if (phone && !Utils.validatePhoneNumber(phone)) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, this.errorMessages.phone]
            }));
            isOK = false;
        }
        if (!this.validateMessage(subject)) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, this.errorMessages.subject]
            }));
            isOK = false;
        }
        if (!this.validateMessage(message)) {
            this.setState(prevState => ({
                errorMessages: [...prevState.errorMessages, this.errorMessages.message]
            }));
            isOK = false;
        }
        if (isOK) {

            /*ApiEngine.postContactForm(from, email, phone, subject, message,
                response => {
                    this.setState({
                        submitted: true,
                        errorMessages: [],
                        processing: false
                    });
                },
                response => {
                    this.setState(prevState => ({
                        errorMessages: [...prevState.errorMessages, 'Nie udało się wysłać formularza z powodu problemów łączności z serwerem. Przepraszamy i zapraszamy w późniejszym czasie'],
                        submitted: false,
                        processing: false
                    }));
                });*/
            if (!email) {
                email = '-- nie podano --';
            }
            if (!phone) {
                phone = '-- nie podano --';
            }
            ApiEngine.postContactFormMessage(from, email, phone, subject, message,
                /**
                 *
                 * @param {ValidationResult} validationResult
                 */
                validationResult => {
                    if (validationResult.isValid()) {
                        this.setState({
                            submitted: true,
                            errorMessages: [],
                            processing: false
                        });
                    } else {
                        this.setState(prevState => {
                            validationResult.errors.map(validationMessage => {
                                console.log(validationMessage.text);
                            });
                            return {
                                errorMessages: [...prevState.errorMessages, 'Nie udało się wysłać formularza z powodu problemów łączności z serwerem. Przepraszamy i zapraszamy w późniejszym czasie'],
                                submitted: false,
                                processing: false
                            }
                        });
                    }
                },
                validationResult => {
                    this.setState(prevState => ({
                        errorMessages: [...prevState.errorMessages, 'Nie udało się wysłać formularza z powodu problemów łączności z serwerem. Przepraszamy i zapraszamy w późniejszym czasie'],
                        submitted: false,
                        processing: false
                    }));
                });
        } else {
            this.setState({processing: false})
        }
    }

    render() {

        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem, Msg.of().menu.contact.label);

        const errorsDiv = this.state.errorMessages.length > 0 && (
            <ErrorMessage messagesList={this.state.errorMessages}/>
        );
        const submittedDiv = this.state.submitted && (
            <ConfirmMessage text="Dziękujemy za wysłanie wiadomości. Odpowiemy w możliwie najkrótszym czasie."/>
        );
        const scrollPoint = (this.state.submitted || this.state.errorMessages.length > 0) && (
            <ScrollToMeOnRender/>
        );

        const rodoClauseStyle = {marginBottom: '5px', fontSize: '13px', lineHeight: '23px', textAlign: 'justify'};

        const paddings = {padding: '2rem 0 1rem'};

        return (
            <>
                <ScrollToTopOnMount/>
                <TitleBanner text="SKONTAKTUJ SIĘ Z NAMI"/>


                <section className="mbr-section form1 cid-rz85Klm51j" id="form1-2a">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="title col-12 col-lg-12">
                                <h2 className="mbr-section-title align-center pb-3 mbr-fonts-style display-2">
                                    FORMULARZ KONTAKTOWY
                                </h2>
                                <h3 className="mbr-section-subtitle mbr-light pb-3 mbr-fonts-style display-4">
                                </h3>
                                <div className="text-warning">
                                    <div>
                                        <p>
                                            Administratorem Państwa danych osobowych jest i-Pi Sp. z o.o. z siedzibą w Twardogórze, ul. Norwida 10 56-416 Twardogóra<br/>NIP: 911-198-62-85, REGON: 021322055, KRS: 0000362089
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            Przetwarzamy Państwa dane wyłącznie w celu udzielenia odpowiedzi na pytanie zawarte w formularzu
                                            kontaktowym.
                                            Podstawa przetwarzania danych to realizacja prawnie uzasadnionych interesów administratora w postaci komunikacji z użytkownikami strony.
                                            Państwa dane będą przetwarzane nie dłużej, niż jest to konieczne do
                                            udzielenia odpowiedzi, a po tym czasie mogą być przetwarzane przez okres przedawnienia ewentualnych roszczeń.
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            Podanie danych jest dobrowolne, ale konieczne do tego, żeby odpowiedzieć na zadane pytanie.
                                            Mają Państwo prawo do żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania,
                                            a także prawa do: wniesienia sprzeciwu wobec przetwarzania, przenoszenia swoich danych oraz
                                            wniesienia skargi do organu nadzorczego.
                                        </p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="media-container-column col-lg-12" data-form-type="formoid">
                                <form method="POST" className="mbr-form form-with-styler" data-form-title="Formularz kontaktowy" onSubmit={this.handleContactFormSubmit}>
                                    <input type="hidden" name="code" data-form-email="true"
                                           value="3XNLKq7dhBotlQVpE6EaMZS4pW7tmLX8ZcAcSkErtfiBzHygspvOKFjz46BAeFbQG3mkWQuDyuuvCIf368x6292/6mD5h+x3egl8kX0bynhKz+w+UPC/kb8VjhiiKTLC"/>
                                    <a href="#formTop"></a>
                                    <div className="dragArea row">
                                        <div className="col-md-12  form-group" data-for="name">
                                            <label htmlFor="name-form1-2a" className="form-control-label mbr-fonts-style display-7">Imię i nazwisko</label>
                                            <input type="text" name="from" data-form-field="Name" required="required" className="form-control display-7" id="name-form1-2a"
                                                   onChange={this.handleContactFormEdit} value={this.state.from}/>
                                        </div>
                                        <div className="col-md-12">
                                            <hr/>
                                            <p className="text-warning">
                                                Abyśmy mogli Państwu odpowiedzieć na tę wiadomość, prosimy o uzupełnienie przynajmniej jednego z dwóch poniższych pól: email, nr
                                                telefonu
                                            </p>
                                        </div>
                                        <div className="col-md-6  form-group" data-for="email">
                                            <label htmlFor="email-form1-2a" className="form-control-label mbr-fonts-style display-7">Email</label>
                                            <input type="email" name="email" data-form-field="Email" className="form-control display-7" id="email-form1-2a"
                                                   onChange={this.handleContactFormEdit} value={this.state.email}/>
                                        </div>
                                        <div className="col-md-6  form-group" data-for="phone">
                                            <label htmlFor="phone-form1-2a" className="form-control-label mbr-fonts-style display-7">Telefon</label>
                                            <input type="tel" name="phone" data-form-field="Phone" className="form-control display-7" id="phone-form1-2a"
                                                   onChange={this.handleContactFormEdit} value={this.state.phone}/>
                                        </div>
                                        <div className="col-md-12">
                                            <hr/>
                                        </div>
                                        {/*<div className="col-md-12  form-group" data-for="subject">
                                            <label htmlFor="phone-form1-2a" className="form-control-label mbr-fonts-style display-7">Temat</label>
                                            <input type="text" name="subject" data-form-field="Subject" className="form-control display-7" id="subject-form1-2a" required="required"
                                                   onChange={this.handleContactFormEdit} value={this.state.subject}/>
                                        </div>*/}
                                        {scrollPoint}
                                        <div className="col-md-12 form-group" data-for="message">
                                            <label htmlFor="message-form1-2a" className="form-control-label mbr-fonts-style display-7">Wiadomość</label>
                                            <textarea name="message" data-form-field="Message" className="form-control display-7" id="message-form1-2a" required="required"
                                                      onChange={this.handleContactFormEdit} value={this.state.message}>
                                        </textarea>
                                        </div>
                                        <div className="col-md-12">
                                            {errorsDiv}
                                            {submittedDiv}
                                        </div>
                                        {!this.state.submitted && !this.state.processing && (
                                            <div className="col-md-12 input-group-btn align-center" style={paddings}>
                                                <button type="submit" className="btn btn-primary btn-form display-4">WYŚLIJ WIADOMOŚĆ</button>
                                            </div>
                                        )}
                                        {this.state.processing && (
                                            <div className="col-md-12" style={paddings}>
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                         aria-valuemax="100"
                                                         style={{width: '100%'}}>
                                                        Trwa wysyłanie. Proszę czekać
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>


                <br/>

                {/*<section className="mbr-section article content14 cid-rz86yT2T3b" id="content14-26">
                    <div className="container">
                        <div className="media-container-row content">

                            <div className="col-6 col-md-4 mbr-text mbr-fonts-style display-7 align-center">
                                <div style={{display: 'inline-block', textAlign: 'left'}}>
                                    <p className="mbr-text">
                                        <strong>i-Pi Sp. z o.o.</strong><br/>
                                        ul. Norwida 10<br/>
                                        56-416 Twardogóra<br/>
                                        NIP: 911-198-62-85<br/>
                                        REGON: 021322055<br/>
                                        KRS: 0000362089
                                    </p>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 mbr-text mbr-fonts-style display-7 align-center">
                                <div style={{display: 'inline-block', textAlign: 'left'}}>
                                    <p className="mbr-text">
                                        <strong>Dane kontaktowe</strong><br/>
                                        tel.: <a href="tel:+48 509 195 314">+48 783 314 404</a><br/>
                                        email: <a href="mailto:info@surneo.pl">info@surneo.pl</a><br/>
                                        web: <a href="https://ewaluacja.online">ewaluacja.online</a><br/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 mbr-text mbr-fonts-style display-7">
                                <p><strong>KB Pretendent Sp. z o.o.</strong></p>
                                <p className="mbr-text">ul. Elizy Orzeszkowej 40/9<br/>50-311 Wrocław<br/>NIP: 612-177-16-33<br/>REGON: 020261720<br/>KRS: 0000251665<br/></p>
                            </div>

                        </div>

                    </div>
                </section>*/}

                <GreenBottomBanner/>
            </>
        );
    }
}

const PageContactConnectedToRedux = connect(State.builder().profile().mapStateToProps(), State.mapDispatchToProps())(PageContact);

export default PageContactConnectedToRedux;