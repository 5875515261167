import React from 'react';
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import TitleBanner from "../components/website/TitleBanner";
import {Link, NavLink} from "react-router-dom";
import GreenButton from "../components/visual/GreenButton";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import SectionHeader from "../components/visual/SectionHeader";
import LinkInText from "../components/visual/LinkInText";
import AlignMiddle from "../components/helpers/AlignMiddle";
import nextIcon from "../img/next-icon.png";
import Msg from "../i18n/Msg";
import {connect} from 'react-redux';

const PageNews = () => {
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="AKTUALNE INICJATYWY"/>
            <section className="mbr-section article content1 cid-rz81bDKPdj" id="content1-1k">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-8">


                            <SectionHeader text='Realizacja podstawy programowej w czasie pandemii'/>
                            <p style={{textAlign: "justify"}}>
                                <NavLink to="/"><strong>Ewaluacja.Online</strong></NavLink> zaprasza do uczestnictwa w nowym projekcie badawczym, który jest kontynuacją projektu „Edukacja w cieniu wirusa”, przeprowadzonego
                                z sukcesem wspólnie z Dziennikiem Gazetą Prawną w sierpniu 2020 roku.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Badanie umożliwia zapoznanie się bliżej z platformą Ewaluacja.Online. Szkoła podstawowa, która weźmie w nim udział będzie miała dostęp do
                                indywidualnego panelu z ankietą oraz wynikami dla szkoły a także z raportem po zakończeniu projektu. Anonimowe ankiety dla uczniów klas IV-VIII,
                                opracowane zostały przez  <NavLink to={Msg.of().menu.aboutUs.link}><strong>naszych ekspertów</strong></NavLink> na bazie wytycznych aktualnej podstawy programowej.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Indywidualne zaproszenie będzie wysłane na adres e-mail każdej szkoły 20 maja 2021. W przypadku, gdyby nie dostali Państwo zaproszenia, prosimy o kontakt – zostanie
                                wysłane ponownie.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Po zakończeniu projektu otrzymacie Państwo bezpłatny raport, przygotowany na bazie wyników Waszej szkoły, pokazujący, jak udało się zrealizować
                                podstawę programową. Wyniki wszystkich szkół, które zdecydują się na udział w badaniu posłużą do przygotowania zbiorczego raportu, dotyczącego
                                sytuacji szkół w całej Polsce.
                            </p>

                            <SectionHeader text="Edukacja w cieniu wirusa"/>
                            <p style={{textAlign: "justify"}}>
                                <LinkInText to="/">Ewaluacja.Online</LinkInText> wspólnie z <LinkInText to="https://www.gazetaprawna.pl">Dziennikiem Gazetą
                                Prawną</LinkInText> zakończyła
                                projekt badawczy, realizowany w celu poznania opinii na temat
                                stanu edukacji szkolnej przed nowym rokiem szkolnym 2020/2021.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Dziękujemy Wam za wypełnienie ankiet. Wasze opinie oraz sugestie są dla nas bardzo istotne.
                            </p>
                            {/*<h5 style={{margin: "2rem 0", lineHeight: "1.7rem"}} className="font-weight-bold">Szanowni Państwo<br/>Dyrektorzy, Wójtowie, Burmistrzowie, Prezydenci oraz Starości</h5>
                            <p style={{textAlign: "justify"}}>
                                <LinkInText to="/">Ewaluacja.Online</LinkInText> wspólnie
                                z <LinkInText to="https://www.gazetaprawna.pl">Dziennikiem Gazetą Prawną</LinkInText> realizują projekt badawczy
                                w celu poznania opinii na temat stanu edukacji szkolnej przed nowym rokiem szkolnym 2020/2021.
                                Chcemy poznać Wasze opinie oraz sugestie, które w okresie pandemii są bardzo ważne.
                            </p>
                            <SectionHeader text="Zapraszamy do wypełnienia ankiety"/>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12" style={{padding: 0}}>
                                        <strong>Dla</strong>
                                        <ul style={{margin: 0}}>
                                            <li>Dyrektorów szkół</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 d-sm-none d-none d-md-none d-lg-table-cell d-xl-table-cell">
                                        <img src={nextIcon}/>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <GreenButton label="Otwórz ankietę" externalLink="https://ankieta.ewaluacja.online/szkola" blank={true}/>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12" style={{padding: 0}}>
                                        <strong>Dla</strong>
                                        <ul style={{margin: 0}}>
                                            <li>Wójtów</li>
                                            <li>Burmistrzów</li>
                                            <li>Prezydentów</li>
                                            <li>Starostów</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 d-sm-none d-none d-md-none d-lg-table-cell d-xl-table-cell">
                                        <img src={nextIcon}/>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <GreenButton label="Otwórz ankietę" externalLink="https://ankieta.ewaluacja.online/JST" blank={true}/>
                                    </div>
                                </div>
                            </div>
                            <br/>*/}
                            <SectionHeader text="Raport z badania"/>
                            <p style={{textAlign: "justify"}}>
                                Raport można pobrać <LinkInText to="https://ankieta.ewaluacja.online/home/Edukacja_w_cieniu_wirusa.pdf">TUTAJ</LinkInText>. Sądzimy iż informacje w nim zawarte pozwolą
                                Państwu na poznanie opinii środowiska nauczycielskiego i samorządowego.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Wstępne wyniki badań pojawiły się w wydaniu
                                Dziennika Gazeta Prawnej z dnia <LinkInText to="https://www.gazetaprawna.pl/artykuly/1489746,lekcje-zdalne-ewaluacja-online-badanie.html">1 września
                                2020</LinkInText>.

                                {/*Wyniki badań będą prezentowane w <LinkInText to="https://www.gazetaprawna.pl/">Dzienniku Gazecie Prawnej</LinkInText>.
                                Wasza opinia jest dla nas bardzo ważna i stanowi możliwość zaprezentowania
                                Państwa postaw i oczekiwań. Ankieta jest anonimowa i czas wypełnienia wyniesie około 10 minut.*/}
                            </p>
                            <SectionHeader text="Misja portalu Ewaluacja.Online"/>
                            <p style={{textAlign: "justify"}}>
                                Jednocześnie informujemy iż nasza platforma badawcza Ewaluacja.Online umożliwia szkołom i placówkom oświatowym przeprowadzenie
                                profesjonalnych badań ewaluacyjnych zgodnie z kierunkami realizacji polityki oświatowej począwszy od roku 2021/2022.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Portal zawiera już przygotowane profesjonalne narzędzie badawcze tworzone przez specjalistów w zakresie edukacji,
                                socjologii oraz psychologii. Na podstawie wyników badań otrzymają Państwo raport badawczy,
                                który posłuży do podniesienia jakości podejmowanych przez Państwa działań programowych.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Zapraszamy do zapoznania się z naszą ofertą.
                            </p>
                            <GreenButton label="Oferta dla szkół" internalLink={Msg.of().menu.offer.link}/>
                        </div>
                        <div className="mbr-text col-12 mbr-fonts-style display-7 col-md-4">
                            <img src="../assets/images/img22.jpg" alt="" title=""/>
                        </div>
                    </div>
                </div>
            </section>
            <GreenBottomBanner/>
        </>
    );
};

export default PageNews;