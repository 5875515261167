import Msg_pl from "./Msg_pl";

class Msg {

    static PL = 1;
    static EN = 2;
    static CZ = 3;
    static SK = 4;

    static LANGUAGES = {
        pl: Msg.PL,
        en: Msg.EN,
        cz: Msg.CZ,
        sk: Msg.SK
    }

    static LANG = Msg.PL;
    static DEFAULT_LANG = Msg.PL;

    static currentLanguage = () => {
        for (let [code, value] of Object.entries(Msg.LANGUAGES)) {
            if (Msg.LANG === value) return code;
        }
    }

    static setCurrentLanguage = newLanguage => {
        for (let [code, value] of Object.entries(Msg.LANGUAGES)) {
            if (newLanguage === value || newLanguage === code) {
                Msg.LANG = value;
            }
        }
    }


    static MESSAGES_PL = new Msg_pl();

    /**
     * @return {Msg_pl}
     */
    static get = () => {
        return Msg.MESSAGES_PL;
    }

    static of = Msg.get;

    /**
     * @static
     * @function
     * @description Funkcja pobierająca tekst w aktywnym języku dla podanego kodu
     * @param {!string} code
     * @returns {string} Zwraca tekst dla danego kodu i aktywnego języka
     */
    static message = (code) => {
        // console.log('message("'+code+'")');
        return Msg.MESSAGES_PL[code]
    };




    static CaptchaInfo = Msg.message('CaptchaInfo');
    static CaptchaPlaceholder = Msg.message('CaptchaPlaceholder');
    static CaptchaError = Msg.message('CaptchaError');
    static FillErrorLogin = Msg.message('FillErrorLogin');
    static FillErrorPassword = Msg.message('FillErrorPassword');
    static FieldNameLogin = Msg.message('FieldNameLogin');
    static FieldNamePassword = Msg.message('FieldNamePassword');
    static ButtonLogin = Msg.message('ButtonLogin');
    static ApiServerNotAccessible = Msg.message('ApiServerNotAccessible');

    /*
    Formularz szkoły
     */
    static schoolBasicData = Msg.message('schoolBasicData');
    static schoolBasicDataShort = Msg.message('schoolBasicDataShort');
    static schoolTypeLabel = Msg.message('schoolTypeLabel');
    static schoolTypeLabelShort = Msg.message('schoolTypeLabelShort');
    static schoolNameLabel = Msg.message('schoolNameLabel');
    static schoolNameLabelShort = Msg.message('schoolNameLabelShort');
    static schoolNipLabel = Msg.message('schoolNipLabel');
    static schoolNipLabelShort = Msg.message('schoolNipLabelShort');
    static schoolAddressLabel = Msg.message('schoolAddressLabel');
    static schoolAddressLabelShort = Msg.message('schoolAddressLabelShort');
    static schoolStreetLabel = Msg.message('schoolStreetLabel');
    static schoolStreetLabelShort = Msg.message('schoolStreetLabelShort');
    static schoolHouseNumberLabel = Msg.message('schoolHouseNumberLabel');
    static schoolHouseNumberLabelShort = Msg.message('schoolHouseNumberLabelShort');
    static schoolCityLabel = Msg.message('schoolCityLabel');
    static schoolCityLabelShort = Msg.message('schoolCityLabelShort');
    static schoolPostalCodeLabel = Msg.message('schoolPostalCodeLabel');
    static schoolPostalCodeLabelShort = Msg.message('schoolPostalCodeLabelShort');
    static schoolSecretariat = Msg.message('schoolSecretariat');
    static schoolSecretariatShort = Msg.message('schoolSecretariatShort');
    static schoolSecEmailLabel = Msg.message('schoolSecEmailLabel');
    static schoolSecEmailLabelShort = Msg.message('schoolSecEmailLabelShort');
    static schoolSecPhoneLabel = Msg.message('schoolSecPhoneLabel');
    static schoolSecPhoneLabelShort = Msg.message('schoolSecPhoneLabelShort');
    static schoolSchoolSizeLabel = Msg.message('schoolSchoolSizeLabel');
    static schoolSchoolSizeLabelShort = Msg.message('schoolSchoolSizeLabelShort');
    static schoolVoivodeshipLabel = Msg.message('schoolVoivodeshipLabel');
    static schoolVoivodeshipLabelShort = Msg.message('schoolVoivodeshipLabelShort');

    /*
    Formularz szkoły - komunikaty walidacyjne
     */
    static schoolFormValidationError = {
        name: Msg.message('schoolFormValidationErrorName'),
        type: Msg.message('schoolFormValidationErrorType'),
        nip: Msg.message('schoolFormValidationErrorNIP'),
        street: Msg.message('schoolFormValidationErrorStreet'),
        houseNumber: Msg.message('schoolFormValidationErrorHouseNumber'),
        city: Msg.message('schoolFormValidationErrorCity'),
        postalCode: Msg.message('schoolFormValidationErrorPostalCode'),
        secEmail: Msg.message('schoolFormValidationErrorSecEmail'),
        secPhone: Msg.message('schoolFormValidationErrorSecPhone'),
        schoolSize: Msg.message('schoolFormValidationErrorSchoolSize'),
        voivodeship: Msg.message('schoolFormValidationErrorVoivodeship'),
    }

    /*
    Formularz użytkownika
     */
    static userFormHeader = Msg.message('userFormHeader');
    static userAccountHeader = Msg.message('userAccountHeader');
    static userFormIntroduction = Msg.message('userFormIntroduction');
    static userFormIntroductionPersonalData = Msg.message('userFormIntroductionPersonalData');
    static userFirstName = Msg.message('userFirstName');
    static userLastName = Msg.message('userLastName');
    static userEmail = Msg.message('userEmail');
    static userEmailShort = Msg.message('userEmailShort');
    static userEmailRepeated = Msg.message('userEmailRepeated');
    static userPhone = Msg.message('userPhone');
    static userPhoneShort = Msg.message('userPhoneShort');
    static userRodoHeader = Msg.message('userRodoHeader');
    static userRodoText = Msg.message('userRodoText');
    static userRodoAccepted = Msg.message('userRodoAccepted');
    static userLogin = Msg.message('userLogin');
    static userPassword = Msg.message('userPassword');
    static userPasswordRepeated = Msg.message('userPasswordRepeated');
    static userRole = Msg.message('userRole');

    /*
    Formularz użytkownika - komunikaty walidacyjne
     */
    static userFormValidationError = {
        firstname: Msg.message('userFormValidationErrorFirstname'),
        lastName: Msg.message('userFormValidationErrorLastName'),
        email: Msg.message('userFormValidationErrorEmail'),
        emailRepeated: Msg.message('userFormValidationErrorEmailRepeated'),
        phone: Msg.message('userFormValidationErrorPhone'),
        rodoAccepted: Msg.message('userFormValidationErrorRodoAccepted'),
        login: Msg.message('userFormValidationErrorLogin'),
        password: Msg.message('userFormValidationErrorPassword'),
        passwordRepeated: Msg.message('userFormValidationErrorPasswordRepeated'),
        role: Msg.message('userFormValidationErrorRole'),

    }

}

export default Msg;