import React from 'react';
import TitleBanner from "../components/website/TitleBanner";
import ScrollToTopOnMount from "../components/helpers/ScrollToTopOnMount";
import GreenBottomBanner from "../components/website/GreenBottomBanner";
import ckeLogo from "../img/loga/cke-logo.png";
import menLogo from "../img/loga/mein-logo.png";
import oreLogo from "../img/loga/ore-logo.png";
import seoLogo from "../img/loga/seo-logo.png";
import ibeLogo from "../img/loga/ibe-logo.png";
import ibeEeLogo from "../img/loga/ibeee-logo.png";
import ExternalLinkItem from "../components/visual/ExternalLinkItem";
import GreenButton from "../components/visual/GreenButton";
import Msg from "../i18n/Msg";

const PageUsefulLinks = () => {
    return (
        <>
            <ScrollToTopOnMount/>
            <TitleBanner text="PRZYDATNE LINKI"/>
            <section className="mbr-section content4 cid-rz6z3qVI7d" id="content4-u-2">
                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                Ważne strony
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content1 cid-rz6immHSZe">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://cke.gov.pl/" target="_blank"><img src={ckeLogo} className="d-block w-100" alt="Centralna Komisja Egzaminacyjna"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.gov.pl/web/edukacja" target="_blank"><img src={menLogo} className="d-block w-100" alt="Ministerstwo Edukacji Narodowej"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.ore.edu.pl/" target="_blank"><img src={oreLogo} className="d-block w-100" alt="Ośrodek Rozwoju Edukacji"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.npseo.pl/" target="_blank"><img src={seoLogo} className="d-block w-100" alt="System Ewaluacji Oświaty Nadzór Pedagogiczny"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="https://www.ibe.edu.pl/pl/" target="_blank"><img src={ibeLogo} className="d-block w-100" alt="Instytut Badań Edukacyjnych"/></a>
                        </div>
                        <div className="col-sm-12 col-md-4 align-center" style={{padding: '2.5rem'}}>
                            <a href="http://eduentuzjasci.pl/" target="_blank"><img src={ibeEeLogo} className="d-block w-100" alt="Entuzjaści Edukacji"/></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section content4 cid-rz6z3qVI7d" id="content4-u-2">
                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                Odpowiedzi na najważniejsze pytania
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <GreenButton label="Zapoznaj się" internalLink={Msg.of().menu.infoGraphic.link}/>

            <section className="mbr-section content4 cid-rz6z3qVI7d" id="content4-u-2">
                <div className="container">
                    <div className="media-container-row">
                        <div className="title col-12 col-md-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2">
                                Polecane źródła i materiały
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article cid-rz6immHSZe">
                <div className="container">
                    {/*<ExternalLinkItem
                        link="https://prawo.sejm.gov.pl/isap.nsf/download.xsp/WDU20170001658/O/D20171658.pdf"
                        label="ROZPORZĄDZENIE MINISTRA EDUKACJI NARODOWEJ z dnia 25 sierpnia 2017 r. w sprawie nadzoru pedagogicznego"
                    />*/}
                    <ExternalLinkItem
                        link="https://prawo.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20170001611"
                        label="Rozporządzenie Ministra Edukacji Narodowej z dnia 11 sierpnia 2017 r. w sprawie wymagań wobec szkół i placówek"
                    />
                    <ExternalLinkItem
                        link="https://prawo.sejm.gov.pl/isap.nsf/download.xsp/WDU20190001627/O/D20191627.pdf"
                        label="Rozporządzenie Ministra Edukacji Narodowej z dnia 23 sierpnia 2019 zmieniające rozporządzenie w sprawie nadzoru pedagogicznego"
                    />
                    {/*<ExternalLinkItem
                        link="https://prawo.sejm.gov.pl/isap.nsf/download.xsp/WDU20190001575/O/D20191575.pdf"
                        label="ROZPORZĄDZENIE MINISTRA EDUKACJI NARODOWEJ)z dnia 6 sierpnia 2019 r. zmieniające rozporządzenie w sprawie wymagań wobec szkół i placówek"
                    />*/}
                    <ExternalLinkItem
                        link="https://www.gov.pl/attachment/dac7127c-6063-4eab-8e3b-fde9176a0a15"
                        label="Plan nadzoru pedagogicznego Ministra Edukacji Narodowej na rok szkolny 2019-2020"
                    />
                    <ExternalLinkItem
                        link="https://eur-lex.europa.eu/legal-content/PL/TXT/PDF/?uri=CELEX:32018H0604(01)&from=EN"
                        label="Zalecenie Rady UE w sprawie kompetencji kluczowych w procesie uczenia się przez całe życie; 2018"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=23137"
                        label="Podstawa programowa – matematyka"
                    />
                    <ExternalLinkItem
                        link="https://wyszukiwarka.efs.men.gov.pl/product/metody-nauczania-stosowane-w-ksztaltowaniu-kompetencji-kluczowych-matematyka-podrecznik-metodyki-operacyjnej/attachment/219"
                        label="Metody nauczania stosowane w kształtowaniu kompetencji kluczowych. Matematyka. Podręcznik matematyki operacyjnej"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=21419"
                        label="Specjalne potrzeby edukacyjne a matematyka"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=4896"
                        label="Matematyka bez tajemnic – w poszukiwaniu źródeł sukcesu w pracy z uczniami zdolnymi"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=7135"
                        label="Twórcza matematyka. Bank dobrych praktyk"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=27391"
                        label="Sprawozdanie z badania kształcenia kompetencji kluczowych uczniów prowadzonego w formie monitorowania w roku szkolnym 2018/2019"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=27157"
                        label="Znaki rzymskie. Scenariusz lekcji matematyki. II etap edukacyjny"
                    />
                    <ExternalLinkItem
                        link="https://www.ore.edu.pl/wp-content/plugins/download-attachments/includes/download.php?id=27147"
                        label="Większe tańsze mniejsze droższe. Scenariusz lekcji matematyki. II etap edukacyjny"
                    />
                    <ExternalLinkItem
                        link="https://wyszukiwarka.efs.men.gov.pl/product/kompetencje-kluczowe-informator-dla-rodzicow-i-opiekunow/attachment/636"
                        label="Kompetencje kluczowe. Informator dla rodziców i opiekunów"
                    />
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
            </section>

            <GreenBottomBanner/>
        </>
    );
};

export default PageUsefulLinks;