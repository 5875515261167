import PropTypes from 'prop-types';

class ResponsiveRatio {
    sm;
    md;
    lg;
    xl;

    constructor(sm, md, lg, xl) {
        this.sm = sm;
        this.md = md;
        this.lg = lg;
        this.xl = xl;
    }

    static default = () => {
        return new ResponsiveRatio(5, 4, 3, 2);
    }

    static get = (sm, md, lg, xl) => {
        return new ResponsiveRatio(sm, md, lg, xl);
    }

    static getDefault = () => {
        return ResponsiveRatio.get(5, 5, 2, 2);
    }

    static shape = () => {
        return {
            sm: PropTypes.number,
            md: PropTypes.number,
            lg: PropTypes.number,
            xl: PropTypes.number
        }
    }

    getLabelClasses = () => {
        return "col-12 " + "col-sm-" + (this.sm) + " col-md-" + (this.md) + " col-lg-" + (this.lg) + " col-xl-" + (this.xl);
    }

    getControlClasses = () => {
        return "col-12 " + "col-sm-" + (12 - this.sm) + " col-md-" + (12 - this.md) + " col-lg-" + (12 - this.lg) + " col-xl-" + (12 - this.xl);
    }

}

export default ResponsiveRatio;