import React from 'react';
import PropTypes from "prop-types";
import ResponsiveRatio from "../ResponsiveRatio";

const Checkbox = props => {

    let {name, ratio, label, marginBottom, onChangeFunc, checked} = props;

    const controlClasses = 'custom-control-input ';// + ratio.getControlClasses();
    const labelClasses = 'custom-control-label ';// + ratio.getLabelClasses();

    return (
        <>
            <div className="custom-control custom-checkbox" style={{marginBottom: marginBottom}}>
                <input type="checkbox" className={controlClasses} id={name} name={name} checked={checked} onChange={onChangeFunc}/>
                <label className={labelClasses} htmlFor={name} style={{}}>
                    <p className="text-uppercase">{label}</p>
                </label>
            </div>
        </>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    ratio: PropTypes.shape(ResponsiveRatio.shape()).isRequired,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    onChangeFunc: PropTypes.func.isRequired,
    checked: PropTypes.bool
}

Checkbox.defaultProps = {
    ratio: ResponsiveRatio.default(),
    checked: false,
    labelFollowingCheckbox: false
}

export default Checkbox;