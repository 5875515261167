import Field from "./components/Field";
import FieldType from "./components/FieldType";
import Msg from "../i18n/Msg";
import User from "../model/User";
import ResponsiveRatio from "./components/ResponsiveRatio";
import FieldParams from "./components/FieldParams";
import FormHeading from "./components/controls/FormHeading";
import ApiEngine from "../logic/ApiEngine";
import React from 'react';
import ValidationResult from "../logic/ValidationResult";

class UserForm {
    firstname = new Field(User.fieldNames.firstname, Msg.userFirstName, FieldType.text);
    lastname = new Field(User.fieldNames.lastname, Msg.userLastName, FieldType.text);
    email = new Field(User.fieldNames.email, Msg.userEmail, FieldType.text);
    emailRepeated = new Field(User.fieldNames.emailRepeated, Msg.userEmailRepeated, FieldType.text);
    phone = new Field(User.fieldNames.phone, Msg.userPhone, FieldType.text);
    rodoAccepted = new Field(User.fieldNames.rodoAccepted, Msg.userRodoAccepted, FieldType.checkbox);
    login = new Field(User.fieldNames.login, Msg.userLogin, FieldType.text);
    password = new Field(User.fieldNames.password, Msg.userPassword, FieldType.password);
    passwordRepeated = new Field(User.fieldNames.passwordRepeated, Msg.userPasswordRepeated, FieldType.password);
    userRole = new Field(User.fieldNames.userRole, Msg.userRole, FieldType.radio);

    /**
     * @param {User} user
     * @param {Object} changeFunctionsObject
     * @param {ValidationResult} validationResult
     * @return {*}
     */
    buildForm = (user, changeFunctionsObject, validationResult) => {
        const ratio = ResponsiveRatio.get(5, 5, 2, 2);
        const ratioCheckbox = ResponsiveRatio.get(7, 7, 12, 12)
        const marginBelowEveryFormComponent = "2rem";

        const defaultFieldParams = FieldParams.get('', changeFunctionsObject.handleUserEdit, ratio, marginBelowEveryFormComponent, null, validationResult);

        const msg = Msg.get();

        let jsxOfSchoolForm = (
            <div className="container">
                <h5 className="text-uppercase text-danger" style={{marginTop: marginBelowEveryFormComponent, marginBottom: marginBelowEveryFormComponent, textTransform: 'uppercase'}}>
                    {msg.common.step} 2/2
                </h5>
                {validationResult.isNotValid() && (
                    <p className="h6" style={{marginTop: marginBelowEveryFormComponent, marginBottom: marginBelowEveryFormComponent, lineHeight: '1.5rem', color: 'red'}}>
                        Dane wymagają poprawienia. W poniższym formularzu umieszczone są odpowiednie komunikaty
                    </p>
                )
                }
                <FormHeading text={Msg.userAccountHeader}/>
                <p className="h6 text-danger" style={{marginTop: marginBelowEveryFormComponent, marginBottom: marginBelowEveryFormComponent, lineHeight: '1.5rem'}}>
                    {Msg.userFormIntroduction}
                </p>
                {this.login.getReactElement(defaultFieldParams.withFieldValue(user.login))}
                {this.password.getReactElement(defaultFieldParams.withFieldValue(user.password))}
                {this.passwordRepeated.getReactElement(defaultFieldParams.withFieldValue(user.passwordRepeated))}
                {this.email.getReactElement(defaultFieldParams.withFieldValue(user.email))}
                {this.emailRepeated.getReactElement(defaultFieldParams.withFieldValue(user.emailRepeated))}

                <FormHeading text={Msg.userFormHeader}/>
                <p className="h6 text-danger" style={{marginTop: marginBelowEveryFormComponent, marginBottom: marginBelowEveryFormComponent, lineHeight: '1.5rem'}}>
                    {Msg.userFormIntroductionPersonalData}
                </p>
                <h5>
                    {Msg.userRodoHeader}
                </h5>
                <p className="text-justify">
                    {Msg.userRodoText}
                </p>
                {this.rodoAccepted.getReactElement(defaultFieldParams.withChecked(user.rodoAccepted).withResponsiveRatio(ratioCheckbox))}
                {this.firstname.getReactElement(defaultFieldParams.withFieldValue(user.firstname))}
                {this.lastname.getReactElement(defaultFieldParams.withFieldValue(user.lastname))}
                {this.phone.getReactElement(defaultFieldParams.withFieldValue(user.phone))}
                {this.userRole.getReactElement(defaultFieldParams.withFieldValue(user.userRole).withDictionary(ApiEngine.USER_ROLES))}
            </div>
        );
        return jsxOfSchoolForm;
    }

    /**
     * @public
     * @static
     * @method
     * @param {User} user
     * @param {function} handleUserEdit
     * @param {ValidationResult} validationResult
     * @return {*} Zwraca JSX formularza
     */
    static get = (user, handleUserEdit, validationResult) => {
        const userForm = new UserForm();
        return userForm.buildForm(user, {handleUserEdit}, validationResult);
    }
}

export default UserForm;