import Action from "../../Action";
import ResponseWrapper from "../../../logic/ResponseWrapper";
import ApiEngine from "../../../logic/ApiEngine";
import ProfileState from "../../states/ProfileState";
import ApiUrl from "../../../logic/ApiUrl";
import ValidationResult from "../../../logic/ValidationResult";
import Msg from "../../../i18n/Msg";
import State from "../../State";
import Logger from "../../../logic/Logger";
import Current from "../../../profiles/Current";

class LoginAction extends Action {

    static TYPE = "LoginAction";

    /**
     * @param {object} payload
     */
    constructor(payload) {
        super(LoginAction.TYPE, payload,
            /**
             * @param {ProfileState} prevState
             * @return {Object}
             */
            prevState => {
                return ProfileState.get(prevState.initialDataLoaded, this.payload.token, this.payload.authenticated, this.payload.schoolUser, this.payload.eduInstitution,
                    this.payload.authenticated ? Msg.of().menu.schoolProfile.label : prevState.activeMenuItem, this.payload.admin);
            });
    }

    /**
     * @public
     * @static
     * @method
     * @param {?string} token
     * @param {boolean} authenticated
     * @param {SchoolUser} schoolUser
     * @param {EduInstitution} eduInstitution
     * @param {boolean} admin
     * @return {LoginAction}
     */
    static get = (token, authenticated, schoolUser, eduInstitution, admin) => {
        return new LoginAction({token, authenticated, schoolUser, eduInstitution, admin});
    }

    /**
     * @public
     * @static
     * @method
     * @description Zwraca akcję pobrania szkoły i użytkownika po zalogowaniu
     * @param token
     * @param {SchoolUser} schoolUser
     * @param {EduInstitution} eduInstitution
     * @return {LoginAction}
     */
    static loggedSuccess = (token, schoolUser, eduInstitution) => {
        return LoginAction.get(token, true, schoolUser, eduInstitution, false);
    }


    /**
     *
     * @return {LoginAction}
     */
    static loggedFailure = () => {
        return LoginAction.get(null, false, null, null, false);
    }

    /**
     * @param {string} token
     * @return {LoginAction}
     */
    static loggedSuccessAdmin = (token) => {
        return LoginAction.get(token, true, null, null, true);
    }


    /**
     * @public
     * @static
     * @function
     * @description Wysłanie żądania zalogowania użytkownika
     * @param {function(Object)} dispatch metoda dispatch Reduxa
     * @param {string} login nazwa użytkownika/login
     * @param {string} password hasło użytkownika
     * @param {function(ValidationResult)} funcCallback funkcja, która ma być wykonana po otrzymaniu responsa
     */
    static run = (dispatch, login, password, funcCallback) => {
        let loginURL;
        if(Current.PROFILE.isSandbox() || Current.PROFILE.isDev()) {
            loginURL = ApiUrl.API.OPEN.SESSION.LOGIN_SANDBOX;
        } else {
            loginURL = ApiUrl.API.OPEN.SESSION.LOGIN;
        }
        ApiEngine.postOpen(
            loginURL,
            {
                "login": login,
                "password": password
            },
            /**
             * @param {ValidationResult} response
             */
            response => {
                if (response.isValid()) {
                    const token = response.token;
                    ApiEngine.loadProfile((schoolUser, eduInstitution) => {
                        const resp = response;
                        dispatch(LoginAction.loggedSuccess(token, schoolUser, eduInstitution).plain());
                        funcCallback(resp);
                    }, (errorResponse) => {
                        dispatch(LoginAction.loggedFailure().plain());
                        funcCallback(errorResponse);
                    }, token);

                } else {
                    Logger.debug('Login failure');
                    Logger.debug(response);
                    dispatch(LoginAction.loggedFailure().plain());
                    funcCallback(response);
                }
            },
            reason => {
                dispatch(LoginAction.loggedFailure().plain());
                funcCallback(ValidationResult.buildFromErrorReason(reason));
            }
        );
    }

    /**
     * @public
     * @static
     * @function
     * @description Wysłanie żądania zalogowania użytkownika
     * @param {function(Object)} dispatch metoda dispatch Reduxa
     * @param {string} login nazwa użytkownika/login
     * @param {string} password hasło użytkownika
     * @param {function(ValidationResult)} funcCallback funkcja, która ma być wykonana po otrzymaniu responsa
     */
    static runForAdmin = (dispatch, login, password, funcCallback) => {
        ApiEngine.postOpen(
            ApiUrl.API.OPEN.SESSION.ADMIN_LOGIN,
            {
                "login": login,
                "password": password
            },
            /**
             * @param {ValidationResult} response
             */
            response => {
                if (response.isValid()) {
                    const token = response.token;
                    dispatch(LoginAction.loggedSuccessAdmin(token).plain());
                } else {
                    Logger.debug('Login failure');
                    Logger.debug(response);
                    dispatch(LoginAction.loggedFailure().plain());
                    funcCallback(response);
                }
            },
            reason => {
                dispatch(LoginAction.loggedFailure().plain());
                funcCallback(ValidationResult.buildFromErrorReason(reason));
            }
        );
    }
}

export default LoginAction;